import { Markup } from "interweave";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { Button, Drawer, notification, Spin } from "antd";
import no_result from "../../assests/images/undraw_warning.svg";
import { NavLink, createSearchParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { MdOutlineStar } from "react-icons/md";
import { generateExpertProfile } from "../expert_profile/ExpertSubmittedProfile";
import { FiDownload } from "react-icons/fi";
import { convertDate, getFormattedDate, statusButton, statusSetter } from "../../Utils/util";
import ProjectDrawer from "./ProjectDrawer";

function DeclineTable({ project_id, expertList, getExperts, loading }) {
  const [DeclinedExperts, setDeclinedExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [modalToggle, setModalToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [expertID, setExpertID] = useState("");
  const [status, setStatus] = useState("");
  const [loadingp, setloadingp] = useState(false)

  const [submitPop, setSubmitPop] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };
  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);

  useEffect(() => {
    if (expertList) {
      let filteredExperts = expertList.filter(
        (elem) => elem?.admin_invite_experts[0]?.status === 6)
      setDeclinedExperts(filteredExperts)
    }
  }, [expertList]);

  const fetchData = (_id) => {
    axios
      .get(
        `${API_PATH.PROJECT_EXPERT_GET}/${_id}`,
        // { email: UserData.user.email },
        { headers }
      )
      .then((res) => {
        setSubmitPop(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExpertDetails = (_id) => {
    setExpertID(_id, "expertId");
    fetchData(_id);
  };

  const expertPopupStatus = (statuses) => {
    return statuses.map((status, index) => {
      if (status === "Edit") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2a55e", cursor: "pointer", fontSize: "14px" }}
            >
              <NavLink
                to={{
                  pathname: "/dashboard/addExpert",
                  search: `?${createSearchParams({ project_id, expertID })}`,
                }}
                key={index}
              >
                Edit
              </NavLink>
            </span>
          </div>
        );
      } else if (status === "Request") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2ca8ff", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Request
            </span>
          </div>
        );
      } else if (status === "Schedule") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#f96332", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Schedule
            </span>
          </div>
        );
      } else if (status === "Complete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#39c4a3", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Complete
            </span>
          </div>
        );
      } else if (status === "Decline") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "maroon", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Decline
            </span>
          </div>
        );
      } else if (status === "Delete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Delete
            </span>
          </div>
        );
      } else if (status === "Download") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "14px" }}
              onClick={async () => {
                setloadingp(true)
                await generateExpertProfile(submitPop)
                setloadingp(false)

              }}
              key={index}
            >
              {loadingp ? <><Spin size="small" /> PDF... </> : <><FiDownload /> PDF</>}</span>
          </div>
        );
      }
    });
  };

  const statusData = expertPopupStatus(["Delete", "Download"]);

  const expertStatusChanger = (status) => {
    setStatus(status);
    setModalToggle(true);
  };

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (status) => {
    setSpinner(true);
    document.getElementById("closebutton1").disabled = true;

    const successFunction = () => {
      setSpinner(false);
      setModalToggle(false);
      getExperts();
      toggleDrawer();
      notification.success({
        message: "Status has been changed",
        icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#2ecc71",
          color: "#fff !important",
          border: "1px solid #52c41a",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    let valueToAdd;
    if (status === "Request") {
      valueToAdd = 3;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          successFunction()
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Schedule") {
      valueToAdd = 4;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          successFunction()
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Complete") {
      valueToAdd = 5;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          successFunction()
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Decline") {
      valueToAdd = 6;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: expertID,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          successFunction()
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Delete") {
      axios
        .post(
          `${API_PATH.PROJECT_EXPERT_DELETE}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          getExperts();
          toggleDrawer();
          notification.success({
            message: "Expert Deleted Successfully!",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  if (loading) {
    return <div className="d-flex justify-content-center align-items-center h-100"><Spin size="large" /></div>
  }
  return (
    <>
      <>
        {DeclinedExperts?.length > 0 && (
          <>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    {/* <th title="Name" style={{ width: "55px" }}>
                      #
                    </th> */}
                    {/* <th style={{ width: "120px" }}>
                      Status
                    </th> */}
                    <th style={{ width: "50%" }}>
                      Most Relevant Title
                    </th>
                    <th style={{ width: "25%" }}>
                      Company
                    </th>
                    <th style={{ width: "95px" }}>
                      RATE/HR{" "}
                    </th>
                    <th title="Age" style={{ width: "130px" }}>
                      DATE
                    </th>
                    <th style={{ width: "80px" }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {DeclinedExperts.map((curElem, index) => <tr key={curElem._id}>
                    {/* <td>
                      <span className="declined-text"> Declined </span>
                    </td> */}
                    <td
                      style={{
                        color: "rgb(82, 111, 215)",
                        fontWeight: "500",
                        // whiteSpace: "nowrap"
                      }}
                    >
                      {curElem.admin_expert_experience[0]
                        ?.current_designation
                        ? curElem.admin_expert_experience[0]
                          ?.current_designation
                        : curElem.admin_expert_experience[0]
                          ?.previous_designation}
                    </td>
                    <td>
                      {curElem.admin_expert_experience[0]?.current_compny
                        ? curElem.admin_expert_experience[0]?.current_compny
                        : curElem.admin_expert_experience[0]
                          ?.previous_compny}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {curElem?.expert_client_Currency}{" "}
                      {curElem.hourly_consulting_rate}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {convertDate(curElem?.created_at)}
                    </td>
                    <td>
                      <span
                        type="button"
                        style={{ fontWeight: "600", color: "#5746EC" }}
                        key={index}
                        onClick={() => {
                          toggleDrawer();
                          getExpertDetails(curElem?._id);
                        }}
                      >
                        View
                      </span>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </>)}
        {DeclinedExperts?.length === 0 && !loading && (
          <>
            <div className="text-center noResultFound">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-6">
                    <img src={no_result} style={{ height: "350px" }} alt="img"></img>
                  </div>
                  <div className="col-md-6">
                    <h3
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      We're glad you haven't declined any expert profiles to
                      date. <br />
                      <br />
                      We like you as much as you like us! However, if you ever
                      feel that an expert does not match your requirement,
                      please feel free to decline their profile as it would
                      help us improve our search.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <ProjectDrawer isDrawerVisible={isDrawerVisible} toggleDrawer={toggleDrawer} statusData={statusData} submitPop={submitPop} />
      </>

      <Modal
        show={modalToggle}
        onHide={handleClose1}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: "none", marginTop: "-8px" }} closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            <h4 className="modalHeader">
              {first_charc_uppercase(status)} a call with the expert
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "left", fontSize: "18px" }}>
            {statusSetter(status)}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-end"
          style={{ border: "none" }}
        >
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              id="closebutton1"
              variant="primary"
              style={{
                backgroundColor: "#5746ec",
                border: "none",
                color: "#ffff",
              }}
              data-bs-dismiss="modal"
              onClick={() => {
                handleSubmit(status);
              }}
            >
              {spinner ? (
                <>
                  <Spinner
                    size="sm"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "5px",
                    }}
                  />
                  {statusButton(status)}
                </>
              ) : (
                statusButton(status)
              )}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default DeclineTable;
