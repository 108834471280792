import React, { useState, useRef, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { UploadOutlined } from "@ant-design/icons";
import {
  Input,
  Select,
  DatePicker,
  Form,
  notification,
  Spin,
  Modal,
  Button,
  Divider,
  Upload,
  message,
} from "antd";
import { Col, Row } from "react-bootstrap";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import plugin for UTC handling
import customParseFormat from "dayjs/plugin/customParseFormat";
import debounce from "lodash/debounce";
import "./AddProjectModal.css";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import useAddProject from "./Hooks/useAddProject";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setAddData,
  setGeographies,
  setInputs,
} from "./features/addProject.slice";
import { Country } from "country-state-city";
import { MdUploadFile } from "react-icons/md";
import { ImUpload } from "react-icons/im";
import upload_icon from "../../assests/images/upload_icon.png";

dayjs.extend(customParseFormat);
dayjs.extend(utc); // Extend Day.js with the UTC plugin
const { Option } = Select;
const { Dragger } = Upload;
function AddProjectModal({
  show,
  handleClose,
  teamIdEdit,
  managerList,
  selectedOptions,
  currentPage,
  GetLiveProjectList = () => { },
  GetCompleteProjectList = () => { },
}) {
  const editorRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const countries = Country?.getAllCountries();
  const [fileList, setFileList] = useState([]);
  const [deletedfileList, setdeletedFileList] = useState([]);
  const { fetchAllClientList, fetchCompanyList } = useAddProject();
  const { companyList, allClientList, addData, geographies, inputs } =
    useSelector((state) => state.addProjectReducer);

  const debounceFetcher = React.useMemo(() => {
    let loadOptions = (value) => {
      fetchAllClientList(value);
    };

    return debounce(loadOptions, 1000);
  }, [fetchAllClientList]);

  const debounceFetcherCompany = React.useMemo(() => {
    let loadOptions = (value) => {
      fetchCompanyList(value);
    };

    return debounce(loadOptions, 1000);
  }, [fetchCompanyList]);

  const handleDropdownVisibleChange = async (isOpen) => {
    if (isOpen) {
      await fetchCompanyList("");
    }
  };
  const handleDropdownVisibleChangeClient = async (isOpen) => {
    if (isOpen) {
      await fetchAllClientList("");
    }
  };

  const handleChange = useCallback(
    (value, field) => {
      let datas;
      if (field === "client_name") {
        datas = {
          ...addData,
          client_name: value?.client_name,
          company_id: value?.company_id,
          client_domain: value?.client_domain,
          client_team_email: value?.client_team_email,
          client_email: value?.client_email,
        };
      } else {
        datas = {
          ...addData,
          [field]:
            (field === "project_start" || field === "project_deadline") && value
              ? dayjs(value)?.format("YYYY-MM-DD")
              : value,
        };
      }

      dispatch(setAddData(datas));
      // setAddData((prevData) => ({
      //   ...prevData,
      //   [field]:
      //     (field === "project_start" || field === "project_deadline") && value
      //       ? dayjs(value)?.format("YYYY-MM-DD")
      //       : value,
      // }));
    },
    [addData, dispatch]
  );

  const fetchData = useCallback(
    async (teamIdEdit) => {
      setLoadingProject(true);
      try {
        const res = await axios.get(
          `${API_PATH?.TEAM_PROJECT_ADD}/${teamIdEdit}`
        );
        const projectData = res?.data?.data;
        if (projectData) {
          dispatch(
            setAddData({
              project_title: projectData?.project_title,
              company_id: projectData?.company_id,
              project_start: projectData?.project_start
                ? dayjs(projectData?.project_start).utc()
                : null, // Parse and handle UTC
              project_deadline: projectData?.project_deadline
                ? dayjs(projectData?.project_deadline).utc()
                : null, // Parse and handle UTC
              client_name: projectData?.client_name,
              client_domain: projectData?.companies?.[0]?.domain,
              overview: projectData?.question,
              status:
                projectData?.status === 1
                  ? "active"
                  : projectData?.status === 2
                    ? "closed"
                    : "hold",
              client_email: projectData?.client_email,
              client_team_email: projectData?.clientteamemail?.map(
                (client) => client?.email
              ),
              linkedin_account: projectData?.linkedin_account,
              platform: projectData?.platform,
              manager_id: Array.isArray(projectData?.manager_id)
                ? projectData?.manager_id?.map((manager) => {
                  return managerList?.filter(
                    (manag) => manag?._id === manager
                  )?.[0]
                    ? {
                      label: managerList?.filter(
                        (manag) => manag?._id === manager
                      )?.[0]?.email,
                      value: managerList?.filter(
                        (manag) => manag?._id === manager
                      )?.[0]?._id,
                      key: managerList?.filter(
                        (manag) => manag?._id === manager
                      )?.[0]?._id,
                    }
                    : undefined;
                })
                : managerList?.filter(
                  (manag) => manag?._id === projectData?.manager_id
                )?.[0]
                  ? {
                    label: managerList?.filter(
                      (manag) => manag?._id === projectData?.manager_id
                    )?.[0]?.email,
                    value: managerList?.filter(
                      (manag) => manag?._id === projectData?.manager_id
                    )?.[0]?._id,
                    key: managerList?.filter(
                      (manag) => manag?._id === projectData?.manager_id
                    )?.[0]?._id,
                  }
                  : undefined,
              no_of_calls_expected: projectData?.no_of_calls_expected,
              no_of_profiles_sent: projectData?.no_of_profiles_sent,
              no_of_calls_completed: projectData?.no_of_calls_completed,
              remarks: projectData?.remarks,
              project_recieved_date: projectData?.project_recieved_date
                ? dayjs(projectData?.project_recieved_date).utc()
                : null,
              expert_status: projectData?.expert_status,
            })
          );
          if (editorRef?.current) {
            editorRef?.current?.setData(projectData?.question);
          }
          const geographiesArray = projectData?.country?.split(",");
          const inviteTeamArray = projectData?.invite_team_dashboard
            ?.split(",")
            ?.filter(
              (item) =>
                item.toLowerCase() !== userData?.data?.name?.toLowerCase()
            );
          dispatch(
            setGeographies(
              geographiesArray?.map((geo, index) => geo?.trim()) || []
            )
          );

          dispatch(setInputs(inviteTeamArray?.map((input) => input?.trim())));
          // Map the initial file URLs to Ant Design's Upload file structure
          let mappedFiles = projectData?.attachments?.map((obj, index) => ({
            uid: obj?.s3key, // unique identifier for the file
            name: obj?.file_original_name, // name of the file
            status: "done", // status 'done' because it's already uploaded
            url: obj?.s3url, // use the link you already have for the file
          }));
          setFileList(mappedFiles);

          let formObj = {
            project_title: projectData?.project_title,
            client_name: projectData?.client_name,
            client_poc_email: projectData?.client_email,
            client_team_email: projectData?.clientteamemail?.map(
              (client) => client?.email
            ),
            linkedin_account: projectData?.linkedin_account,
            geography_of_experts: geographiesArray?.map((geo, index) =>
              geo?.trim()
            ),
            add_team: inviteTeamArray?.map((input) => input?.trim()),
            project_start: projectData?.project_start
              ? dayjs(projectData?.project_start).utc()
              : null,
            project_deadline: projectData?.project_deadline
              ? dayjs(projectData?.project_deadline).utc()
              : null,
            project_recieved_on: "email",
            project_manager: Array.isArray(projectData?.manager_id)
              ? projectData?.manager_id?.map((manager) => {
                return managerList?.filter(
                  (manag) => manag?._id === manager
                )?.[0]
                  ? {
                    label: managerList?.filter(
                      (manag) => manag?._id === manager
                    )?.[0]?.email,
                    value: managerList?.filter(
                      (manag) => manag?._id === manager
                    )?.[0]?._id,
                    key: managerList?.filter(
                      (manag) => manag?._id === manager
                    )?.[0]?._id,
                  }
                  : undefined;
              })
              : managerList?.filter(
                (manag) => manag?._id === projectData?.manager_id
              )?.[0]
                ? {
                  label: managerList?.filter(
                    (manag) => manag?._id === projectData?.manager_id
                  )?.[0]?.email,
                  value: managerList?.filter(
                    (manag) => manag?._id === projectData?.manager_id
                  )?.[0]?._id,
                  key: managerList?.filter(
                    (manag) => manag?._id === projectData?.manager_id
                  )?.[0]?._id,
                }
                : undefined,
            no_of_calls_expected: {
              label: projectData?.no_of_calls_expected,
              value: projectData?.no_of_calls_expected,
              key: projectData?.no_of_calls_expected,
            },
            project_status:
              projectData?.status === 1
                ? "active"
                : projectData?.status === 2
                  ? "closed"
                  : "hold",
            project_overview: projectData?.question,
            project_recieved_date: projectData?.project_recieved_date
              ? dayjs(projectData?.project_recieved_date).utc()
              : null,
            expert_status: projectData?.expert_status,
            remarks: projectData?.remarks,
            attachement: mappedFiles,
          };

          form.setFieldsValue(formObj);
        } else {
          dispatch(
            setAddData({
              project_title: "",
              project_start: null,
              project_deadline: null,
              client_name: "",
              client_domain: "",
              company_id: "",
              overview: "",
              status: "active",
              client_email: "",
              client_team_email: [],
              linkedin_account: "",
              platform: "",
              manager_id: [],
              no_of_calls_expected: "",
              no_of_profiles_sent: "",
              no_of_calls_completed: "",
              remarks: "",
              project_recieved_date: null,
              expert_status: "",
            })
          );
          dispatch(setGeographies([]));
          dispatch(setInputs([]));
        }

        setLoadingProject(false);
      } catch (error) {
        console.error(error);
        setLoadingProject(false);
      }
    },
    [dispatch, form, managerList, userData?.data?.name]
  );
  const deleteFiles = useCallback(async () => {
    try {
      let fileToBeDeleted = deletedfileList?.filter(
        (file) => !file?.originFileObj
      );
      if (fileToBeDeleted) {
        const res = await axios.post(
          `${API_PATH.DELETE_FILE}`,
          {
            s3key: fileToBeDeleted?.map((file) => file?.uid),
            project_id: teamIdEdit,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );
      }
    } catch (error) {
      setLoading(false);
      return notification.error({
        message: "Error: error while deleting files",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [deletedfileList, teamIdEdit, userData?.token]);

  const uploadFiles = useCallback(
    async (project_id = teamIdEdit) => {
      let filterFilesToBeUpload = fileList?.filter(
        (file) => file?.originFileObj
      );
      if (filterFilesToBeUpload?.length > 0) {
        try {
          const formdata = new FormData();
          filterFilesToBeUpload?.forEach((file) => {
            if (file?.originFileObj) {
              formdata.append("attachment", file?.originFileObj);
            }
          });
          formdata.append("project_id", project_id);
          await axios.post(`${API_PATH.UPLOAD_FILE}`, formdata, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (error) {
          setLoading(false);
          return notification.error({
            message: error?.response?.data?.message,
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    },
    [fileList, teamIdEdit]
  );

  const AddTeamProject = useCallback(async () => {
    setLoading(true);
    try {
      let formattedInviteTeam = [];
      inputs.forEach((input) => formattedInviteTeam.push(input.value));
      const formattedGeography = geographies?.join(",");
      const inviteTeamString = inputs?.toString();

      const requestData = {
        project_title: addData?.project_title,
        overview: addData?.overview,
        project_start: addData?.project_start
          ? dayjs(addData?.project_start).format("YYYY-MM-DD")
          : null,
        project_deadline: addData?.project_deadline
          ? dayjs(addData?.project_deadline).format("YYYY-MM-DD")
          : null,
        admin_id: userData?.data?._id,
        invite_team_dashboard: inviteTeamString,
        client_name: addData?.client_name,
        company_id: addData?.company_id,
        geography: formattedGeography || "",
        status:
          addData?.status === "active"
            ? 1
            : addData?.status === "closed"
              ? 2
              : 3,
        client_email: addData?.client_email,
        client_team_email: addData?.client_team_email?.join(","),
        linkedin_account: addData?.linkedin_account,
        platform: addData?.platform,
        manager_id: Array.isArray(addData?.manager_id)
          ? addData?.manager_id?.map((manag) => manag?.key)
          : [addData?.manager_id?.key],
        no_of_calls_expected: addData?.no_of_calls_expected,
        remarks: addData?.remarks,
        project_recieved_date: addData?.project_recieved_date
          ? dayjs(addData?.project_recieved_date).format("YYYY-MM-DD")
          : null,
        expert_status: addData?.expert_status,
      };

      const requestDataEdit = {
        project_title: addData?.project_title,
        overview: addData?.overview,
        project_start: addData?.project_start
          ? dayjs(addData?.project_start).format("YYYY-MM-DD")
          : null,
        project_deadline: addData?.project_deadline
          ? dayjs(addData?.project_deadline).format("YYYY-MM-DD")
          : null,
        project_id: teamIdEdit,
        invite_team_dashboard: inviteTeamString,
        client_name: addData?.client_name,
        company_id: addData?.company_id,
        geography: formattedGeography || "",
        status:
          addData?.status === "active"
            ? 1
            : addData?.status === "closed"
              ? 2
              : 3,
        client_email: addData?.client_email,
        client_team_email: addData?.client_team_email?.join(","),
        linkedin_account: addData?.linkedin_account,
        platform: addData?.platform,
        manager_id: Array.isArray(addData?.manager_id)
          ? addData?.manager_id?.map((manag) => manag?.key)
          : [addData?.manager_id?.key],
        no_of_calls_expected: addData?.no_of_calls_expected?.toString(),
        no_of_profiles_sent: addData?.no_of_profiles_sent?.toString(),
        no_of_calls_completed: addData?.no_of_calls_completed?.toString(),
        remarks: addData?.remarks,
        project_recieved_date: addData?.project_recieved_date
          ? dayjs(addData?.project_recieved_date).format("YYYY-MM-DD")
          : null,
        expert_status: addData?.expert_status,
      };

      if (teamIdEdit) {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestDataEdit),
        });

        const result = await res.json();
        return result;
      } else {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestData),
        });

        const result = await res.json();
        return result;
      }
    } catch (error) {
      console.error("ADD PROJECT Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  }, [
    addData?.client_email,
    addData?.client_name,
    addData?.client_team_email,
    addData?.company_id,
    addData?.expert_status,
    addData?.linkedin_account,
    addData?.manager_id,
    addData?.no_of_calls_completed,
    addData?.no_of_calls_expected,
    addData?.no_of_profiles_sent,
    addData?.overview,
    addData?.platform,
    addData?.project_deadline,
    addData?.project_recieved_date,
    addData?.project_start,
    addData?.project_title,
    addData?.remarks,
    addData?.status,
    geographies,
    inputs,
    teamIdEdit,
    userData?.data?._id,
    userData?.token,
  ]);

  useEffect(() => {
    if (teamIdEdit) {
      fetchData(teamIdEdit);
      setLoading(false);
    } else {
      form.resetFields();
      form.setFieldValue("project_status", "active");
      dispatch(
        setAddData({
          project_title: "",
          project_start: null,
          project_deadline: null,
          client_name: "",
          company_id: "",
          overview: "",
          status: "active",
          client_email: "",
          client_team_email: [],
          linkedin_account: "",
          platform: "",
          manager_id: [],
          no_of_calls_expected: "",
          no_of_profiles_sent: "",
          no_of_calls_completed: "",
          remarks: "",
          project_recieved_date: null,
          expert_status: "",
        })
      );
      setLoading(false);
      dispatch(setGeographies([]));
      dispatch(setInputs([]));
    }
  }, [dispatch, fetchData, form, teamIdEdit]);

  return (
    <Modal
      title={
        <div>
          {teamIdEdit ? "Update Project" : "Add New Project"}
          <Divider className="m-2" />
        </div>
      }
      open={show}
      onCancel={() => {
        dispatch(
          setAddData({
            project_title: "",
            project_start: null,
            project_deadline: null,
            client_name: "",
            company_id: "",
            client_domain: "",
            overview: "",
            status: "active",
            client_email: "",
            client_team_email: [],
            linkedin_account: "",
            platform: "",
            manager_id: [],
            no_of_calls_expected: "",
            no_of_profiles_sent: "",
            no_of_calls_completed: "",
            remarks: "",
            project_recieved_date: null,
            expert_status: "",
          })
        );
        form.resetFields();
        form.setFieldValue("project_status", "active");
        dispatch(setGeographies([]));
        dispatch(setInputs([]));
        handleClose();
      }}
      centered
      footer={false}
      width={1000}
      destroyOnClose
      bodyStyle={{
        height: 600,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
      className="modal_scroll removeScrollbar"
    >
      {!loadingProject && (
        <div>
          <Form
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={async () => {
              let res = await AddTeamProject();
              console.log("res: TOOK RESPONSE", res);
              let project_id = res?.data || teamIdEdit;
              await uploadFiles(project_id);
              console.log("res:UPLOAD FILE", res);
              if (deletedfileList?.length > 0) {
                await deleteFiles();
              }
              console.log("res:DELETE FILE", res);
              console.log("res:RESET FILE", res);

              handleClose();
              setFileList([]);
              form.resetFields();
              form.setFieldValue("project_status", "active");
              GetLiveProjectList();
              GetCompleteProjectList();
              setLoading(false);
              dispatch(
                setAddData({
                  project_title: "",
                  project_start: null,
                  project_deadline: null,
                  client_name: "",
                  client_domain: "",
                  company_id: "",
                  overview: "",
                  status: "active",
                  client_email: "",
                  client_team_email: [],
                  linkedin_account: "",
                  platform: "",
                  manager_id: [],
                  no_of_calls_expected: "",
                  no_of_profiles_sent: "",
                  no_of_calls_completed: "",
                  remarks: "",
                  project_recieved_date: null,
                  expert_status: "",
                })
              );
              dispatch(setGeographies([]));
              dispatch(setInputs([]));
              notification.success({
                message: "Project has been created",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });
            }}
          >
            <Row>
              <Col md={8}>
                <Form.Item
                  name="project_title"
                  label="Project Title"
                  rules={[
                    { required: true, message: "Project title is required" },
                  ]}
                >
                  <Input
                    type="text"
                    size="large"
                    placeholder="Project Title"
                    name="project_title"
                    value={addData?.project_title || null}
                    onChange={(event) =>
                      handleChange(event.target.value, "project_title")
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={4}>
                <div className=" mb-1">
                  <Form.Item
                    className="mb-0"
                    label="Project Status"
                    name="project_status"
                    rules={[
                      { required: true, message: "Project status is required" },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Select
                        value={addData?.status || null}
                        style={{ flex: 1, marginRight: "8px" }}
                        placeholder="Select Status"
                        name="project_status"
                        size="large"
                        virtual={false}
                        allowClear
                        onChange={(selectedValue) => {
                          handleChange(selectedValue, "status");
                        }}
                        filterOption={(input, option) =>
                          option?.props?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {["Active", "Hold", "Closed"]?.map((status, index) => (
                          <Select.Option
                            key={index}
                            value={status?.toLowerCase()}
                          >
                            {status}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  name="client_name"
                  label="Client Name"
                  rules={[
                    { required: true, message: "Client name is required" },
                  ]}
                  className="mb-0"
                >
                  <Select
                    showSearch
                    allowClear
                    type="client"
                    name="client_name"
                    optionType="email"
                    filterOption={false}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    onSearch={debounceFetcherCompany}
                    size="large"
                    value={addData?.client_name || null}
                    placeholder="Select Client Company"
                    onChange={(newValue, option) => {
                      handleChange(
                        {
                          client_name: newValue,
                          company_id: option?.key,
                          client_domain: option?.title,
                          client_team_email: [],
                          client_email: "",
                        },
                        "client_name"
                      );
                      form.resetFields([
                        "client_poc_email",
                        "client_team_email",
                      ]);
                    }}
                    style={{ width: "100%" }}
                    options={companyList?.map((item) => ({
                      label: item?.companyName,
                      value: item?.companyName,
                      title: item?.companyDomain,
                      key: item?._id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Client POC Email"
                  name="client_poc_email"
                  rules={[
                    { required: true, message: "Client POC email is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    name="client_poc_email"
                    type="client"
                    disabled={!addData?.client_name}
                    optionType="email"
                    filterOption={false}
                    onDropdownVisibleChange={handleDropdownVisibleChangeClient}
                    onSearch={debounceFetcher}
                    size="large"
                    value={addData?.client_email || null}
                    placeholder="Select Client POC Email"
                    onChange={(newValue, option) => {
                      handleChange(newValue, "client_email");
                    }}
                    style={{ width: "100%" }}
                    options={allClientList
                      ?.filter(
                        (item) =>
                          item?.email !== addData?.client_email &&
                          !addData?.client_team_email?.includes(item?.email)
                      )
                      ?.map((item) => ({
                        label: item?.email,
                        value: item?.email,
                        key: item?._id,
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Client Team Emails" name="client_team_email">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    name="client_team_email"
                    type="client"
                    optionType="email"
                    disabled={!addData?.client_name}
                    filterOption={false}
                    onDropdownVisibleChange={handleDropdownVisibleChangeClient}
                    onSearch={debounceFetcher}
                    size="large"
                    value={addData?.client_team_email || []}
                    placeholder="Select Client Team Emails"
                    onChange={(newValue, option) => {
                      handleChange(newValue, "client_team_email");
                      fetchAllClientList("");
                    }}
                    style={{ width: "100%" }}
                    options={allClientList
                      ?.filter(
                        (item) =>
                          item?.email !== addData?.client_email &&
                          !addData?.client_team_email?.includes(item?.email)
                      )
                      ?.map((item) => ({
                        label: item?.email,
                        value: item?.email,
                        key: item?._id,
                      }))}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Linkedin Account (Reachouts)"
                  name="linkedin_account"
                >
                  <Input
                    type="text"
                    size="large"
                    name="linkedin_account"
                    value={addData?.linkedin_account}
                    onChange={(event) =>
                      handleChange(event.target.value, "linkedin_account")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Item
                  name="project_recieved_date"
                  label="Project Recieved Date"
                  rules={[
                    {
                      required: true,
                      message: "Project Recieved date is required",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%", zIndex: "1100" }}
                    name="project_recieved_date"
                    value={
                      addData?.project_recieved_date
                        ? dayjs(addData?.project_recieved_date, "YYYY-MM-DD")
                        : null
                    }
                    selected={addData?.project_recieved_date}
                    disabledDate={(current) =>
                      current && current?.isAfter(dayjs(), "D")
                    }
                    format="YYYY-MM-DD"
                    onChange={(value) =>
                      handleChange(value, "project_recieved_date")
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item
                  name="project_start"
                  label="Project Start Date"
                  rules={[
                    {
                      required: true,
                      message: "Project start date is required",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%", zIndex: "1100" }}
                    name="project_start"
                    value={
                      addData?.project_start
                        ? dayjs(addData?.project_start, "YYYY-MM-DD")
                        : null
                    }
                    selected={addData?.project_start}
                    disabledDate={(current) =>
                      current && current?.isAfter(dayjs(), "D")
                    }
                    format="YYYY-MM-DD"
                    onChange={(value) => handleChange(value, "project_start")}
                  />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item
                  name="project_deadline"
                  label="Project Deadline"
                // rules={[
                //   { required: true, message: "Project deadline is required" },
                // ]}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%", zIndex: "1100" }}
                    value={
                      addData?.project_deadline
                        ? dayjs(addData?.project_deadline, "YYYY-MM-DD")
                        : null
                    }
                    disabledDate={(current) => {
                      return (
                        addData?.project_start &&
                        current &&
                        current.isBefore(
                          dayjs(addData?.project_start, "YYYY-MM-DD"),
                          "day"
                        )
                      );
                    }}
                    format="YYYY-MM-DD"
                    name="project_deadline"
                    selected={addData?.project_deadline}
                    onChange={(value) =>
                      handleChange(value, "project_deadline")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="projectEditor ck-body-wrapper">
                <Form.Item
                  label="Project Overview"
                  name="project_overview"
                  rules={[
                    { required: true, message: "Project overview is required" },
                  ]}
                >
                  <CKEditor
                    editor={Editor}
                    data={addData?.overview || ""} // Prefill CKEditor with fetched overview data
                    onReady={(editor) => {
                      // editorRef.current = editor;
                      console.info("Editor is ready to use!", editor);
                    }}
                    name="project_overview"
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "undo",
                        "redo",
                      ],
                      placeholder:
                        "Please mention clearly the Purpose of the Project/End Goal, Profile of Expert, Target Companies, End Deliverable, Stage of Research, Resources Used, Update Expectations of the project.",
                      // extraPlugins: 'autogrow',
                      // autoGrow_bottomSpace: 0,
                      autoGrow_minHeight: 800, // Set a fixed minimum height
                      autoGrow_maxHeight: 500, // Set a fixed maximum height
                    }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      handleChange(newData, "overview");
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={8} xl={6}>
                <Form.Item label="Documents" name="attachement">
                  <Dragger
                    className="w-100 document_uploader"
                    action={false}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      // Simulate an upload request
                      setTimeout(() => {
                        console.log("Uploading file:", file);
                        onSuccess("ok"); // Simulate successful upload
                      }, 1000);
                    }}
                    beforeUpload={async (file) => {
                      console.log('file: ', file);
                      const isLt10M = file.size / 1024 / 1024 < 10;
                      if (!isLt10M) {
                        message.error({
                          content: "File must be smaller than 10MB",
                          style: {
                            fontSize: "18px", // Increase the font size
                            padding: "20px", // Increase the padding
                          },
                        });
                        form.setFields([
                          {
                            name: "attachement",
                            errors: [`File must be smaller than 10MB.`],
                          },
                        ]);
                      }
                      const isPNG = file.type === "image/png";
                      const isJPEG = file.type === "image/jpeg";
                      const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                      const isPDF = file.type === "application/pdf";
                      const isCSV = file.type === "text/csv";
                      if (!isPNG && !isJPEG && !isPDF && !isWordsheet && !isSpreadsheet && !isCSV) {
                        form.setFields([
                          {
                            name: "attachement",
                            errors: [`Please upload a file of a PDF, PNG, CSV, Xlsx, docx or JPEG format.`],
                          },
                        ]);
                      }
                      return isPNG || isJPEG || isPDF || isSpreadsheet || isWordsheet || isCSV || isLt10M || Upload.LIST_IGNORE;
                    }}
                    onRemove={async (file) => {
                      console.log("remove file: ", file);
                      setdeletedFileList([...deletedfileList, file]);
                      setFileList(fileList?.filter((f) => f.uid !== file.uid));
                      // await deleteProject(file)
                    }}
                    onChange={({ fileList }) => {
                      let list = fileList
                        ?.map((file) => {
                          if (file?.size) {
                            const isLt10M = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB
                            const isPNG = file.type === "image/png";
                            const isJPEG = file.type === "image/jpeg";
                            const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                            const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                            const isPDF = file.type === "application/pdf";
                            const isCSV = file.type === "text/csv";
                            if ((isPNG || isJPEG || isPDF || isWordsheet || isSpreadsheet || isCSV) && isLt10M) {
                              return file
                            }
                          } else {
                            return file;
                          }
                        })
                        ?.filter((li) => li !== undefined);
                      console.log('list: ', list);
                      setFileList(list);
                    }}
                    multiple={true}
                    fileList={fileList}
                  >
                    <img
                      src={upload_icon}
                      style={{ width: "60px" }}
                      alt="Upload Icon"
                    />
                    <p className="ant-upload-text pb-0 mt-4">
                      Please upload all relevant documents regarding the project
                    </p>
                    <small className="text-muted">
                      Maximum file size 10MB each
                    </small>
                  </Dragger>
                  {/* <Upload

                    action={false}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      // Simulate an upload request
                      setTimeout(() => {
                        console.log("Uploading file:", file);
                        onSuccess("ok"); // Simulate successful upload
                      }, 1000);
                    }}
                    beforeUpload={async (file) => {
                      // Optional validation before uploading (like file size/type check)
                      console.log("Validating file:", file);
                      return true; // Return false to prevent upload
                    }}
                    onChange={({ fileList }) => {
                      // Update file list on change
                      setFileList(fileList);
                    }}
                    multiple={true}
                    fileList={fileList}

                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Geography of experts"
                  name="geography_of_experts"
                  rules={[
                    {
                      required: true,
                      message: "Geography of experts is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    value={geographies || []}
                    mode="multiple"
                    style={{ flex: 1, marginRight: "8px" }}
                    name="geography_of_experts"
                    size="large"
                    virtual={false}
                    allowClear
                    onChange={(selectedValue) => {
                      dispatch(setGeographies(selectedValue));
                    }}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <>
                      <Select.Option key={0} value={"Global"} label={"Global"}>
                        Global
                      </Select.Option>
                      <Select.Option key={0} value={"SAARC"} label={"SAARC"}>
                        SAARC
                      </Select.Option>
                      <Select.Option key={1} value={"Asia"} label={"Asia"}>
                        Asia
                      </Select.Option>
                      <Select.Option
                        key={2}
                        value={"Middle East"}
                        label={"Middle East"}
                      >
                        Middle East
                      </Select.Option>
                      <Select.Option key={3} value={"Europe"} label={"Europe"}>
                        Europe
                      </Select.Option>
                      <Select.Option
                        key={4}
                        value={"North America"}
                        label={"North America"}
                      >
                        North America
                      </Select.Option>
                      <Select.Option
                        key={5}
                        value={"South America"}
                        label={"South America"}
                      >
                        South America
                      </Select.Option>
                    </>
                    {countries?.map((country, index) => (
                      <Select.Option
                        key={index + 5}
                        value={country?.name}
                        label={country?.name}
                      >
                        {`${country?.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <div>
                  <Form.Item label="Add Team (Optional)" name={"add_team"}>
                    <Select
                      size="large"
                      name={"add_team"}
                      value={inputs}
                      mode="multiple"
                      showSearch
                      onChange={(selectedValue) => {
                        dispatch(setInputs(selectedValue));
                      }}
                      style={{ flex: 1, marginRight: "8px" }}
                      filterOption={(input, option) =>
                        option?.props?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      virtual={false}
                      allowClear
                    >
                      {managerList?.map((item, index) => (
                        <Select.Option
                          key={index}
                          value={item?.email?.split("@")[0]}
                          label={item?.email?.split("@")[0]}
                        >
                          {`${item?.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Item
                  label="Project recieved on"
                  name="project_recieved_on"
                  rules={[
                    {
                      required: true,
                      message: "Project recieved on is required",
                    },
                  ]}
                >
                  <Select
                    value={addData.platform}
                    style={{ flex: 1, marginRight: "8px" }}
                    size="large"
                    name="project_recieved_on"
                    virtual={false}
                    allowClear
                    onChange={(selectedValue) => {
                      handleChange(selectedValue, "platform");
                    }}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {["Email", "Whatsapp", "Phone Call", "Other"]?.map(
                      (status, index) => (
                        <Select.Option
                          key={index}
                          value={status?.toLowerCase()}
                        >
                          {status}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label="No. of calls expected"
                  rules={[
                    {
                      required: true,
                      message: "No. of calls expected is required",
                    },
                  ]}
                  name="no_of_calls_expected"
                >
                  <Select
                    size="large"
                    labelInValue
                    name="no_of_calls_expected"
                    allowClear
                    placeholder="Expected Calls"
                    autoComplete="off"
                    showSearch
                    value={addData?.no_of_calls_expected}
                    onChange={(value) => {
                      handleChange(
                        value?.value?.toString(),
                        "no_of_calls_expected"
                      );
                    }}
                  >
                    {Array.from(Array(200).keys())?.map((item) => {
                      return <Option value={item + 1}>{item + 1}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>{" "}
              {/* <Col md={4}>
                <Form.Item
                  label="Expert Status"
                  name="expert_status"
                  rules={[
                    { required: true, message: "Expert Status is required" },
                  ]}
                >
                  <Select
                    value={addData.expert_status}
                    style={{ flex: 1, marginRight: "8px" }}
                    size="large"
                    name="expert_status"
                    placeholder="Select Expert Status"
                    virtual={false}
                    allowClear
                    onChange={(selectedValue) => {
                      handleChange(selectedValue, "expert_status");
                    }}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {["Current", "Former", "Both"]?.map((status, index) => (
                      <Select.Option key={index} value={status?.toLowerCase()}>
                        {status}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            {/* <Row> */}
            {/* <Col md={6}>
                <Form.Item
                  label="Project Manager"
                  name="project_manager"
                >
                  <DebounceSelect
                    type="manager"
                    optionType="email"
                    mode={"multiple"}
                    size="large"
                    name={`project_manager`}
                    value={addData?.manager_id}
                    placeholder="Select Managers"
                    style={{ flex: 1, marginRight: "8px" }}
                    virtual={false}
                    onChange={(selectedValue) => {
                      console.log("selectedValue: ", selectedValue);
                      handleChange(selectedValue, "manager_id");
                    }}
                    allowClear
                  />
                </Form.Item>
              </Col> */}
            {/* </Row> */}
            <Row>
              <Col md={12}>
                <Form.Item label="Additional Notes (Optional)" name="remarks">
                  <Input
                    type="text"
                    size="large"
                    name="remarks"
                    value={addData?.remarks}
                    onChange={(event) =>
                      handleChange(event.target.value, "remarks")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Form.Item className="m-0">
                <Button
                  className="secondaryBttn"
                  htmlType="button"
                  onClick={() => {
                    dispatch(
                      setAddData({
                        project_title: "",
                        project_start: null,
                        project_deadline: null,
                        client_name: "",
                        company_id: "",
                        client_domain: "",
                        overview: "",
                        status: "active",
                        client_email: "",
                        client_team_email: [],
                        linkedin_account: "",
                        platform: "",
                        manager_id: [],
                        no_of_calls_expected: "",
                        no_of_profiles_sent: "",
                        no_of_calls_completed: "",
                        remarks: "",
                        project_recieved_date: null,
                        expert_status: "",
                      })
                    );
                    setFileList([]);
                    form.resetFields();
                    form.setFieldValue("project_status", "active");
                    dispatch(setGeographies([]));
                    dispatch(setInputs([]));
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </Form.Item>
              <Form.Item className="m-0">
                <Button htmlType="submit" className="viewButton ms-2" disabled={loading}>
                  Submit{" "}
                  {loading && (
                    <Spin
                      size="small"
                      indicator={
                        <LoadingOutlined
                          style={{
                            color: "white",
                          }}
                        />
                      }
                    />
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default AddProjectModal;
