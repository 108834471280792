import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Spin } from "antd";
import { LiaIndustrySolid } from "react-icons/lia";


function ScopeProjectInfomation({ datas, projectDetails }) {
  const [Loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    setLoading(false);
  }, [datas]);

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const splitArray = (array) => {
    let newarray = array.split(",");
    return newarray;
    //console.log(newarray);
  };

  if (!Loading) {
    return (
      <>

        <div className="d-flex align-items-center justify-content-between">
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: "14px",
            }}
          >
            Start Date:
          </span>
          <span
            className="col-indigo"
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {convertDate(datas?.created_at)}
          </span>
        </div>

        {/* <li>
            <figure className="l-icon">
              <img className="width20" src="images/expert-icon2.svg" />
            </figure>
            <div className="text-ex">{datas.call_recorded} Recording</div>
          </li> */}

        {/* <li>
            <figure className="l-icon">
              <img className="width20" src="images/expert-icon3.svg" />
            </figure>
            <div className="text-ex">{datas.call_transcript} Transcription</div>
          </li> */}
        {datas?.country ? (
          <div className="d-flex align-items-center justify-content-between">
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            >
              Country:
            </span>
            <span
              className="col-indigo"
              style={{ fontWeight: 500, fontSize: "14px" }}
            >
              {datas?.country?.replace(/,(?=[^\s])/g, ", ")}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="d-flex align-items-center justify-content-between">
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: "14px",
            }}
          >
            Target Companies:
          </span>
          <span
            className="col-indigo"
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {datas?.target_companies?.length > 0 ? (
              <div className="text-ex list-btn3">
                {splitArray(datas?.target_companies)
                  .map((curElem, id) => {
                    return curElem;
                  })
                  .join(", ")}
              </div>
            ) : (
              <a style={{ marginLeft: "10px" }}>No companies found</a>
            )}
          </span>
        </div>
        {datas?.expert_industry_name?.[0]?.category_name ? (
          <div className="d-flex align-items-center justify-content-between">
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            >
              Industries:
            </span>
            <span
              className="col-indigo"
              style={{ fontWeight: 500, fontSize: "14px" }}
            >
              {datas?.expert_industry_name?.[0]?.category_name?.replace(/,(?=[^\s])/g, ", ")}
            </span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </div>
      </>
    );
  }

}

export default ScopeProjectInfomation;
