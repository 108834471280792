import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
  message,
  Pagination,
  notification,
  Dropdown,
  Menu,
  Tooltip,
} from "antd";
import React, { useState, useEffect, useMemo } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  MdDelete,
  MdBorderColor,
  MdModeEditOutline,
  MdOutlineCheck,
  MdOutlineClose,
} from "react-icons/md";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const { Option } = Select;

const LeadTable = () => {
  let history = useNavigate();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [zoomId, setZoomId] = useState("");
  const [leadList, setLeadList] = useState([]);
  const [zoomAccountList, setZoomAccountList] = useState("");
  const [zoomCompanyList, setZoomCompanyList] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewId, setViewId] = useState("");
  console.log(viewId, "viewId");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [zoomData, setZoomData] = useState({
    zoomId: "",
    meetingId: "",
    startDate: "",
    passcode: "",
    startTime: "",
    endDate: "",
    endTime: "",
    comapnyName: "",
    client: "",
    client_user: [],
    client_user_id: [],
    client_id: viewId,
  });

  console.log(zoomData.meetingId, "meetingId");

  const [filteredClientList, setFilteredClientList] = useState([]);
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const GetLeadList = async (page, selectedOptions) => {
    setLoading(true);
    try {
      let object = {};

      console.log(object, "object");

      if (selectedOptions?.key) {
        object.search = selectedOptions?.key;
      }
      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

      const res = await fetch(
        `${API_PATH.LEAD_LIST
        }?page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        console.log(result, "resultData");
        setLeadList(result?.data);
        setCallPage(result?.totalDataCount);
        console.log(result?.totatDataCount, "totatDataCount");
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Lead Error", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const filteredData = leadList?.filter((record) =>
      Object.values(record)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredData);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredData?.length,
    });
  }, [leadList, searchText]);

  const constructUrl = (options) => {
    const { key } = options;

    const projectParam = Array.isArray(key) ? key.join(", ") : key;
    const sanitizedProject = projectParam
      ? projectParam.replace(/%20/g, " ")
      : "";

    const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

    return history({
      pathname: `/dashboard/lead`,
      search: `?${urlParams.join("&")}`,
    });
  };

  useEffect(() => {
    GetLeadList(currentPage, selectedOptions);
    const url = constructUrl(selectedOptions);
    console.log(url); // Output the constructed UR
  }, [currentPage, selectedOptions]);

  const demo_status = [
    { key: 1, label: "Done", value: "Done" },
    { key: 2, label: "Rescheduled", value: "Rescheduled" },
    { key: 3, label: "Cancelled", value: "Cancelled" },
  ];

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const handleChange = async (value, _id) => {
    console.log(value, "value");
    console.log(_id, "_id");

    try {
      const res = await fetch(`${API_PATH.LEAD_DEMO_STATUS}/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          demo_status: value,
        }),
      });

      const result = await res?.json();
      if (result) {
        GetLeadList();
        if (result?.status === 200 || result?.status === true) {
          // window.location.reload();
          notification.success({
            message: "Status updated successfully",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Lead Status Error", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const GenerateLOgin = async (record) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH?.LEAD_LOGIN_CREDENTIAL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          name: record?.fullName,
          email: record?.email,
          contact_number: record?.contact_number,
          companyName: record?.companyName,
          company_id: record?._id,
          contact_code: record?.contact_code,
          country: record?.country,
          is_demo_user: true,
        }),
      });

      const result = await res?.json();
      console.log(result, "result-->");
      if (result) {
        GetLeadList();
        if (result?.status === 200 || result?.status === true) {
          // window.location.reload();
          notification.success({
            message: "Login credential generated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else if (result?.status === false) {
          notification.error({
            message: result?.message || "Error: Something went wrong",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        // Handle other cases
        notification.error({
          message: "Error: Something went wrong",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Generate Credentials Error", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });
    setSearchText(inputValue);
    setCurrentPage(1);
  };
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const columns = [
    {
      title: "Company name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text) => (
        <span title={text} style={{ display: "inline-block", maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact_number",
      key: "contact_number",
      render: (text, record) => {
        const phoneNumber = record?.phoneNumber;
        if (phoneNumber) {
          // If phoneNumber exists, use it with a leading '+'
          return <span>{`+${phoneNumber}`}</span>;
        }

        const countryCode = record?.contact_code;

        const formattedCountryCode =
          countryCode && !countryCode.includes("+")
            ? `+${countryCode === null ? "" : countryCode}`
            : countryCode === null
              ? ""
              : countryCode;

        const combinedNumber = `${formattedCountryCode} ${text}`;

        return <span>{combinedNumber}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <span title={text} style={{ display: "inline-block", maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Verification",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        const isVerified = record.verification_token === null;
        const style = {
          color: isVerified ? "green" : "red",
          fontWeight: "normal",
        };

        return (
          <span style={style}>{isVerified ? "Verified" : "Not Verified"}</span>
        );
      },
    },
    {
      title: "Demo Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span className="px-2 createdDate">
          {convertDate(record?.createdAt)}
        </span>
      ),
    },
    {
      title: "Demo Status",
      // dataIndex: "name",
      // key: "name",
      render: (record) => (
        <>
          <Tooltip color="#5746EB" title={!permission?.[18]?.lead?.edit ? "Permission Denied" : ""} >
            <Select
              placeholder="Select"
              value={record?.demo_status ? record?.demo_status : "Select"}
              onChange={(value) => handleChange(value, record?._id)}
              style={{
                width: 110,
              }}
              disabled={!permission?.[18]?.lead?.edit}
              size="large"
              virtual={false}
              allowClear
            >
              {demo_status && demo_status?.length > 0 ? (
                demo_status.map((item, index) => (
                  <Option value={item?.value} key={item?.key}>
                    {item?.label}
                  </Option>
                ))
              ) : (
                <option value="">No data found</option>
              )}
            </Select>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Generate Credentials",
      dataIndex: "_id",
      key: "add",
      render: (text, record) => (
        <Tooltip color="#5746EB" title={!permission?.[18]?.lead?.edit ? "Permission Denied"
          : ""}>
          <Space size="middle">
            {record?.credential_generated || !permission?.[18]?.lead?.edit ? (
              <Button type="primary" style={{ opacity: 0.7 }} disabled>
                Generated
              </Button>
            ) : (
              <Button type="primary" onClick={() => GenerateLOgin(record)}>
                Generate
              </Button>
            )}
          </Space>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Lead</h2>
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              loading={loading}
              pagination={false}
              // onChange={handleTableChange}
              scroll={{ x: 500 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadTable;
