import { SearchOutlined } from "@ant-design/icons";
import {
  Input,
  Table,
  Pagination,
  notification,
  Tooltip,
  Tag,
  Modal,
  Divider,
} from "antd";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import _ from "lodash";

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { SiMinutemailer } from "react-icons/si";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import {
  MdContentCopy,
  MdEdit,
} from "react-icons/md";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { BsEyeFill } from "react-icons/bs";
import ViewZoomMeetingDrawer from "./ViewZoomMeetingDrawer";
// import { resetZoomMeeting } from "./zoomMeeting.slice";
import { useDispatch } from "react-redux";
import useZoomMeeting from "./useZoomMeeting";
import CreditModal from "./CreditModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaPlus } from "react-icons/fa6";
import { Spinner } from "reactstrap";
import { LuRefreshCw } from "react-icons/lu";
import SelectDateRangeModal from "../CallRecords/SelectDateRangeModal";
import { FiDownload } from "react-icons/fi";

const ZoomDraftTable = () => {
  let history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [copiedId, setCopiedId] = useState(null);
  const backendDateFormat = "YYYY-MM-DD";
  const [open, setOpen] = useState(false);
  const [openCreditModal, setOpenCreaditModal] = useState({
    index: null,
    open: false,
    data: {},
  });

  // const { form } = useZoomMeeting();
  const [zoomId, setZoomId] = useState("");
  const [zoomList, setZoomList] = useState([]);
  // const [zoomAccountList, setZoomAccountList] = useState([]);
  // const [zoomCompanyList, setZoomCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isOpenCalModal, setIsOpenCalModal] = useState(false);

  // const [viewId, setViewId] = useState("");
  // const [roleList, setRoleList] = useState([]);
  // const [phone, setPhone] = useState("");
  // const [companyOption, setFilteredCompany] = useState("");
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const [selectedOptions, setSelectedOptions] = useState({
    key: "",
    sortType: "",
    sortBy: "",
  });
  // const [zoomData, setZoomData] = useState({
  //   zoomId: "",
  //   meetingId: "",
  //   startDate: "",
  //   passcode: "",
  //   startTime: "",
  //   endDate: "",
  //   endTime: "",
  //   comapnyName: "",
  //   client: "",
  //   client_user: [],
  //   client_user_id: [],
  //   client_id: viewId,
  //   zoommeeting_id: "",
  //   expertCompany: "",
  //   expertDesignation: "",
  // });

  // const [clienData, setClienData] = useState({
  //   name: "",
  //   email: "",
  //   comapnyName: "",
  //   contact_number: "",
  //   country: "",
  //   contact_code: "",
  //   role_id: "",
  //   registrationType: "",
  // });
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const callOnce = useRef(false);
  // const [clientView, setClientView] = useState([]);

  const GetZoomList = useCallback(
    async ({
      page = currentPage || 1,
      key = selectedOptions.key,
      sortBy = selectedOptions.sortBy,
      sortType = selectedOptions.sortType,
    }) => {
      setLoading(true);
      try {
        let object = {};
        if (key) {
          object.key = key;
        }
        const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

        const res = await fetch(
          `${API_PATH.LIST_DRAFT_MEETING}?page=${page}&admin_id=${userData?.data?._id
          }&limit=10&sortBy=${sortBy}&sortType=${sortType}${queryParams?.toString() !== "" ? "&" + queryParams?.toString() : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setZoomList(result?.data);
          setCallPage(result?.countData);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);

        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [
      currentPage,
      selectedOptions.key,
      selectedOptions.sortBy,
      selectedOptions.sortType,
      userData?.data?._id,
      userData?.token,
    ]
  );
  const handlePageChange = useCallback(
    async (pageNumber) => {
      setCurrentPage(pageNumber);
      await GetZoomList({ page: pageNumber });
    },
    [GetZoomList]
  );

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  // const GetZoomAccountList = useCallback(async () => {
  //   try {
  //     const res = await fetch(`${API_PATH.ZOOM_ACCOUNT_LIST}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       setZoomAccountList(result?.data);
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //   }
  // }, [userData?.token]);

  // const GetZoomCompanyList = useCallback(async () => {
  //   let object = {};

  //   if (companyOption) {
  //     object.key = `${companyOption}`;
  //   }

  //   const queryParams = new URLSearchParams(object);

  //   try {
  //     const res = await fetch(
  //       // `${API_PATH.CLIENT_LIST}?page=1&limit=10&${queryParams.toString()}`,
  //       `${API_PATH.CLIENT_LIST}?${queryParams.toString()}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userData?.token}`,
  //         },
  //       }
  //     );

  //     const result = await res.json();
  //     if (result) {
  //       setZoomCompanyList(result?.data);
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //   }
  // }, [companyOption, userData?.token]);

  // const GetRoleList = useCallback(async () => {
  //   try {
  //     const res = await fetch(`${API_PATH.CLIENT_ROLE}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       setRoleList(result?.data);
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {

  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //   }
  // }, [userData?.token]);

  const constructUrl = useCallback(
    (options) => {
      const { key } = options;

      const projectParam = Array.isArray(key) ? key.join(", ") : key;
      const sanitizedProject = projectParam
        ? projectParam.replace(/%20/g, " ")
        : "";

      const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

      return history({
        pathname: `/dashboard/draft-zoom-meetings`,
        search: `?${urlParams.join("&")}`,
      });
    },
    [history]
  );

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetZoomList({
        page: currentPage,
        key: selectedOptions.key,
        sortBy: selectedOptions.sortBy,
        sortType: selectedOptions.sortType,
      });
      // GetZoomAccountList();
      // GetZoomCompanyList();
      // GetRoleList();
      constructUrl(selectedOptions);
    }
  }, [
    currentPage,
    selectedOptions,
    // companyOption,
    GetZoomList,
    // GetZoomCompanyList,
    // GetRoleList,
    constructUrl,
  ]);

  // const handleNameChange = useCallback(
  //   async (value, option) => {
  //     const selectedCompanyId = option?.key;
  //     const selectedDomain = option?.domain;
  //     setViewId(selectedCompanyId);

  //     zoomData.client_id = selectedCompanyId;
  //     zoomData.comapnyName = selectedDomain;

  //     const selectValue = value;
  //     setZoomData({ ...zoomData, client: selectValue });

  //     setFilteredCompany(value || "");
  //     // if (!value) {
  //     //   GetZoomCompanyList();
  //     // }

  //     try {
  //       const res = await fetch(
  //         `${API_PATH.CLIENT_VIEW}/${selectedCompanyId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${userData?.token}`,
  //           },
  //         }
  //       );

  //       const result = await res.json();
  //       if (result) {
  //         setClientView(result?.data);
  //       } else {
  //         // Handle error
  //       }
  //     } catch (error) {
  //       // Handle error
  //     }
  //   },
  //   [userData?.token, zoomData]
  // );
  // const AddClientData = useCallback(async () => {
  //   try {
  //     const res = await fetch(`${API_PATH.CLIENT_CREATE}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //       body: JSON.stringify(clienData),
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       if (result.status === 200 || result.status === true) {
  //         // GetZoomCompanyList();
  //         // handleNameChange();
  //         notification.success({
  //           message: "Client created",
  //           icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
  //           style: {
  //             backgroundColor: "#2ecc71",
  //             color: "#fff !important",
  //             border: "1px solid #52c41a",
  //           },
  //           duration: 5,
  //           placement: "topRight",
  //         });
  //       } else if (
  //         result.status === false &&
  //         result.message === "Email is already exists."
  //       ) {
  //         notification.error({
  //           message: "Email is already exists.",
  //           icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //           style: {
  //             backgroundColor: "#e74c3c",
  //             color: "#fff",
  //             border: "1px solid #c0392b",
  //           },
  //           duration: 5,
  //           placement: "topRight",
  //         });
  //       } else {
  //         notification.error({
  //           message: "Error: Something went wrong server error",
  //           icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //           style: {
  //             backgroundColor: "#e74c3c",
  //             color: "#fff",
  //             border: "1px solid #c0392b",
  //           },
  //           duration: 5,
  //           placement: "topRight",
  //         });
  //       }
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //   }
  // }, [clienData, userData?.token]);

  const DeleteZoomMeeting = useCallback(async () => {
    // e.preventDefault();
    setLoadingDelete(true);

    try {
      const res = await fetch(
        `${API_PATH.DELETE_ZOOM_DRAFT_MEETING}?id=${zoomId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        GetZoomList({
          page: currentPage,
          key: selectedOptions.key,
          sortBy: selectedOptions.sortBy,
          sortType: selectedOptions.sortType,
        });
        setCurrentPage(1);
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Successful deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoadingDelete(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoadingDelete(false);
  }, [GetZoomList, currentPage, selectedOptions, userData?.token, zoomId]);

  // const handleInputsClient = useCallback(
  //   (e) => {
  //     const name = e.target.name;
  //     const value = e.target.value;

  //     setClienData({ ...clienData, [name]: value });
  //   },
  //   [clienData]
  // );

  const getZoomId = useCallback((_id) => {
    setZoomId(_id);
  }, []);

  const dataSource = zoomList?.map((data, index) => ({
    project_title: (
      <div className="d-flex flex-column justify-content-center align-items center">
        <span>{<Tooltip title={data?.topic} color="#5746ec">
          {_.truncate(data?.topic, {
            length: 30,
            omission: "...",
          })}
        </Tooltip>}</span>

        <div className="d-flex  align-items-center justify-content-start gap-2 pt-2 text-capitalize">
          {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting", "introductory_call"]?.includes(data?.engagement_type) ? <Tag color="blue" style={{ fontSize: "10px" }}>{
            data?.engagement_type === "file_or_sheet_filling" ? "File Or Sheet Filling" :
              data?.engagement_type === "focus_group_discuession" ? "Focus Group Discussion" :
                data?.engagement_type === "b2b_survey" ? "B2B Survey" :
                  data?.engagement_type === "in_person_meeting" ? "In-Person Meeting" :
                    data?.engagement_type === "introductory_call" ? "Intro Call"
                      : ""}</Tag> : (data?.meeting_link || data?.zoom_account?.[0]?.email) && <Tag color="green" style={{ fontSize: "10px" }}>
                        {data?.meeting_link ? "External Meeting Link" : data?.zoom_account?.[0]?.email}
                      </Tag>}
          {!data?.is_meeting_link_provided && data?.zoommeetings?.[0]?.meetingId && !["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) && (
            <CopyToClipboard
              text={data?.zoommeetings?.[0]?.meetingId}
              onCopy={() => {
                setCopiedId(data?.zoommeetings?.[0]?.meetingId);
              }}
            >
              <div className="d-flex ">
                <Tag color="green" style={{ fontSize: "10px" }}>
                  {data?.zoommeetings?.[0]?.meetingId}
                  <Tooltip
                    title={
                      copiedId === data?.zoommeetings?.[0]?.meetingId
                        ? "Copied!"
                        : "Copy ID"
                    }
                  >
                    <MdContentCopy
                      style={{ cursor: "pointer", marginInline: 4 }}
                    />
                  </Tooltip>
                </Tag>
              </div>

            </CopyToClipboard>
          )}
        </div>
      </div>
    ),
    client: data?.clientname?.[0]?.companyName || "-",
    approval: <div className="d-flex align-items-center justify-content-center flex-column">
      <div className="d-flex align-items-center justify-content-center gap-2">
        {
          ["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? <div className="d-flex flex-column justify-content-center align-items-center">
            {" "}
            <div>
              <Tooltip color="#5746EB" title={`Not Applicable`}>
                <IoMdCheckmarkCircleOutline style={{
                  color: "#0958d9"
                }} />
              </Tooltip>
            </div>
          </div>
            : data?.approve_by_user1 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                {" "}
                <div>
                  {/* <Tag title="Approved" color="green">
              {"Approved"}
            </Tag> */}
                  <Tooltip color="#5746EB" title={`Team Lead - ${data?.approve_by_user1_name?.[0]?.name}`}>
                    <IoMdCheckmarkCircleOutline style={{
                      color: "green"
                    }} />
                  </Tooltip>
                </div>
                {/* <small className="text-muted mt-1" style={{ fontSize: "10px" }}>
            {data?.approve_by_user1_name?.[0]?.name}
          </small> */}
              </div>
            ) : (
              <div className="d-flex flex-column flex-column justify-content-center align-items-center">
                <div>
                  <Tooltip color="#5746EB" title={"Pending"}>
                    <IoMdCheckmarkCircleOutline style={{
                      color: "red"
                    }} />
                  </Tooltip>
                </div>
              </div>
            )}
        {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? <div className="d-flex flex-column justify-content-center align-items-center">
          {" "}
          <div>
            <Tooltip color="#5746EB" title={`Not Applicable`}>
              <IoMdCheckmarkCircleOutline style={{
                color: "#0958d9"
              }} />
            </Tooltip>
          </div>
        </div>
          : data?.approve_by_user2 ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              {" "}
              <div>
                <Tooltip color="#5746EB" title={`Operations - ${data?.approve_by_user2_name?.[0]?.name}`}>
                  <IoMdCheckmarkCircleOutline style={{
                    color: "green"
                  }} />
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <Tooltip color="#5746EB" title={"Pending"}>
                  <IoMdCheckmarkCircleOutline style={{
                    color: "red"
                  }} />
                </Tooltip>
              </div>
            </div>
          )}
      </div>
      <div >
        <Tag color="green" style={{ fontSize: "10px", margin: "0px" }}>
          {data?.admin?.name}
        </Tag>
      </div></div>,

    call_date:
      <>
        {data.call_date ? <div>
          {["Invalid date", "null", "", "undefined"]?.includes(data?.call_date) ? moment().format("DD MMM YYYY") :
            moment(data?.call_date, backendDateFormat)?.format("DD MMM YYYY")}
        </div> : "-"}
        {data.call_time ? <div className="text-muted" style={{ fontSize: "12px" }}>
          {moment(data?.call_time, ["HH:mm:ss", "HH:mm"])?.format("HH:mm")} -{" "}
          {moment(data?.call_time, ["HH:mm:ss", "HH:mm"])
            .add(data?.duration, "minutes")
            ?.format("HH:mm")}
        </div> : "-"}
      </>
    ,
    duration: data?.duration,
    status: <>
      {data?.is_draft ?
        <Tag color="red">Draft</Tag>
        : <>
          <Tooltip
            color="#5746EB"
            title={`${["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(data?.engagement_type) ? "Not Applicable" : data?.cal_data?.length > 0 && data?.expert_cal_data?.length > 0
              ? "Invite Sent"
              : "No Invite Sent"
              }`}
            overlayClassName="zoomPopOver"

          >
            <Tag color={["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(data?.engagement_type) ? "blue" : data?.cal_data?.length > 0 && data?.expert_cal_data?.length > 0 ? "green" : "red"}>
              {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(data?.engagement_type) ? "N" : data?.cal_data?.length > 0 && data?.expert_cal_data?.length > 0 ? "S" : "W"}
            </Tag>
          </Tooltip>

          <Tooltip
            color="#5746EB"
            title={`${["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "Not Applicable" : ![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided ? "Not Applicable, Recording Turned Off" : data?.is_meeting_link_provided ? "Not Applicable" :
              data?.zoommeetings?.[0]?.zoomrecordings?.length === 0 || !data?.zoommeetings?.[0]?.zoomrecordings
                ? "Waiting for Transcript and Recording"
                : !data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.recording_count <= 0 && data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.transcript_status !== "processed"
                  ? "Recording generated Transcript Processing"
                  : "Transcript and Recording Generated"
              }`}
            overlayClassName="zoomPopOver"
          >
            <Tag
              color={
                ["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "blue" : ![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided ? "blue" : data?.is_meeting_link_provided ? "blue" : data?.zoommeetings?.[0]?.zoomrecordings?.length === 0 || !data?.zoommeetings?.[0]?.zoomrecordings
                  ? "red"
                  : !data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.recording_count <= 0 && data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.transcript_status !== "processed"
                    ? "yellow"
                    : "green"
              }
            >
              {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "N" : ((![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided) || (data?.is_meeting_link_provided)) ? "N" : data?.zoommeetings?.[0]?.zoomrecordings?.length === 0 || !data?.zoommeetings?.[0]?.zoomrecordings
                ? "W"
                : !data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.recording_count <= 0 && data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.transcript_status !== "processed"
                  ? "P"
                  : "S"}
            </Tag>
          </Tooltip>
          <Tooltip
            color="#5746EB"
            title={`${["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "Not Applicable" : ![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided ? "Not Applicable, Recording Turned Off" : data?.is_meeting_link_provided ? "Not Applicable" : data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.mail
              ? "Email Sent To Client"
              : "Client Email Pending"
              }`}
            overlayClassName="zoomPopOver"

          >
            <Tag color={["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "blue" : ![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided ? "blue" : data?.is_meeting_link_provided ? "blue" : data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.mail ? "green" : "red"}>
              {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type) ? "N" : ((![undefined, "undefined"]?.includes(data?.auto_recording) && data?.auto_recording === false && !data?.is_meeting_link_provided) || (data?.is_meeting_link_provided)) ? "N" : data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.mail ? "S" : "W"}
            </Tag>
          </Tooltip>

          <Tooltip
            color="#5746EB"
            title={`${["introductory_call"]?.includes(data?.engagement_type) ? "Not Applicable" : data?.expert_credits?.length > 0
              ? "Post Call Email Sent"
              : "Post Call Email Pending"
              }`}
            overlayClassName="zoomPopOver"

          >
            <Tag color={["introductory_call"]?.includes(data?.engagement_type) ? "blue" : data?.expert_credits?.length ? "green" : "red"}>
              {["introductory_call"]?.includes(data?.engagement_type) ? "N" : data?.expert_credits?.length ? "S" : "W"}
            </Tag>
          </Tooltip></>}

    </>,
    _id: { ...data, index: index },
  }));

  const navigate = useNavigate();
  const columns = [
    {
      title: "S.No",
      render: (text, record, rowIndex) => {
        return currentPage === 1 ? rowIndex + 1 : (((currentPage - 1) * 10) + rowIndex + 1)
      },
      // width: "2%",
      align: "center",
    },
    {
      title: "Call Title",
      dataIndex: "project_title",
      key: "project_title",
      // width: "20%",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      // width: "10%",
    },
    {
      title: () => (
        <span>
          Approval
        </span>
      ),
      dataIndex: "approval",
      key: "approval",
      align: "center",
      // width: "10%",
    },

    {
      title: <>Date/Time<br />(IST)</>,
      dataIndex: "call_date",
      key: "call_date",
      align: "center",
      // width: "10%",
      sorter: true
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      // width: "20%",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      align: "left",
      render: (data) => {
        let cakkDateFormated = ["Invalid date", "null", "", "undefined"]?.includes(data?.call_date) ? moment().format("YYYY-MM-DD") : data?.call_date
        let datejoin = `${cakkDateFormated + " " + data?.call_time}`;
        return (
          <div className="d-flex expertActions align-items-center justify-content-start gap-1">
            <Tooltip
              placement="top"
              title={
                !permission?.[16]?.draftedZoomMeeting?.edit
                  ? "Permission Denied"
                  : "Edit"
              }
              color="#5746EB"
            >
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  disabled={!permission?.[16]?.draftedZoomMeeting?.edit}
                  // disabled={data?.approve_by_user1 === userData?.data?._id || data?.approve_by_user2 === userData?.data?._id || data?.approve_count === 2}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // dispatch(resetZoomMeeting());
                    // form.resetFields();
                    navigate(
                      `/dashboard/draft-zoom-meetings/${data?._id}?action=edit`
                    );
                  }}
                >
                  <MdEdit />
                </button>
              </span>
            </Tooltip>
            <Tooltip placement="top" title={"View"} color="#5746EB">
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  onClick={() => {
                    setOpen(true);
                    navigate(
                      `/dashboard/draft-zoom-meetings?id=${data?._id}&action=view`
                    );
                  }}
                >
                  <BsEyeFill />
                </button>
              </span>
            </Tooltip>
            <Tooltip
              placement="top"
              title={
                !permission?.[16]?.draftedZoomMeeting?.delete
                  ? "Permission Denied"
                  : "Delete"
              }
              color="#5746EB"
            >
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  style={{
                    cursor: !userData?.data?.roleData?.[0]
                      ?.role_permission?.[16]?.draftedZoomMeeting?.delete
                      ? "not-allowed"
                      : "pointer",
                  }}
                  disabled={!permission?.[16]?.draftedZoomMeeting?.delete}
                  onClick={() => {
                    setDeleteModal(true);
                    getZoomId(data?._id);
                  }}
                >
                  <MdDelete />
                </button>
              </span>
            </Tooltip>
            {(
              (moment()?.isAfter(moment(datejoin, "YYYY-MM-DD HH:mm")) &&
                ["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(data?.engagement_type)) ||
              !data?.engagement_type === "introductory_call" || (moment()?.isAfter(moment(datejoin, "YYYY-MM-DD HH:mm")) &&
                data?.approve_by_user1 &&
                data?.approve_by_user2 && data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.transcript_status === "processed" && data?.zoommeetings?.[0]?.zoomrecordings?.[0]?.recording_count > 0 && ["micro_consulting_call"]?.includes(data?.engagement_type)) ||
              (moment()?.isAfter(moment(datejoin, "YYYY-MM-DD HH:mm")) &&
                data?.approve_by_user1 &&
                data?.approve_by_user2 && ![undefined, "undefined"]?.includes(data?.auto_recording) &&
                data?.auto_recording === false && ["micro_consulting_call"]?.includes(data?.engagement_type))) && (
                <Tooltip
                  placement="top"
                  title={
                    !permission?.[16]?.draftedZoomMeeting?.edit
                      ? "Permission Denied"
                      : data?.expert_credits?.length > 0 ? "Update Post Call Details" : "Send Post Call Email"
                  }
                  color="#5746EB"
                >
                  <span>
                    <button
                      size="large"
                      className="viewBttnExpert"
                      disabled={!permission?.[16]?.draftedZoomMeeting?.edit}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenCreaditModal({
                          data: data,
                          index: data?.index,
                          open: true,
                        });
                      }}
                    >
                      {data?.expert_credits?.length > 0 ? <LuRefreshCw /> : <SiMinutemailer />}
                    </button>
                  </span>
                </Tooltip>
              )}

          </div>
        );
      },
      // width: "20%",
    },
  ];

  const handleTableChange = useCallback(
    async (pagination, filters, sorter) => {
      setCurrentPage(1);
      setSelectedOptions({
        ...selectedOptions,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
      await GetZoomList({
        page: 1,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
    },
    [GetZoomList, selectedOptions]
  );

  const handleInputChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setSelectedOptions({
        ...selectedOptions,
        key: inputValue,
      });
      setCurrentPage(1);
      GetZoomList({ page: 1, key: inputValue });
    },
    [GetZoomList, selectedOptions]
  );

  // const handlePhoneChange = useCallback((value, country) => {
  //   setPhone(value);
  //   const selectedCountry = countryOptions?.find(
  //     (option) => option?.code === `+${country?.dialCode}`
  //   );
  //   if (selectedCountry) {
  //     setClienData((prevStep3) => ({
  //       ...prevStep3,
  //       contact_code: country?.dialCode,
  //       phoneNumber: value?.replace(country?.dialCode, ""),
  //       country: selectedCountry?.name,
  //     }));
  //   } else {
  //     // console.log("No matching country found for dial code:", country.dialCode);
  //   }
  // }, []);

  // const handleSelectChange = useCallback(
  //   (value) => {
  //     const selectValue = value;
  //     setClienData({ ...clienData, role_id: selectValue });
  //   },
  //   [clienData]
  // );

  // const handleSelectChangeMoveTo = useCallback(
  //   (value) => {
  //     const selectValue = value;
  //     setClienData({ ...clienData, registrationType: selectValue });
  //   },
  //   [clienData]
  // );

  // const setFilteredData = useCallback(
  //   (value) => {
  //     if (!value?.trim()) {
  //       setFilteredCompany("");
  //       // GetZoomCompanyList();
  //     } else {
  //       setFilteredCompany(value);
  //     }
  //   },
  //   []
  // );
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Calendar Invites </h2>
              <div>
                {permission?.[16]?.draftedZoomMeeting?.add && (
                  <Link
                    to={"/dashboard/draft-zoom-meetings/add-meeting"}
                    className="btn viewButton"
                    // onClick={() => {
                    //   // dispatch(resetZoomMeeting());
                    //   // form.resetFields();
                    //   // setZoomData({
                    //   //   zoomId: "",
                    //   //   meetingId: "",
                    //   //   startDate: "",
                    //   //   passcode: "",
                    //   //   startTime: "",
                    //   //   endDate: "",
                    //   //   endTime: "",
                    //   //   comapnyName: "",
                    //   //   client: "",
                    //   //   client_user: [],
                    //   //   client_user_id: [],
                    //   //   client_id: viewId,
                    //   //   zoommeeting_id: "",
                    //   // });
                    //   // setDisplayExpert(!displayExpert);
                    // }}
                    size="large"
                  >
                    <span className="d-none d-md-block">Add New Meeting</span>
                    <span className="d-block d-md-none">
                      <FaPlus />
                    </span>
                  </Link>
                )}
                {
                  permission?.[5]?.call_records?.download &&
                  <Link

                    className="btn viewButton ms-2"
                    onClick={() => {
                      setIsOpenCalModal(true)
                    }}
                    size="large"
                  >
                    <span className="d-none d-md-block">Download</span>
                    <span className="d-block d-md-none">
                      <FiDownload />
                    </span>
                  </Link>
                }
              </div>
            </div>
            {/* 
            {displayExpert ? (
              // <AddMeetingForm />
              <>
                <div className="row d-flex justify-content-center align-items-center py-4">
                  <div className="col-md-10">
                    <div className="card bg-white p-4">
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                          AddZoomMeeting();
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item label="Select Zoom Account">
                              <Select
                                size="large"
                                placeholder="Select Zoom Account"
                                onChange={handleChange}
                                value={zoomData?.zoomId}
                                options={zoomAccountList?.map((item) => ({
                                  label: item?.email,
                                  value: item?._id,
                                }))}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Meeting Id">
                              <Input
                                size="large"
                                placeholder="89223750266"
                                name="meetingId"
                                value={zoomData?.meetingId}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Designation"
                              name="expertDesignation"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert designation is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Designation"
                                name="expertDesignation"
                                value={zoomData.expertDesignation}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Company"
                              name="expertCompany"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert company is required.",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Company"
                                name="expertCompany"
                                value={zoomData.expertCompany}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client" label="Client">
                              <Select
                                showSearch
                                size="large"
                                placeholder="Select Client"
                                onChange={handleNameChange}
                                name="client"
                                value={zoomData?.client}
                                onSearch={setFilteredData}
                                // optionFilterProp="children"
                                options={zoomCompanyList
                                  ?.filter((item) => item?.name)
                                  .map((item) => ({
                                    label: item?.name,
                                    value: item?.name,
                                    key: item?._id,
                                    domain: item?.domain,
                                  }))}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client_user" label="Client User">
                              <Select
                                size="large"
                                placeholder="Select Client User"
                                name="client_user"
                                mode="multiple"
                                onChange={(values, options) =>
                                  handleNameChangeClient(values, options)
                                }
                                value={zoomData?.client_user}
                                options={clientView?.map((item) => ({
                                  label: item?.name,
                                  value: item?.name,
                                  key: item?._id,
                                }))}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: "8px 0",
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: "0 8px 4px",
                                      }}
                                    >
                                      <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => setCreateModal(true)}
                                      >
                                        Client user
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>

                          {/* <div className="col-md-6">
                            <Form.Item label="Meeting Passcode">
                              <Input
                                size="large"
                                placeholder="819302"
                                name="passcode"
                                value={zoomData?.passcode}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div> */}

            {/* <div className="col-md-6">
                            <Form.Item name="dateAndTime" label="Date Time">
                              <RangePicker
                                size="large"
                                showTime={{
                                  format: "HH:mm",
                                }}
                                name="dateAndTime"
                                format="YYYY-MM-DD HH:mm"
                                onChange={onChange}
                                onOk={onOk}
                              />
                            </Form.Item>
                          </div> */}

            {/* <div className="d-flex justify-content-center align-items-center">
              <Form.Item>
                <button
                  className="viewButton btn"
                  type="submit"
                // onClick={AddZoomMeeting}
                >
                  Submit
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div > */}
            {/* </div >
        </>
            ) : null} * /} */}

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />

            <Table
              // dataSource={filteredClientList}
              dataSource={dataSource}
              columns={columns}
              className="paddTable"
              loading={loading}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 600 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Add New Manager</h5>
          </div>
          <div className="offcanvas-body">
            <div className="experien-list mt-4">
              <div>
                <ul className="nextyn_list_items">
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">From:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">To:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Through:</span>
                      <div className="companyName_1dSwA">+17182858858</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Reference Id:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Total Talk Time:</span>
                      <div className="companyName_1dSwA">s</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Start Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">End Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Transcription:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      </div>

      <Modal
        title={
          <div>
            Delete Zoom Call
            <Divider className="m-2" />
          </div>
        }
        open={showDeleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={async () => {
          await DeleteZoomMeeting();
          setDeleteModal(false);
        }}
        cancelText={"No"}
        width={800}
        okText={<>
          {loadingDelete && <Spinner
            size="sm"
            style={{
              width: "1rem",
              height: "1rem",
              marginRight: "5px",
            }}
          />} Yes
        </>}
        centered
      >
        <div>
          Are you sure you want to delete this call? The event will be cancelled once the call is deleted
        </div>
      </Modal>
      {/* 
      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Create Client</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Name">
                    <Input
                      size="large"
                      name="name"
                      value={clienData?.name}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Email">
                    <Input
                      size="large"
                      name="email"
                      value={clienData?.email}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Company Name">
                    <Input
                      size="large"
                      name="companyName"
                      value={zoomData?.companyName}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Contact Number">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(value, country) => {
                        handlePhoneChange(value, country);
                      }}
                      inputStyle={{
                        width: "100%",
                      }}
                      value={phone}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Role">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.role_id}
                      onChange={handleSelectChange}
                    >
                      {roleList?.map((option, index) => (
                        <Select.Option value={option?._id} key={index}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Move to">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.registrationType}
                      onChange={handleSelectChangeMoveTo}
                    >
                      <Select.Option value={"cloud"}>Cloud</Select.Option>
                      <Select.Option value={"client"}>Client</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                AddClientData();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <UpdateZoomMeetingModal
        editZoomModal={editZoomModal}
        setEditZoomModal={setEditZoomModal}
        zoomData={zoomData}
        setZoomData={setZoomData}
        zoomAccountList={zoomAccountList}
        zoomCompanyList={zoomCompanyList}
        clientView={clientView}
        setCreateModal={setCreateModal}
        setFilteredData={setFilteredData}
        setViewId={setViewId}
        setFilteredCompany={setFilteredCompany}
        GetZoomCompanyList={GetZoomCompanyList}
        setClientView={setClientView}
        viewId={viewId}
        GetZoomList={GetZoomList}
      /> */}

      <ViewZoomMeetingDrawer
        open={open}
        setOpen={setOpen}
        GetZoomList={GetZoomList}
      />

      {/* <Modal
        show={editZoomModal}
        onHide={() => setEditZoomModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setEditZoomModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit Zoom Meetingssss</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Select Zoom Account">
                    <Select
                      size="large"
                      placeholder="Select Zoom Account"
                      onChange={handleChange}
                      value={zoomData?.zoomId}
                      options={zoomAccountList?.map((item) => ({
                        label: item?.email,
                        value: item?._id,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item label="Meeting Id">
                    <Input
                      size="large"
                      placeholder="Meeting Id"
                      name="meetingId"
                      value={zoomData?.meetingId}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client" label="Client">
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select Clientssss"
                      onChange={handleNameChange}
                      name="client"
                      value={zoomData?.client}
                      options={zoomCompanyList
                        ?.filter((item) => item?.name)
                        .map((item) => ({
                          label: item?.name,
                          value: item?.name,
                          key: item?._id,
                          domain: item?.domain,
                        }))}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client_user" label="Client User">
                    <Select
                      size="large"
                      placeholder="Select Client User"
                      name="client_user"
                      mode="multiple"
                      onChange={(values, options) => handleNameChangeClient(values, options)}
                      value={zoomData?.client_user}
                      options={clientView?.map((item) => ({
                        label: item?.name,
                        value: item?.name,
                        key: item?._id,
                      }))}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => setCreateModal(true)}
                            >
                              Create client
                            </Button>
                          </Space>
                        </>
                      )}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Meeting Passcode">
                    <Input
                      size="large"
                      placeholder="819302"
                      name="passcode"
                      // value={zoomData?.passcode}
                      // onChange={handleInputs}
                      value={zoomData?.passcode}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="dateAndTime" label="Date Time">
                    <RangePicker
                      size="large"
                      showTime={{
                        format: "HH:mm",
                      }}
                      name="dateAndTime"
                      format="YYYY-MM-DD HH:mm"
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setEditZoomModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                // AddClientData()
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
      <CreditModal
        open={openCreditModal.open}
        index={openCreditModal.index}
        setOpen={setOpenCreaditModal}
        data={openCreditModal.data}
        getZoomList={GetZoomList}
      />
      <SelectDateRangeModal isOpenCalModal={isOpenCalModal} setIsOpenCalModal={setIsOpenCalModal} />

    </>
  );
};

export default ZoomDraftTable;
