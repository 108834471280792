import { Form, Input, Select, Button, Spin } from "antd";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useStep from "../../../CustomHooks/UseStep";
import LinkedinLogin from "../../components/LinkedinRegistration";
import ExpertAuth from "../../components/ExpertAuth";
import PhoneInput from "react-phone-input-2";
import { countryOptions } from "../../../Constants/CountryOptions";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import LinkedInRegistrationConsultant from "../../components/LinkedinRegistrationConsultant";
import { Country, City, State } from "country-state-city";
const { Option } = Select;

function RegistarConsultantStep1() {
    const history = useNavigate();
    const { setCurrentStepConsultant, registrationType } = useStep();
    const [form] = Form.useForm();
    const [code, setCode] = useState(null);
    const countries = Country?.getAllCountries();
    const [phone, setPhone] = useState("");
    const [lowerValidated, setLowerValidated] = useState(false);
    const [upperValidated, setUpperValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [lengthValidated, setLengthValidated] = useState(false);
    const [trackerBox, setTrackerBox] = useState(false);

    const updatedCountries = useMemo(() => countries?.map((country, index) => {
        return <Option key={country.isoCode} value={country.name} >{country.name}</Option>
    }), [countries]);

    const updatedCities = (countryId) =>
        City
            .getCitiesOfCountry(countryId)
            .map((city, index) => {
                return <Option value={city.name} key={index}>{city.name} {city.stateCode}</Option>
            })

    const linkedinData = JSON.parse(localStorage?.getItem("linkedinData"));

    const [step1, setStep1] = useState({
        first_name: "",
        last_name: "",
        full_name: "",
        email: linkedinData?.email || "",
        contact_number: "",
        contact_code: "",
        country: {},
        password: "",
        city: ""

    });
    const [loading, setIsLoading] = useState(false);

    const handleStep1Change = (event) => {
        const { name, value } = event.target;
        if (name === "full_name") {
            let first_name = value?.split(" ")?.[0]
            let last_name = ""
            if (value?.split(" ")?.length > 1) {
                if (value?.split(" ")?.length > 2) {
                    last_name = value?.split(" ")?.[1] + " " + value?.split(" ")?.[2]
                } else {
                    last_name = value?.split(" ")?.[1]
                }
            }
            setStep1((prevStep1) => ({
                ...prevStep1,
                full_name: value,
                first_name: first_name?.trim(),
                last_name: last_name
            }));
        } else {
            setStep1((prevStep1) => ({
                ...prevStep1,
                [name]: value,
            }));
        }



    };

    const handleSubmit = () => {
        saveToLocalStorage();
        history(`/expert-register?regType=${registrationType}&step=2`);
        setCurrentStepConsultant(2);
    };


    const handleSubmitTwo = () => {
        saveToLocalStorage();
        history(`/expert-register?regType=2&step=2&type=linkedin`);
        setCurrentStepConsultant(2);
    };

    const saveToLocalStorage = () => {
        const step1Obj = {
            first_name: step1?.first_name,
            last_name: step1?.last_name,
            full_name: form?.getFieldValue("full_name"),
            email: form?.getFieldValue("email"),
            password: form?.getFieldValue("password"),
            country: step1.country,
            city: step1.city,
            contact_number: step1?.contact_number,
            contact_code: step1?.contact_code
        };

        const expirationTime = new Date()?.getTime() + 60 * 60 * 1000; // 1 hour from now
        const dataToStore = {
            data: step1Obj,
            expiration: expirationTime,
        };

        localStorage?.setItem("step1", JSON?.stringify(dataToStore));
    };

    const isLocalStorageExpired = () => {
        const storedStep1 = localStorage?.getItem("step1");
        if (storedStep1) {
            const parsedStep1 = JSON.parse(storedStep1);
            const now = new Date()?.getTime();
            return parsedStep1?.expiration && now > parsedStep1?.expiration;
        }
        return true;
    };

    const handlePhoneChange = (value, country) => {
        console.log('value: ', value);
        setPhone(value);
        const selectedCountry = countryOptions?.find(
            (option) => option?.code === `+${country?.dialCode}`
        );
        if (selectedCountry) {
            setStep1((prevStep3) => ({
                ...prevStep3,
                contact_code: country?.dialCode,
                contact_number: value?.replace(country?.dialCode, ""),
            }));
        } else {
            console.info(
                "No matching country found for dial code:",
                country.dialCode
            );
        }
    };

    const handleTrackerBox = () => {
        setTrackerBox(true);
    };
    const validateEnterPassword = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("Please enter a password!"));
        } else if (step1?.password &&
            (!lowerValidated ||
                !upperValidated ||
                !numberValidated ||
                !lengthValidated)) {
            return Promise.reject(new Error("Password is not strong enough!"));
        }
        return Promise.resolve();
    };
    const validateName = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("Full Name is required!"));
        } else if (value) {
            let splitName = value?.split(" ")
            if (splitName?.length > 1) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error("Please enter your full name!"));
            }
        }
    };

    const validatePassword = (_, value) => {
        const { password } = form.getFieldValue();
        let reEnter = form.getFieldValue("verify-password")
        console.log('password: ', reEnter, password);
        if (reEnter && reEnter !== password) {
            return false
        }
        return true
    };

    const handleChange = useCallback((e) => {
        const value = e.target.value;

        setStep1({ ...step1, password: value });
        const minMaxLength = /^[\s\S]{8,16}$/;
        const upper = new RegExp("[A-Z]");
        const lower = new RegExp("[a-z]");
        // special = new RegExp("(?=.*[!@#$%^&*])"),
        const number = new RegExp("[0-9]");
        if (lower.test(value)) {
            setLowerValidated(true);
        } else {
            setLowerValidated(false);
        }
        if (upper.test(value)) {
            setUpperValidated(true);
        } else {
            setUpperValidated(false);
        }
        if (number.test(value)) {
            setNumberValidated(true);
        } else {
            setNumberValidated(false);
        }

        if (minMaxLength.test(value)) {
            setLengthValidated(true);
        } else {
            setLengthValidated(false);
        }
    }, [step1]);
    useEffect(() => {
        if (!isLocalStorageExpired()) {
            const storedStep1 = localStorage?.getItem("step1");
            if (storedStep1) {
                const parsedStep1 = JSON.parse(storedStep1);
                console.log('parsedStep1: ', parsedStep1);
                setStep1({ ...parsedStep1?.data });
                form.setFieldsValue({
                    first_name: parsedStep1.data?.first_name,
                    last_name: parsedStep1.data?.last_name,
                    full_name: parsedStep1.data?.full_name,
                    email: parsedStep1.data?.email,
                    country: parsedStep1?.data?.country,
                    city: parsedStep1?.data?.city,
                    password: parsedStep1?.data?.password,
                    contact_number: parsedStep1?.data?.contact_number,
                    contact_code: parsedStep1?.data?.contact_code,
                    "verify-password": parsedStep1?.data?.password,
                });
                setLowerValidated(true);
                setUpperValidated(true);
                setNumberValidated(true);
                setLengthValidated(true);
                let number = `${parsedStep1?.data?.contact_code}${parsedStep1?.data?.contact_number}`
                console.log('number: ', number);
                form.setFieldValue("contact_number", number)
                setPhone(number)
            }
        } else {
            localStorage?.removeItem("step1"); // Remove expired item from local storage
        }
    }, [form]);

    console.log("step1 AFTER", step1);
    useEffect(() => {
        // Function to extract query parameters from the URL
        const getQueryParameter = (name) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        };
        // Get the 'code' query parameter from the URL
        const typeFromURL = getQueryParameter("type");
        if (typeFromURL === "linkedin") {
            // If 'code' exists in the URL, set it in the state
            setCode(typeFromURL);
            form.setFieldValue("email", linkedinData?.email)
            form.setFieldValue("full_name", linkedinData?.firstName + " " + linkedinData?.lastName)
            setStep1({ ...step1, first_name: linkedinData?.firstName, last_name: linkedinData?.lastName })
            // You can perform any additional actions with the 'code' here if needed
        }
    }, []);
    useEffect(() => {
        // Function to extract query parameters from the URL
        const getQueryParameter = (name) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        };

        // Get the 'code' query parameter from the URL
        const codeFromURL = getQueryParameter("code");
        if (codeFromURL) {
            // If 'code' exists in the URL, set it in the state
            setCode(codeFromURL);
            // You can perform any additional actions with the 'code' here if needed
        } else {
            console.log("Code not found in Linkedin Url");
        }
    }, []);

    return (
        <React.Fragment>
            <ExpertAuth />
            <div
                className={
                    loading
                        ? "text-center loginScreenLinkedin active"
                        : "text-center loginScreenLinkedin"
                }
            >
                <Spin size="large" />
            </div>
            <div className="expertRegistration step1">
                <h5>Step 1 : Registration Process</h5>
                <p>
                    Your contact information will help our team get in touch with you for
                    potential consultation opportunities.
                </p>

                <div>
                    <LinkedInRegistrationConsultant
                        txt="Sign Up With Linkedin"
                        setIsLoading={setIsLoading}
                    />
                </div>

                <div class="centerLine">Or</div>

                <div>
                    <Form layout="vertical" autoComplete="off" form={form}
                        onFinish={() => {
                            let isValidated = validatePassword()
                            console.log('isValidated: ', isValidated);
                            if (isValidated) {
                                if (code) {
                                    handleSubmitTwo()
                                } else {
                                    handleSubmit()
                                }
                            } else {
                                form.setFields([{
                                    name: "verify-password",
                                    errors: ["Please re-enter the correct password."]
                                }])
                            }

                        }}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Form.Item
                                    name="full_name"
                                    label="Full Name"
                                    rules={[
                                        {
                                            required: true,
                                            validator: validateName,
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        name="full_name"
                                        autoComplete="nope"
                                        size="large"
                                        placeholder="Full Name"
                                        value={step1?.full_name}
                                        onChange={handleStep1Change}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Item
                                    name="email"
                                    label={<span className="">Email</span>}
                                    rules={[
                                        {
                                            type: "email",
                                            message: "Please enter a valid email address!",
                                        },
                                        {
                                            required: true,
                                            message: "Email is required!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="email"
                                        autoComplete="nope"
                                        placeholder="Email Address"
                                        size="large"
                                        value={step1?.email}
                                        onChange={handleStep1Change}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        {code ? null :
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="country"
                                        label={
                                            <span className="">Country</span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select country!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            name="country"
                                            size="large"
                                            labelInValue
                                            allowClear
                                            autoComplete="off"
                                            showSearch
                                            value={step1.country}
                                            onChange={(value) => {
                                                setStep1({
                                                    ...step1,
                                                    country: value
                                                })
                                            }}
                                        >
                                            {updatedCountries}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="city"
                                        label={
                                            <span className="">City</span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select city!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            name="city"
                                            size="large"
                                            labelInValue
                                            autoComplete="off"
                                            allowClear
                                            disabled={Object?.keys(step1?.country || {})?.length === 0}
                                            showSearch
                                            value={step1?.city}
                                            onChange={(value) => {
                                                setStep1({
                                                    ...step1,
                                                    city: value
                                                })
                                            }}

                                        >
                                            {updatedCities(step1?.country ? step1?.country?.key : null)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>}
                        {code ? null : (
                            <>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Item
                                            name="password"
                                            label="Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    // message: "Please Enter your password!",
                                                    validator: validateEnterPassword,
                                                },
                                            ]}
                                            hasFeedback
                                        // validateStatus={
                                        //     step1?.password &&
                                        //     (!lowerValidated ||
                                        //         !upperValidated ||
                                        //         !numberValidated ||
                                        //         !lengthValidated)
                                        //     && "error"
                                        // }
                                        // help={
                                        //     step1?.password &&
                                        //         (!lowerValidated ||
                                        //             !upperValidated ||
                                        //             !numberValidated ||
                                        //             !lengthValidated)
                                        //         ? "Password does not meet the requirements"
                                        //         : ""
                                        // }
                                        >
                                            <Input.Password
                                                name="password"
                                                size="large"
                                                value={setStep1?.password}
                                                onChange={handleChange}
                                                onClick={() => handleTrackerBox()}
                                            />
                                        </Form.Item>
                                        <main
                                            className={
                                                trackerBox ? "tracker-box active" : "tracker-box"
                                            }
                                        >
                                            <div
                                                className={
                                                    lowerValidated ? "validated" : "not-validated"
                                                }
                                            >
                                                {lowerValidated ? (
                                                    <span className="list-icon green">
                                                        <AiFillCheckCircle style={{ fontSize: "20px" }} />
                                                    </span>
                                                ) : (
                                                    <span className="list-icon">
                                                        <AiOutlineCheckCircle
                                                            style={{ fontSize: "20px" }}
                                                        />
                                                    </span>
                                                )}
                                                At least one lowercase letter
                                            </div>
                                            <div
                                                className={
                                                    upperValidated ? "validated" : "not-validated"
                                                }
                                            >
                                                {upperValidated ? (
                                                    <span className="list-icon green">
                                                        <AiFillCheckCircle style={{ fontSize: "20px" }} />
                                                    </span>
                                                ) : (
                                                    <span className="list-icon">
                                                        <AiOutlineCheckCircle
                                                            style={{ fontSize: "20px" }}
                                                        />
                                                    </span>
                                                )}
                                                At least one uppercase letter
                                            </div>
                                            <div
                                                className={
                                                    numberValidated ? "validated" : "not-validated"
                                                }
                                            >
                                                {numberValidated ? (
                                                    <span className="list-icon green">
                                                        <AiFillCheckCircle style={{ fontSize: "20px" }} />
                                                    </span>
                                                ) : (
                                                    <span className="list-icon">
                                                        <AiOutlineCheckCircle
                                                            style={{ fontSize: "20px" }}
                                                        />
                                                    </span>
                                                )}
                                                At least one number
                                            </div>
                                            <div
                                                className={
                                                    lengthValidated ? "validated" : "not-validated"
                                                }
                                            >
                                                {lengthValidated ? (
                                                    <span className="list-icon green">
                                                        <AiFillCheckCircle style={{ fontSize: "20px" }} />
                                                    </span>
                                                ) : (
                                                    <span className="list-icon">
                                                        <AiOutlineCheckCircle
                                                            style={{ fontSize: "20px" }}
                                                        />
                                                    </span>
                                                )}
                                                At least 8 characters
                                            </div>
                                        </main>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Item
                                            name="verify-password"
                                            label="Re-enter Password"
                                            dependencies={["password"]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please verify your password!",
                                                },
                                                // { validator: validatePassword },
                                            ]}
                                        >
                                            <Input.Password name="verify-password" size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col xs={12} >
                                <Form.Item
                                    name="contact_number"
                                    label={
                                        <span className="">Contact Number</span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Contact number is required",
                                        },
                                    ]}
                                >
                                    <PhoneInput
                                        className="react-tel-input-2"
                                        country={"us"}
                                        enableSearch={true}
                                        value={phone}
                                        onChange={(value, country) => {
                                            handlePhoneChange(value, country);
                                        }}
                                        inputStyle={{
                                            width: "100%",
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>

                            {code ? (
                                <Form.Item shouldUpdate className="text-end">
                                    {() => (
                                        <Button
                                            // onClick={handleSubmitTwo}
                                            className="btn btn-responsive expertButtonNext"
                                            size="large"
                                            htmlType="submit"
                                        // disabled={
                                        //     (!code && !form.isFieldsTouched(true)) ||
                                        //     (code &&
                                        //         !!form
                                        //             .getFieldsError()
                                        //             .filter(({ errors }) => errors.length).length
                                        //     ) || (step1?.contact_code === "" && step1?.contact_number === "")
                                        // }
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Form.Item>
                            ) : (
                                <Form.Item shouldUpdate className="text-end">
                                    {() => (
                                        <Button
                                            // onClick={handleSubmit}
                                            className="btn btn-responsive expertButtonNext"
                                            size="large"
                                            htmlType="submit"
                                        // disabled={
                                        //     (!code && !form.isFieldsTouched(true)) ||
                                        //     (code &&
                                        //         !!form
                                        //             .getFieldsError()
                                        //             .filter(({ errors }) => errors.length).length
                                        //     )
                                        // }
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Form.Item>
                            )}
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RegistarConsultantStep1;
