import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Row } from "react-bootstrap";
import "./Pages.css";
import { Table, Pagination, notification } from "antd";
import API_PATH from "../../../Constants/api-path";
import ExpertDashboard from "../../components/ExpertDashboard";
import { useNavigate } from "react-router-dom";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const CallRecords = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Project Name",
      // dataIndex: "project_title",
      // key: "project_title",
      render: (record) => (
        <>
          <div>
            <h5
              className="mt-0 mb-1 link-text"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {record?.project_title}
            </h5>
            <div className="d-flex">
              <div className="text-muted">
                <svg
                  style={{ fontSize: "16px" }}
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                </svg>

                <span className="ps-2">{convertDate(record?.created_at)}</span>
              </div>

              <div className="text-muted ps-5">
                <svg
                  style={{ fontSize: "18px" }}
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  t="1569683618210"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <path d="M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-0.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c0.1-6.2-5-11.2-11.1-11.2z"></path>
                  <path d="M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7-12.7 20.1-27.5 38.7-44.5 55.7-29.3 29.3-63.4 52.3-101.3 68.3-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3 17 17 31.8 35.6 44.5 55.7 3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8 3.1-6.1-1.4-13.3-8.2-13.3z"></path>
                </svg>

                <span className="ps-2">{record?.call_duration}</span>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "expert_rate",
      key: "expert_rate",
      render: (record) => (
        <>
          <div>{userData?.data?.hourly_currency + " " + record}</div>
        </>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "expert_payment_status",
      key: "expert_payment_status",
      render: (record) => (
        <div>{record === "No" ? "In Progress" : "Processed"}</div>
      ),
    },
    {
      title: "Payment Date",
      key: "payment_date",
      dataIndex: "payment_date",
      render: (record) => <div>{record ? convertDate(record) : "-"}</div>,
    },
  ];

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const userData = JSON.parse(localStorage.getItem("expertData"));
  const [loading, setLoading] = useState(false);
  const [callList, setCallList] = useState([]);
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const GetCallList = React.useCallback(async (page) => {
    setLoading(true);
    try {
      const res = await fetch(
        `${API_PATH.EXPERT_CALL_LIST}/${userData?.data?._id}?page=${page}&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result, "resultData");
        setCallList(result?.data);
        setCallPage(result?.countData);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.data?._id, userData?.token]);

  useEffect(() => {
    GetCallList(currentPage);
  }, [GetCallList, currentPage]);



  const handleIconClick = () => {
    navigate(-1);
  };

  document.title = "Expert-CallRecords";

  return (
    <>
      <ExpertDashboard />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <Row className="justify-content-center marginT">
              <Col xl={11}>
                <Row>
                  <Col>
                    <h5
                      className="d-flex align-items-center mt-0"
                      style={{ fontWeight: 600, fontSize: "26px" }}
                      onClick={handleIconClick}
                    >
                      <span
                        className="pe-3 bckIcon"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 320 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path>
                        </svg>
                      </span>

                      <span>Call Records</span>
                    </h5>
                  </Col>
                </Row>

                <div>
                  <Table
                    columns={columns}
                    dataSource={callList}
                    loading={loading}
                    pagination={false}
                    scroll={{ x: 800 }}
                  ></Table>
                  <Pagination
                    {...paginationSettings}
                    showSizeChanger={false}
                    style={{
                      marginTop: 16,
                      marginBottom: 16,
                      textAlign: "right",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </main>
      </div>
    </>
  );
};

export default CallRecords;
