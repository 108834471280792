import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from '@ant-design/icons';
import { FaCheck } from 'react-icons/fa';
import {
  Button,
  Checkbox,
  // Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Space, Select, Tooltip,
  Switch,
  notification,
} from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/en-gb"; // Import locale if needed, adjust locale as per your requirements
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import { Col, Row } from "react-bootstrap";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import { Country } from "country-state-city";
dayjs.extend(customParseFormat);
const { Option } = Select;

const AddNewExpertSection = () => {
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [experts, setExperts] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);
  const [industOption, setFilteredIndust] = useState("");
  const [open, setOpen] = useState(false);
  const [resExpert, setResExpert] = useState(false);
  const [customIndustry, setCustomIndustry] = useState("");
  const [addData, setAddData] = useState({
    expert_id: "",
    email: null,
    expert_name: "",
    primary_expert_rate: "",
    expert_primary_rate_currency: "USD",
    industry_experience: "",
    inustry_or_area_of_expertise: "",
    submit: "",
    link: "",
    consulting_rate: "",
    expertCurrency: "USD",
    hourly_consulting_rate: "",
    expert_client_Currency: "USD",
    overview: "",
    current_location: "",
    profile_number: null,
  });
  const [form] = Form.useForm();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");
  let redirect = query.get("redirect");

  let expertID = query.get("expertID");

  const [inputs, setInputs] = useState([
    {
      title: "",
      company: "",
      startDate: "",
      endDate: "",
      current_show_check: "no",
      till_present: "no",
    },
  ]);

  const [inputsTwo, setInputsTwo] = useState([
    {
      titlePrev: "",
      companyPrev: "",
      startDatePrev: "",
      endDatePrev: "",
      previous_show_check: "no",
    },
  ]);
  // const handleSearch = (value) => {
  //   const filteredOptions = experts
  //     .map((itemData) => {
  //       const dataName = itemData.name
  //         ? itemData.name
  //         : `${itemData.first_name} ${itemData.last_name}`;
  //       return { value: dataName };
  //     })
  //     .filter((option) => option.value.toLowerCase().includes(value.toLowerCase()));

  //   // Check if there's an exact match with the input value
  //   const exactMatchOption = filteredOptions.find(
  //     (option) => option.value.toLowerCase() === value.toLowerCase()
  //   );

  //   if (exactMatchOption) {
  //     setSelectedValue(exactMatchOption.value); // Set the selected value
  //   } else {
  //     setOptions(filteredOptions); // Update the options list
  //   }
  // };

  const onBlur = () => {
    const selectedValue = addData?.expert_id;
    if (
      selectedValue &&
      !experts.some((itemData) => itemData.name === selectedValue)
    ) {
      // If the selected value doesn't exist in the original experts array, add it as a custom option
      setExperts((prevExperts) => [
        ...prevExperts,
        {
          name: selectedValue, // Assuming your expert object has a "name" property
          // Add other properties if needed, e.g., first_name, last_name, etc.
        },
      ]);
    }
  };

  // const handleSelect = (value) => {
  //   setSelectedValue(value);
  // };

  const GetIndustryList = useCallback(async (industOption) => {
    setLoading(true);

    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Industry Error", error);
    }
    setLoading(false);
  }, []);

  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
    const getData = setTimeout(() => {
      GetIndustryList(industOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [industOption]);
  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetIndustryList("");
    }
  }, [GetIndustryList]);

  const handleInputChange = useCallback((value, field, index) => {
    setInputs((prevInputs) =>
      prevInputs?.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            [field]:
              (field === "startDate" || field === "endDate") && value
                ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                : value, // If value is null (cleared), then set field to null
          };
        }
        return input;
      })
    );
  }, []);

  const handleInputChangeTwo = useCallback((value, field, index) => {
    setInputsTwo((prevInputs) =>
      prevInputs?.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            [field]:
              (field === "startDatePrev" || field === "endDatePrev") && value
                ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                : value, // If value is null (cleared), then set field to null
          };
        }
        return input;
      })
    );
  }, []);

  const handleAddInput = useCallback(() => {
    const newInputs = [
      ...inputs,
      { title: "", company: "", startDate: "", endDate: "" },
    ];
    setInputs(newInputs);
  }, [inputs]);

  const handleAddInputTwo = useCallback(() => {
    const newInputs = [
      ...inputsTwo,
      { titlePrev: "", companyPrev: "", startDatePrev: "", endDatePrev: "" },
    ];
    setInputsTwo(newInputs);
  }, [inputsTwo]);

  const handleDeleteInput = useCallback(
    (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
    },
    [inputs]
  );

  const handleDeleteInputTwo = useCallback(
    (index) => {
      const newInputs = [...inputsTwo];
      newInputs.splice(index, 1);
      setInputsTwo(newInputs);
    },
    [inputsTwo]
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const expertCurrency = useCallback(
    (value) => {
      const selectValue = value || "USD";
      setAddData({ ...addData, expertCurrency: selectValue });
    },
    [addData]
  );

  const expertRate = useCallback(
    (value) => {
      const selectValue = value;
      setAddData({ ...addData, consulting_rate: selectValue });
    },
    [addData]
  );

  const clientCurrency = useCallback(
    (value) => {
      const selectValue = value || "USD";
      setAddData({ ...addData, expert_client_Currency: selectValue });
    },
    [addData]
  );

  const clientRate = useCallback(
    (value) => {
      const selectValue = value;
      setAddData({ ...addData, hourly_consulting_rate: selectValue });
    },
    [addData]
  );

  const handleCurrentChange = useCallback(
    (checked, index) => {
      const updatedInputs = [...inputs];
      updatedInputs[index].current_show_check = checked ? "yes" : "no";
      setInputs(updatedInputs);
    },
    [inputs]
  );

  const handleCheckboxChange = useCallback((event, index) => {
    const { checked } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, till_present: checked ? "yes" : "no" } : input
      )
    );
  }, []);

  const handlePrevChange = useCallback(
    (checked, index) => {
      const updatedInputs = [...inputsTwo];
      updatedInputs[index].previous_show_check = checked ? "yes" : "no";
      setInputsTwo(updatedInputs);
    },
    [inputsTwo]
  );

  const getFormattedDateInOneFormat = useCallback((date) => {
    let trimDate = date?.trim();
    if (trimDate && trimDate != null && trimDate != "null") {
      const possibleFormats = ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"];
      let detectedFormat = null;
      for (let format of possibleFormats) {
        const parsedDate = moment(trimDate?.trim(), format, true);
        if (parsedDate.isValid()) {
          detectedFormat = format;
          break;
        }
      }
      return dayjs(trimDate, detectedFormat)?.format("MM, YYYY");
    } else {
      return "";
    }
  }, []);

  const onChange = useCallback(
    async (value, option) => {
      const selectValue = value;
      const setSelectedID = option?.key;
      setAddData({
        ...addData,
        email: selectValue,
        expert_id: setSelectedID,
        expert_name: option?.title,
      });
      try {
        const res = await axios.get(
          `${API_PATH.EXPERT_PROFILE}/${setSelectedID}`
        );
        setAddData({
          ...addData,
          expert_id: res?.data?.data[0]?._id || "",
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          email: res?.data?.data[0]?.email || "",
          primary_expert_rate: res?.data?.data[0]?.primary_expert_rate || "",
          link: res?.data?.data[0]?.linkedin_url || "",
          submit: res?.data?.data[0]?.submit || "",
          consulting_rate: res?.data?.data[0]?.hourly_rate_call_cunsltn || "",
          expertCurrency: res?.data?.data[0]?.hourly_currency || "",
          overview: res?.data?.data[0]?.overview || "",
          current_location: res?.data?.data[0]?.country || "",
          inustry_or_area_of_expertise: res?.data?.data[0]?.industry,
          profile_number: res?.data?.data?.[0]?.profile_number,
        });

        form.setFieldsValue({
          link: res?.data?.data[0]?.linkedin_url || "",
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          consulting_rate: res?.data?.data[0]?.hourly_rate_call_cunsltn || "",
        });

        const currentData = res?.data?.data[0]?.expertexperiences;
        const temDatas = currentData?.map((value, index) => {
          if (value?.till_present === "yes") {
            return {
              title: value?.career_title,
              company: value?.company_name,
              startDate: getFormattedDateInOneFormat(value?.date_from),
              endDate: getFormattedDateInOneFormat(value?.date_till),
              till_present: value?.till_present,
              current_show_check: value?.show_check ? value?.show_check : "no",
            };
          }
          return null;
        });

        const filteredData = temDatas.filter((val) => val !== null);
        setInputs(filteredData);
        const currentPrevData = res?.data?.data[0]?.expertexperiences;
        const temPrevData = currentPrevData.map((value) => {
          if (value?.till_present === "no") {
            return {
              titlePrev: value?.career_title || "",
              companyPrev: value?.company_name || "",
              startDatePrev: getFormattedDateInOneFormat(value?.date_from), //dayjs(value?.date_from, "MMMM YYYY").format('MM-YYYY'),
              endDatePrev: getFormattedDateInOneFormat(value?.date_till), //dayjs(value?.endDate, "MMMM YYYY").format('MM-YYYY'),
              previous_show_check: value?.show_check || "no",
            };
          } else {
            return null;
          }
        });
        const filteredPrevData = temPrevData.filter((val) => val !== null);
        setInputsTwo(filteredPrevData);
      } catch (error) {
        console.error(error);
      }
    },
    [addData, form, getFormattedDateInOneFormat]
  );

  const [, setFilteredExperts] = useState([]); // State to hold filtered experts

  const onSearch = useCallback(
    (query) => {
      const filtered = experts.filter((itemData) => {
        const dataName = itemData?.name
          ? itemData?.name
          : `${itemData?.first_name} ${itemData?.last_name}`;
        return dataName.toLowerCase().includes(query.toLowerCase());
      });
      setFilteredExperts(filtered);
    },
    [experts]
  );

  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const AddExpert = useCallback(
    async (status) => {
      // e.preventDefault();
      setLoading(true);

      const transformedData = inputs?.map((input) => ({
        current_designation: input?.title,
        current_compny: input?.company,
        current_designation_date: `${input?.startDate} - ${input?.endDate}`,
        current_show_check: input?.current_show_check,
        till_present: input?.till_present === "yes" ? "yes" : "no",
      }));

      // const isTillPresentChecked = transformedData.some(
      //   (data) => data?.till_present === "yes"
      // );

      const postData = {
        current_compny: [],
        current_designation: [],
        current_designation_date: [],
        current_show_check: [],
        // till_present: isTillPresentChecked ? "yes" : "no",
        till_present: [],
      };

      for (let i = 0; i < transformedData?.length; i++) {
        const data = transformedData[i];
        postData.current_compny?.push(data?.current_compny);
        postData.current_designation?.push(data?.current_designation);

        // Split the combined date string into individual start and end date values
        const [startDate, endDate] =
          data?.current_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postData?.current_designation_date?.push(
          `${startDate?.trim()} - ${endDate?.trim()}`
        );

        postData?.current_show_check?.push(data?.current_show_check);
        postData?.till_present?.push(data?.till_present);
        // Check if any of the inputs have till_present === "yes"
        // if (transformedData.some((data) => data?.till_present === "yes")) {
        //   postData.till_present = "yes"; // Update the till_present value to "yes"
        // }
      }
      const current_compny = postData?.current_compny;
      const current_designation = postData?.current_designation;
      const current_designation_date = postData?.current_designation_date;
      const current_show_check = postData?.current_show_check;
      const till_present = postData?.till_present;

      const transformedDataTwo = inputsTwo?.map((input) => ({
        previous_designation: input?.titlePrev,
        previous_compny: input?.companyPrev,
        previous_designation_date: `${input?.startDatePrev} - ${input?.endDatePrev}`, // Combine start and end dates into a single string
        previous_show_check: input?.previous_show_check,
      }));

      const postDataTwo = {
        previous_compny: [],
        previous_designation: [],
        previous_designation_date: [],
        previous_show_check: [],
      };

      for (let i = 0; i < transformedDataTwo?.length; i++) {
        const data = transformedDataTwo[i];
        postDataTwo?.previous_compny?.push(data?.previous_compny);
        postDataTwo?.previous_designation?.push(data?.previous_designation);

        // Split the combined date string into individual start and end date values
        const [startDatePrev, endDatePrev] =
          data?.previous_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postDataTwo?.previous_designation_date?.push(
          `${startDatePrev?.trim()} - ${endDatePrev?.trim()}`
        );

        postDataTwo?.previous_show_check?.push(data?.previous_show_check);
        // if (data?.till_present === "yes") {
        //   postDataTwo.till_present = "yes"; // Update the till_present value if any input has "yes"
        // }
      }

      const previous_compny = postDataTwo?.previous_compny;
      const previous_designation = postDataTwo?.previous_designation;
      const previous_designation_date = postDataTwo?.previous_designation_date;
      const previous_show_check = postDataTwo?.previous_show_check;

      if (status === "Submit") {
        // Perform save logic
        // setStatusData("Submit");
        // Make the necessary API request or update the state accordingly
      } else if (status === "Save as Draft") {
        // Perform draft logic
        // setStatusData("Save as Draft");
        // Make the necessary API request or update the state accordingly
      }

      try {
        if (expertID) {
          const res = await fetch(`${API_PATH?.PROJECT_EXPERT_UPDATE}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email, //added by onkar
              expert_name: addData.expert_name,
              // expert_primary_rate_currency: addData.expert_primary_rate_currency,
              // primary_expert_rate: addData.primary_expert_rate,
              industry_experience: addData.industry_experience,
              inustry_or_area_of_expertise:
                addData.inustry_or_area_of_expertise,
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency,
              profile_number: addData?.profile_number,
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency,
              user_id: userData?.data?._id, //prev project_id,
              overview: addData?.overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
              experts_detail_id: expertID,
            }),
          });
          const result = await res.json();
          if (result) {
            setSpinner(false);
            notification.success({
              message: "Expert has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
            if (redirect === "team") {
              setTimeout(() => {
                history(
                  `/dashboard/team/project-overview?project_id=${project_id}`
                );
              }, 800);
            } else if (redirect !== "team" && status === "Save as Draft") {
              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=`
                );
              }, 800);
            } else if (redirect !== "team" && status === "Submit") {
              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=submitted`
                );
              }, 800);
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } else {
          const res = await fetch(`${API_PATH?.Add_Expert}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email,
              expert_name: addData.expert_name,
              // expert_primary_rate_currency: addData.expert_primary_rate_currency,
              // primary_expert_rate: addData.primary_expert_rate,
              industry_experience: addData.industry_experience,
              inustry_or_area_of_expertise:
                addData.inustry_or_area_of_expertise,
              added_by_team: true,
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency,
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency,
              profile_number: addData?.profile_number,
              user_id: userData?.data?._id, //prev project_id
              overview: addData?.overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
            }),
          });

          const result = await res.json();
          if (result) {
            if (redirect === "team") {
              setTimeout(() => {
                history(
                  `/dashboard/team/project-overview?project_id=${project_id}`
                );
              }, 800);
              if (status === "Save as Draft") {
                notification.success({
                  message: "Expert saved as draft.",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              } else if (status === "Submit") {
                notification.success({
                  message: "Expert submitted successfully.",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              }
            } else if (redirect !== "team" && status === "Save as Draft") {
              notification.success({
                message: "Expert saved as draft.",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });

              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=`
                );
              }, 200);
            } else if (redirect !== "team" && status === "Submit") {
              notification.success({
                message: "Expert submitted successfully.",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });

              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=submitted`
                );
              }, 200);
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [
      addData?.consulting_rate,
      addData?.current_location,
      addData?.email,
      addData?.expertCurrency,
      addData?.expert_client_Currency,
      addData?.expert_id,
      addData.expert_name,
      addData?.hourly_consulting_rate,
      addData.industry_experience,
      addData.inustry_or_area_of_expertise,
      addData?.link,
      addData?.overview,
      addData?.profile_number,
      expertID,
      history,
      inputs,
      inputsTwo,
      project_id,
      redirect,
      userData?.data?._id,
      userData?.token,
    ]
  );

  const getExperts = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`${API_PATH.EXPERT_LIST}?page=1&limit=1000`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        setLoading(true);
        setExperts(filterDuplicates([...res?.data?.data]));
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [userData?.token]);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${API_PATH.PROJECT_EXPERT_GET}/${expertID}`);
      setAddData({
        expert_id: res?.data?.data[0]?.expert_id,
        email:
          res?.data?.data[0]?.expertprofiles?.[0]?.email ||
          res?.data?.data[0]?.email,
        link: res?.data?.data[0]?.link,
        submit: res?.data?.data[0]?.submit,
        expert_name:
          res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
        // primary_expert_rate: res?.data?.data[0]?.expert_rate,
        // expert_primary_rate_currency: res?.data?.data[0]?.hourly_currency,
        industry_experience: res?.data?.data[0]?.years_of_experience,
        inustry_or_area_of_expertise:
          res?.data?.data[0]?.industries_expertise ||
          res?.data?.data[0]?.industry,
        consulting_rate: res?.data?.data[0]?.consulting_rate,
        expertCurrency: res?.data?.data[0]?.expertCurrency,
        hourly_consulting_rate: res?.data?.data[0]?.hourly_consulting_rate,
        expert_client_Currency: res?.data?.data[0]?.expert_client_Currency,
        overview: res?.data?.data[0]?.overview || "",
        current_location: res?.data?.data[0]?.current_location,
        profile_number: res?.data?.data?.[0]?.profile_number,
      });
      const currentData = res?.data?.data[0]?.expert_experience;
      const temDatas = currentData?.map((value, index) => {
        if (value?.current_designation) {
          // Extract the start and end dates
          const [startDate, endDate] =
            value?.current_designation_date.split("-");
          return {
            title: value?.current_designation,
            company: value?.current_compny,
            startDate: getFormattedDateInOneFormat(startDate),
            endDate: getFormattedDateInOneFormat(endDate),
            till_present: value?.till_present,
            current_show_check: value?.show_check,
          };
        }
      });
      const filteredData = temDatas.filter(
        (val) => ![undefined, null].includes(val)
      );
      setInputs(filteredData);

      const currentPrevData = res?.data?.data[0]?.expert_experience;

      const temPrevData = currentPrevData.map((value, index) => {
        if (value?.previous_designation) {
          // Split the combined date string into individual start and end date values
          const [startDatePrev, endDatePrev] =
            value?.previous_designation_date.split("-");
          return {
            titlePrev: value?.previous_designation || "",
            companyPrev: value?.previous_compny || "",
            startDatePrev: getFormattedDateInOneFormat(startDatePrev),
            endDatePrev: getFormattedDateInOneFormat(endDatePrev),
            previous_show_check: value?.show_check || "no",
          };
        }
        // Return null for entries without a valid previous designation
        return null;
      });

      // Filter out null entries to get the valid data
      const filteredPrevData = temPrevData.filter(
        (val) => ![undefined, null].includes(val)
      );
      setInputsTwo(filteredPrevData);
      let currentObj = filteredData?.map((data) => {
        return {
          current_end_date: data?.endDate !== "" ? dayjs(data?.endDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
          current_start_date: data?.startDate !== "" ? dayjs(data?.startDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
        }
      })
      let prevObj = filteredPrevData?.map((data) => {
        return {
          prev_end_date: data?.endDatePrev !== "" ? dayjs(data?.endDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
          prev_start_date: data?.startDatePrev !== "" ? dayjs(data?.startDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
        }
      })
      const maxLength = Math.max(currentObj?.length, prevObj?.length);
      const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
        ...currentObj[index],
        ...prevObj[index],
      }));
      form.setFieldsValue({
        ...mergedArray,
        email:
          res?.data?.data[0]?.expertprofiles?.[0]?.email ||
          res?.data?.data[0]?.email,
        link: res?.data?.data[0]?.link || "",
        expert_name:
          res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
        consulting_rate: res?.data?.data[0]?.consulting_rate || "",
      });
    } catch (error) {
      console.error(error);
    }
  }, [expertID, form, getFormattedDateInOneFormat]);

  const oncecall = useRef(false);
  useEffect(() => {
    if (!oncecall.current) {
      oncecall.current = true;
      getExperts();
      fetchData();
      setLoading(false);
    }
  }, [fetchData, getExperts]);

  const suffixSelectorExp = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 100,
        }}
        size="large"
        name="expert_primary_rate_currency"
        value={addData?.expertCurrency || "USD"} // Set the value to the state value
        onChange={(value) => {
          setAddData({ ...addData, expertCurrency: value });
        }}
        defaultValue="USD"
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="INR">INR</Select.Option>
        <Select.Option value="AED">AED</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </Form.Item>
  );
  const suffixSelector = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 100,
        }}
        size="large"
        name="expertCurrency"
        value={addData?.expertCurrency || "USD"} // Set the value to the state value
        onChange={expertCurrency}
        defaultValue="USD"
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="INR">INR</Select.Option>
        <Select.Option value="AED">AED</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </Form.Item>
  );

  const suffixSelectorTwo = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 100,
        }}
        size="large"
        name="expert_client_Currency"
        value={addData?.expert_client_Currency || "USD"}
        onChange={clientCurrency}
        defaultValue="USD"
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="INR">INR</Select.Option>
        <Select.Option value="AED">AED</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </Form.Item>
  );

  const SearchFieldOption = (
    <Option key="search-field" value="search-field" disabled>
      <Input.Search
        size="large"
        placeholder="Search Expert"
        onSearch={(value) => {
          onSearch(value);
        }}
      />
    </Option>
  );

  const optionsDatas = useMemo(
    () =>
      experts?.map((itemData) => {
        const dataName = itemData?.email;
        // ? itemData?.name
        let fullName = `${itemData?.first_name} ${itemData?.last_name}`;
        return {
          value: dataName,
          id: itemData?._id,
          key: itemData?._id,
          name: fullName,
        };
      }),
    [experts]
  );
  const countries = Country?.getAllCountries();
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );
  const handleDropdownIndustry = (open) => {
    setOpen(open);
    setResExpert(false)
    if (open) {
      setCustomIndustry("")
      GetIndustryList("");
    }
  };

  return (
    <div>
      <Form
        className=""
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={() => {
          AddExpert("Submit");
        }}
      >
        <div>
          <h2 class="card-inside-title">Expert Details</h2>
          <Row md={12}>
            <Col md={6}>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Expert email is required.",
                  },
                ]}
              >
                {/* <Select
                showSearch
                placeholder="Expert Email"
                style={{
                  width: "100%",
                }}
                size="large"
                options={optionsDatas}
                filterOption={(inputValue, option) => {
                  return (
                    option?.value
                      ?.toUpperCase()
                      ?.indexOf(inputValue?.toUpperCase()) !== -1
                  );
                }}
                name="expert_id"
                virtual={false}
                allowClear
                onChange={onChange}
                defaultValue={addData?.email}
                value={addData?.email}
              /> */}
                <DebounceSelect
                  type="expert"
                  size="large"
                  optionType="email"
                  value={addData?.email}
                  placeholder="Select Expert"
                  onChange={onChange}
                  style={{
                    width: "100%",
                  }}
                  creatableSelect={true}
                  extraFunction={(email) => {
                    setAddData({ ...addData, email: email?.value });
                    form.setFieldValue("email", email?.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name={"expert_name"}
                rules={[
                  {
                    required: true,
                    message: "Expert Name is required.",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Expert Name"
                  autoComplete="off"
                  name="expert_name"
                  value={addData?.expert_name}
                  onChange={(e) => {
                    setAddData({ ...addData, expert_name: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name={"link"}
                rules={[
                  {
                    required: true,
                    message: "Linkedin URL is required.",
                  },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Linkedin URL"
                  name="link"
                  value={addData?.link}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name={"consulting_rate"}
                rules={[
                  {
                    required: true,
                    message: "Rate is required.",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={suffixSelectorExp}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="consulting_rate"
                  placeholder="Consulting Rate (For Expert)"
                  value={
                    addData?.consulting_rate === ""
                      ? undefined
                      : addData?.consulting_rate
                  }
                  onChange={(value) => {
                    setAddData({ ...addData, consulting_rate: value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-0" />
          <Row lg={12} className="mt-2">
            <Col lg={4}>
              {/* <Input
                size="large"
                placeholder="Location"
                name="current_location"
                value={addData?.current_location}
                onChange={handleChange}
              /> */}
              <Select
                name="current_location"
                style={{
                  width: "100%",
                }}
                size="large"
                labelInValue
                allowClear
                placeholder="Select Location"
                autoComplete="off"
                showSearch
                value={addData?.current_location || null}
                onChange={(value) => {
                  console.log("value: ", value);
                  setAddData((prevData) => ({
                    ...prevData,
                    current_location: value?.value,
                  }));
                }}
              >
                {updatedCountries}
              </Select>
            </Col>
            <Col lg={4}>
              {/* <Input
                size="large"
                placeholder="Industry/Area of Expertise"
                name="inustry_or_area_of_expertise"
                value={addData?.inustry_or_area_of_expertise}
                onChange={handleChange}
              /> */}
              <Select
                style={{
                  width: "100%",
                }}
                name="inustry_or_area_of_expertise"
                size="large"
                onSearch={setFilteredIndust}
                showSearch
                open={open}
                allowClear
                placeholder="Add Industries/Area of expertise"
                value={addData?.inustry_or_area_of_expertise || []}
                onDropdownVisibleChange={handleDropdownIndustry}
                onChange={(value) => {
                  setAddData((prevData) => ({
                    ...prevData,
                    inustry_or_area_of_expertise: value,
                  }));
                }}
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                dropdownRender={
                  (menu) => (
                    <>
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space.Compact
                          block
                          style={{ padding: "0 8px 4px", width: "100%" }}
                          className="zum_meeting_space"
                        >
                          <Input
                            placeholder="Add Industries/Area of expertise"
                            width={100}
                            value={customIndustry}
                            onChange={(e) => {
                              setCustomIndustry(e.target.value)
                            }}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          {resExpert ? (
                            <Button
                              type="text"
                              style={{ color: "green" }}
                              className="d-flex align-items-center"
                            >
                              <FaCheck />
                            </Button>
                          ) : (
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={(e) => {
                                setResExpert(true)
                                setAddData((prevData) => ({
                                  ...prevData,
                                  inustry_or_area_of_expertise: customIndustry
                                }));
                                setTimeout(() => {
                                  setOpen(false)
                                }, 500);

                              }}
                            >
                              Add
                            </Button>

                          )}
                        </Space.Compact>
                      </>
                    </>
                  )

                }
              >
                {filteredIndustOptions?.map((option) => (
                  <Option key={option?._id} value={option?.category_name}>
                    {option?.category_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={4}>
              <Input
                size="large"
                autoComplete="off"
                placeholder="Years of Industry Experience"
                name="industry_experience"
                value={addData?.industry_experience}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Current Designation</h2>
          <div>
            {inputs?.map(
              (input, index) =>
                input && (
                  <>
                    <div key={index}>
                      <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <Col span={12}>
                          <Input
                            size="large"
                            autoComplete="off"
                            placeholder="Job Title"
                            name="title"
                            value={input?.title}
                            onChange={(event) =>
                              handleInputChange(
                                event.target.value,
                                "title",
                                index
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            size="large"
                            autoComplete="off"
                            placeholder="Company"
                            name="company"
                            value={input?.company}
                            onChange={(event) =>
                              handleInputChange(
                                event.target.value,
                                "company",
                                index
                              )
                            }
                          />
                        </Col>
                      </Row>

                      <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                      >
                        <Col span={12}>
                          <Form.Item
                            name={[index, "current_start_date"]}
                            rules={[
                              {
                                required: true,
                                message: "Start date is required.",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              style={{ width: "100%" }}
                              placeholder="Start Date"
                              name="startDate"
                              value={
                                input?.startDate
                                  ? dayjs(input?.startDate, "MM, YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleInputChange(value, "startDate", index)
                              }
                              picker="month"
                              format="MM, YYYY" // Include the day of the month in the format
                              disabledDate={(current) =>
                                current && current.isAfter(dayjs(), "month")
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[index, "current_end_date"]}
                            rules={[
                              {
                                required: input?.till_present === "yes" ? false : true,
                                message: "End date is required.",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              style={{ width: "100%" }}
                              placeholder="End Date"
                              name="endDate"
                              disabled={
                                input?.endDate && input?.till_present === "yes"
                                  ? false
                                  : input?.till_present === "yes"
                                    ? true
                                    : false
                              }
                              value={
                                input?.endDate
                                  ? dayjs(input?.endDate, "MM, YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleInputChange(value, "endDate", index)
                              }
                              picker="month"
                              format="MM, YYYY" // Include the day of the month in the format
                              disabledDate={(current) =>
                                current && current.isAfter(dayjs(), "month")
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                      >
                        <Col span={12} className="">
                          <Switch
                            checked={input?.current_show_check === "yes"}
                            title="Highlight Designation"
                            onChange={(checked) =>
                              handleCurrentChange(checked, index)
                            }
                          />
                          <span className="ms-3 ">Highlight Designation</span>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            disabled={
                              input?.endDate && input?.till_present === "yes"
                                ? false
                                : input?.endDate
                                  ? true
                                  : false
                            }
                            checked={input?.till_present === "yes"}
                            onChange={(event) =>
                              handleCheckboxChange(event, index)
                            }
                            size="large"
                          >
                            Present
                          </Checkbox>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {inputs.length > 1 ? (
                        <div className="d-flex justify-content-end align-items-center my-2">
                          <Button
                            type="primary"
                            onClick={() => handleDeleteInput(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </>
                )
            )}

            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button
                size="default"
                icon={<PlusCircleOutlined />}
                className="text-center d-flex align-items-center viewButton"
                onClick={handleAddInput}
              >
                Add Current Designation
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Previous Designation</h2>
          <div>
            {inputsTwo?.map((input, index) => (
              <>
                <div key={index}>
                  <Row
                    gutter={[24, 24]}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Col span={12}>
                      <Input
                        size="large"
                        autoComplete="off"
                        placeholder="Job Title"
                        name="titlePrev"
                        value={input?.titlePrev}
                        onChange={(event) =>
                          handleInputChangeTwo(
                            event.target.value,
                            "titlePrev",
                            index
                          )
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        size="large"
                        autoComplete="off"
                        placeholder="Company"
                        name="companyPrev"
                        value={input?.companyPrev}
                        onChange={(event) =>
                          handleInputChangeTwo(
                            event.target.value,
                            "companyPrev",
                            index
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={[24, 24]}
                    className="d-flex justify-content-between align-items-center mt-4"
                  >
                    <Col span={12}>
                      <Form.Item
                        name={[index, "prev_start_date"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Start date is required.",
                      //   },
                      // ]} 
                      >
                        <DatePicker
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Start Date"
                          name="startDatePrev"
                          value={
                            input?.startDatePrev
                              ? dayjs(input?.startDatePrev, "MM, YYYY")
                              : null
                          }
                          onChange={(value) =>
                            handleInputChangeTwo(value, "startDatePrev", index)
                          }
                          picker="month"
                          format="MM, YYYY" // Include the day of the month in the format
                          disabledDate={(current) =>
                            current && current.isAfter(dayjs(), "month")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[index, "prev_end_date"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "End date is required.",
                      //   },
                      // ]}
                      >
                        <DatePicker
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="End Date"
                          name="endDatePrev"
                          value={
                            input?.endDatePrev
                              ? dayjs(input?.endDatePrev, "MM, YYYY")
                              : null
                          }
                          onChange={(value) =>
                            handleInputChangeTwo(value, "endDatePrev", index)
                          }
                          picker="month"
                          format="MM, YYYY" // Include the day of the month in the format
                          disabledDate={(current) =>
                            current && current.isAfter(dayjs(), "month")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row
                    gutter={[24, 24]}
                    className="d-flex justify-content-between align-items-center mt-4"
                  >
                    <Col span={12}>
                      <Switch
                        checked={input?.previous_show_check === "yes"}
                        value={input?.previous_show_check === "yes"}
                        onChange={(checked) => handlePrevChange(checked, index)}
                      />
                      <span className="ms-3">Highlight Designation</span>
                    </Col>

                    {/* <Col span={12}>
              <Checkbox size="large">Present</Checkbox>
            </Col> */}
                  </Row>
                </div>

                <div>
                  {inputsTwo.length > 1 ? (
                    <div className="d-flex justify-content-end align-items-center my-2">
                      <Button
                        type="primary"
                        onClick={() => handleDeleteInputTwo(index)}
                      >
                        Remove
                      </Button>
                    </div>
                  ) : null}
                </div>
              </>
            ))}

            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button
                size="medium"
                icon={<PlusCircleOutlined />}
                className="text-center d-flex align-items-center viewButton"
                onClick={handleAddInputTwo}
              >
                Add Previous Designation
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Overview</h2>
          <Row
            gutter={[24, 24]}
            className="d-flex justify-content-between align-items-center"
          >
            <Col span={24}>
              <CKEditor
                editor={Editor}
                data={addData?.overview}
                onReady={(editor) => {
                  console.info("Editor is ready to use!", editor);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "undo",
                    "redo",
                  ],
                  placeholder: "To reference an expert type @",
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  setAddData((prevData) => ({
                    ...prevData,
                    overview: newData,
                  }));
                }}
              />
              {/* <TextArea
                showCount
                maxLength={100}
                style={{
                  height: 120,
                  marginBottom: 24,
                }}
                name="overview"
                value={addData?.overview}
                onChange={handleChange}
                placeholder="Please enter a detailed overview of the experts experience and topical knowledge"
              /> */}
            </Col>
          </Row>
        </div>
        <Divider />
        <div>
          <Row className="d-flex align-items-center mt-4">
            <Col lg={4}>
              <Form.Item label="Expert Profile Number">
                <div>
                  <Input
                    size="large"
                    autoComplete="off"
                    type="number"
                    placeholder="Expert Profile Number"
                    name="profile_number"
                    value={addData?.profile_number}
                    onChange={handleChange}
                  />
                </div>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
                <Form.Item label="Expert: Hourly Consulting Rate" />
                <InputNumber
                  addonBefore={suffixSelector}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="consulting_rate"
                  value={addData?.consulting_rate}
                  onChange={expertRate}
                />
              </Col> */}

            <Col lg={6}>
              <Form.Item
                label="Client: Hourly Consulting Rate"
                layout="vertical"
              >
                <InputNumber
                  addonBefore={suffixSelectorTwo}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="hourly_consulting_rate"
                  placeholder="Hourly Consulting Rate for client"
                  value={addData?.hourly_consulting_rate}
                  onChange={clientRate}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <Button
            size="default"
            className="text-center me-2 viewBttn"
            onClick={() => AddExpert("Save as Draft")}
            htmlType="button"
          >
            Save as Draft
          </Button>
          <Button
            size="default"
            className="text-center viewButton"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewExpertSection;
