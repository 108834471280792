import {
  SearchOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  Form,
  Input,
  Modal,
  Select,
  Table,
  Pagination,
  Tooltip,
  notification,
  Divider,
} from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import API_PATH from "../../Constants/api-path";
import "../../assests/css/custom.css";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { TEAM_LEAD_ID, TEAM_MEMBER_ID } from "../../Utils/Constants";

const ExotelTable = () => {

  let history = useNavigate();
  const [form] = Form.useForm();
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);

  const [managerList, setManagerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [addManager, setAddManger] = useState({
    name: "",
    email: "",
    contact_number: "",
    role_id: "",
    password: "",
    team_member: []
  });
  const [managerId, setManagerId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [updateMangerId, setUpdateMangerId] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    key: ""
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);

  const [callPage, setCallPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };


  const GetManagerList = useCallback(async (page = 1, options = selectedOptions) => {
    setLoading(true);
    try {
      let object = {};
      if (options?.key) {
        object.key = options?.key;
      }
      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties
      const res = await fetch(
        `${API_PATH.MANAGER_LIST
        }?page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setTeamList(result?.teamMembers)
        setManagerList(result?.data);
        setFilteredClientList(result?.data);
        setCallPage(result?.countData);
        setIsSearching(false)
      } else {
        setIsSearching(false)
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setIsSearching(false)
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [selectedOptions, userData?.token]);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    GetManagerList(pageNumber, selectedOptions)
  }, [GetManagerList, selectedOptions]);

  const GetRoleList = useCallback(async () => {
    try {
      const res = await fetch(`${API_PATH.ROLE_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddManger({ ...addManager, [key]: value });
  };

  const handleSelectChange = (value) => {
    const selectValue = value;
    setAddManger({ ...addManager, role_id: selectValue });
  }

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    setAddManger({ ...addManager, contact_number: value });

    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (numericValue.length !== 10) {
      setErrorMessage("Mobile number should be 10 digits");
    } else {
      setErrorMessage(null);
    }
  };

  const AddManager = async () => {
    // e.preventDefault();
    setLoading(true);
    let body = {}
    if (addManager?.team_member?.length > 0) {
      body = {
        name: addManager?.name,
        email: addManager?.email?.toLowerCase(),
        contact_number: addManager?.contact_number,
        password: addManager?.password,
        role_id: addManager?.role_id,
        team_member: addManager?.team_member
      }
    } else {
      body = {
        name: addManager?.name,
        email: addManager?.email,
        contact_number: addManager?.contact_number,
        role_id: addManager?.role_id,
        password: addManager?.password,

      }
    }

    // addManager.email = addManager?.email?.toLowerCase();

    try {
      const res = await fetch(`${API_PATH.MANAGER_ADD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(body),
      });

      const result = await res.json();
      if (result) {
        GetManagerList(1);
        if (res.status === 200 || res.status === true) {
          setModal1Open(false);
          notification.success({
            message: "User account created",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const getMangerId = (_id) => {
    setManagerId(_id);
  };

  const deleteMangerModal = () => {
    DeleteManager(managerId);
  };

  const DeleteManager = async (_id) => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.MANAGER_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          admin_id: [_id],
        }),
      });

      const result = await res.json();
      if (result) {
        GetManagerList(1);
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "User account deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const UpdateManager = async () => {
    setLoading(true);
    try {
      let body = {}
      if (addManager?.team_member?.length > 0) {
        body = {
          name: addManager?.name,
          email: addManager?.email,
          contact_number: addManager?.contact_number,
          role_id: addManager?.role_id,
          team_member: addManager.team_member
        }
      } else {
        body = {
          name: addManager?.name,
          email: addManager?.email,
          contact_number: addManager?.contact_number,
          role_id: addManager?.role_id,
        }
      }
      const res = await fetch(`${API_PATH.MANAGER_UPDATE}/${updateMangerId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(body),
      });
      const result = await res.json();
      if (result) {
        GetManagerList(currentPage);
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "User account updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error('error: ', error);
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const setUserModal = ({ data, id }) => {
    const res = data?.find((val) => val._id === id);

    setAddManger({
      name: res?.name,
      email: res?.email,
      contact_number: res?.contact_number,
      role_id: res?.role_id ? res?.role_id : "",
      team_member: res?.team_member || []
    });
    setUpdateMangerId(id);
  };
  const constructUrl = useCallback((options) => {
    const { key } = options;
    const projectParam = Array.isArray(key) ? key.join(", ") : key;
    const sanitizedProject = projectParam
      ? projectParam.replace(/%20/g, " ")
      : "";

    const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode
    return history({
      pathname: `/dashboard/managers`,
      search: `?${urlParams.join("&")}`,
    });
  }, [history]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "rolename",
      key: "rolename",
      render: (rolename) => `${rolename ? rolename : "-"}`,
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Added on",
      dataIndex: "created_at",
      render: (created_at) => `${convertDate(created_at)}`,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id) => (
        // <Space size="middle">
        //   <Tooltip color="#5746EB" title="Edit" placement="top">
        //     <MdBorderColor
        //       onClick={() => {
        //         setModal3Open(true);
        //         setUserModal({ data: managerList, id: _id });
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>

        //   <Tooltip color="#5746EB" title="Delete" placement="top">
        //     <MdDelete
        //       onClick={() => {
        //         setModal2Open(true);
        //         getMangerId(_id);
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>
        // </Space>

        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={!permission?.[9]?.managers?.edit ? "Permission Denied"
            : "Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                disabled={!permission?.[9]?.managers?.edit}
                onClick={() => {
                  setModal3Open(true);
                  setUserModal({ data: managerList, id: _id });
                }}
              >
                <MdEdit />
              </button>
            </span>
          </Tooltip>

          <Tooltip placement="top" title={!permission?.[9]?.managers?.delete ? "Permission Denied"
            : "Delete"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                disabled={!permission?.[9]?.managers?.delete}
                onClick={() => {
                  setModal2Open(true);
                  getMangerId(_id);
                }}
              >
                <MdDelete />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const callOnce = useRef(false)

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true
      GetManagerList(currentPage, selectedOptions);
      GetRoleList();
      const url = constructUrl(selectedOptions);
    }

  }, [GetManagerList, GetRoleList, constructUrl, currentPage, selectedOptions]);

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const updateFooter = (
    <div className="d-flex justify-content-end align-items-center">
      <button
        className="secondaryBttn btn"
        onClick={() => setModal3Open(false)}
      >
        Close
      </button>

      <button
        onClick={() => {
          UpdateManager();
          setModal3Open(false);
        }}
        className="viewButton btn ms-2"
      >
        Update
      </button>
    </div>
  );

  const addFooter = (
    <div className="d-flex justify-content-end align-items-center">
      <Form.Item style={{ marginBottom: 0 }}>
        <button
          class="secondaryBttn btn"
          onClick={() => {
            setModal1Open(false);
            form.resetFields();
          }}
        >
          Close
        </button>
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => (
          <button
            onClick={() => {
              AddManager();
              form.resetFields();
            }}
            className="viewButton btn ms-2"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length ||
              !!errorMessage // Disable the button if there is a mobile number validation error
            }
          >
            Add User
          </button>
        )}
      </Form.Item>
    </div>
  );

  const footer = (
    <div>
      <button
        key="cancel"
        className="secondaryBttn btn"
        onClick={() => setModal2Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          deleteMangerModal();
          setModal2Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );



  const handleInputChange = useCallback(() => {
    const inputValue = selectedOptions.key
    // setSearchText(inputValue);
    setCurrentPage(1);
    constructUrl({
      key: inputValue,
    })
    GetManagerList(1, {
      key: inputValue,
    });
  }, [GetManagerList, constructUrl, selectedOptions.key]);

  //DEBOUNCE FUNCTION
  useEffect(() => {
    if (isSearching) {
      const handler = setTimeout(async () => {
        await handleInputChange();
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }

  }, [handleInputChange, isSearching]);


  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Users</h2>
              {permission?.[9]?.managers?.add && <button
                className="btn viewButton"
                onClick={() => {
                  setModal1Open(true);
                  setAddManger({
                    name: "",
                    email: "",
                    contact_number: "",
                    role_id: "",
                    password: "",
                    team_member: []
                  });
                }}
                size="large"
              >
                Add New User
              </button>}
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={(e) => {
                setIsSearching(true)
                setSelectedOptions({
                  key: e.target.value,
                })
              }
              }
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              allowClear
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              loading={loading}
              pagination={false}
              scroll={{ x: 800 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <Modal
        title={<div>
          Add New User
          <Divider className="m-2" />
        </div>}

        footer={addFooter}
        open={modal1Open}
        onOk={() => {
          form.resetFields();
          setModal1Open(false);
        }}
        onCancel={() => {
          form.resetFields();
          setModal1Open(false);
        }}
        width={800}
        centered
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={() => {
            setModal1Open(false);
            form.resetFields();
            AddManager();
          }}
        >
          <Row >
            <Col md={6}>
              <Form.Item
                label="Name:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
              >
                <Input
                  name="name"
                  size="large"
                  value={addManager?.name}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Mobile:"
                name="contact_number"
                rules={[
                  { required: true, message: "Mobile number is required!" },
                ]}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage}
              >
                <Input
                  name="contact_number"
                  size="large"
                  value={addManager?.contact_number}
                  maxLength={10}
                  onChange={handleMobileNumberChange}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Email:"
                name={["user", "email"]}
                rules={emailRules}
              >
                <Input
                  name="email"
                  autoComplete="new-email"
                  size="large"
                  value={addManager?.email}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                <Input.Password
                  name="password"
                  autoComplete="new-password"
                  size="large"
                  value={addManager?.password}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Role:"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  value={addManager?.role_id}
                  onChange={handleSelectChange}
                >
                  {roleList?.map((option, index) => (
                    <Select.Option value={option?._id} key={index}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {roleList?.filter((role) => role?._id === addManager?.role_id)?.[0]?._id !== TEAM_MEMBER_ID &&
              <Col md={6}>
                <Form.Item label="Team Member:">
                  <Select
                    size="large"
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    value={addManager?.team_member}
                    onChange={(value) => {
                      setAddManger({
                        ...addManager,
                        team_member: value
                      })
                    }}
                  >
                    {teamList?.map((option, index) => (
                      <Select.Option value={option?._id} key={index}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>
      </Modal>

      <Modal
        title={<div >Update User <Divider className="m-2" /></div>}
        open={modal3Open}
        onOk={() => {
          setModal3Open(false);
          form.resetFields();
        }}
        onCancel={() => {
          setModal3Open(false);
          form.resetFields();
        }}
        visible={true}
        footer={updateFooter}
        width={600}
        centered
      >
        <div className="mt-4 text-center">
          <Form
            form={form}
            layout="horizontal"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Row>
              <Col xs={2} md={3}>
                <Form.Item label="Name:" />
              </Col>
              <Col xs={10} md={9}>
                <Input
                  name="name"
                  size="large"
                  value={addManager?.name}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={3}>
                <Form.Item label="Mobile:"></Form.Item>
              </Col>
              <Col xs={10} md={9}>
                <Input
                  name="contact_number"
                  size="large"
                  value={addManager?.contact_number}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={3}>
                <Form.Item label="Email:"></Form.Item>
              </Col>
              <Col xs={10} md={9}>
                <Input
                  name="email"
                  size="large"
                  value={addManager?.email}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={3}>
                <Form.Item label="Role:"></Form.Item>
              </Col>
              <Col xs={10} md={9}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={addManager?.role_id}
                  onChange={handleSelectChange}
                >
                  {roleList?.map((option, index) => (
                    <Select.Option value={option?._id} key={index}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {/* {roleList?.filter((role) => role?._id === addManager?.role_id)?.[0]?._id === TEAM_LEAD_ID  */}
            {roleList?.filter((role) => role?._id === addManager?.role_id)?.[0]?._id !== TEAM_MEMBER_ID
              && <Row>
                <Col xs={2} md={3}>
                  <Form.Item label="Team Member:"></Form.Item>
                </Col>
                <Col xs={10} md={9}>
                  <Select
                    size="large"
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    value={addManager?.team_member || null}
                    onChange={(value) => {
                      setAddManger({
                        ...addManager,
                        team_member: value
                      })
                    }}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {teamList?.map((option, index) => (
                      <Select.Option value={option?._id} key={index}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>}
          </Form>

        </div>
      </Modal>

      <Modal
        title={<div >Delete User <Divider className="m-2" /></div>}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        // visible={true}
        footer={footer}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            Are you sure you want to delete this user?
          </p>{" "}
        </div>
      </Modal>
    </>
  );
};

export default ExotelTable;
