const ENV = {
  // BASE_URL: "http://192.168.0.255:8081",
  CRYPTO_JS_KEY: "Key890!@N2024R18Y88",
  // Production
  BASE_URL: "https://api.platform.nextyn.com",
  FRONTEND_BASE_URL: "https://platform.nextyn.com",

  // development
  // BASE_URL: "https://dev.api.platform.nextyn.com",
  // FRONTEND_BASE_URL: "https://dev.platform.nextyn.com",

  // Staging
  // BASE_URL: "https://staging.api.platform.nextyn.com",
  // FRONTEND_BASE_URL: "https://staging.platform.nextyn.com",

  // localhost
  // BASE_URL: "http://localhost:3200",
  // FRONTEND_BASE_URL: "http://localhost:3001",

  REACT_APP_SITE_KEY: "6LdqiMYpAAAAADARFk-fvT9J2q02N3h4LgZ8Sigz",
  REACT_APP_SECRET_KEY: "6LdqiMYpAAAAAPDazRhadqaOoyj0IIqbkgL-ze8e",

  //dev
  ZERO_BOUNCE_API_KEY: "bb0e8e042c3641c1b60a00542449841d",
  //production
  // ZERO_BOUNCE_API_KEY: "6cb96d2308074d28b328e0d065a2069c"

};

export default ENV;
