// privateRoute
import React from "react";
import {Navigate} from "react-router-dom";
import useDecodedTokenExpert from "../../Utils/useDecodedTokenExpert";

const ExpertPrivateRoute = ({children, token}) => {
  const decodedTokenExpert = useDecodedTokenExpert();
  let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));
  // if (decodedTokenExpert && isExpertAuth) {
  if (decodedTokenExpert) {
    return children;
  } else {
    return <Navigate to="/expert-login" replace />;
  }
  // if(!isExpertAuth){
  //     return <Navigate to="/expert-login" replace/>
  // }
  // return children;
  // if(myDecodeToken === null){
  //   return <Navigate to="/" replace/>
  // }
  // return children;
};

export default ExpertPrivateRoute;
