import { Checkbox, Divider, Input, Modal, notification, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import API_URL from '../../Constants/api-path';
import { LoadingOutlined } from "@ant-design/icons";

import axios from 'axios';
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
const CreditModal = ({ open, setOpen, data, getZoomList }) => {
    const [creditsDetails, setCreditsDetails] = useState([{
        time: null,
        callPrice: null,
        payment_terms: 21,
        advance_payment: null,
        send_post_call_email_expert: true,
        advance_payment_done: false,
        pro_bono: false,
        data: {}
    }])
    const [creditsDetailsClient, setCreditsDetailsClient] = useState([{
        time: null,
        callPrice: null,
        data: {}
    }])
    const [loading, setLoading] = useState(false)
    const [remarks, setRemarks] = useState("")
    const userData = JSON.parse(localStorage.getItem("userData"));

    const getTotalCallPrice = (duration, currency, callRate) => {
        let ratePerMinute = Number(callRate) / 60
        let actualPriceOfCall = Math.round(ratePerMinute * Number(duration) || 0)
        return actualPriceOfCall
        // return actualPriceOfCall?.toFixed(2)
    }
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        let arr = []
        if (data?.expert_credits) {
            arr = data?.expert_credits?.map((exp) => {
                return {
                    time: exp?.call_time,
                    callPrice: exp?.expert_call_price,
                    advance_payment: exp?.advance_payment,
                    payment_terms: exp?.payment_terms || 21,
                    send_post_call_email_expert: exp?.send_post_call_email_expert ? exp?.send_post_call_email_expert : true,
                    advance_payment_done: exp?.advance_payment_done ? exp?.advance_payment_done : false,
                    pro_bono: exp?.pro_bono ? exp?.pro_bono : false,
                    data: data
                }
            })
        } else {
            arr = data?.expert_details?.map((exp) => {
                return {
                    time: data?.duration,
                    callPrice: getTotalCallPrice(data?.duration, exp?.expert_call_rate_currency, exp?.expert_call_rate),
                    advance_payment: exp?.advance_payment,
                    payment_terms: exp?.payment_terms || 21,
                    send_post_call_email_expert: exp?.send_post_call_email_expert ? exp?.send_post_call_email_expert : true,
                    advance_payment_done: exp?.advance_payment_done ? exp?.advance_payment_done : false,
                    pro_bono: exp?.pro_bono ? exp?.pro_bono : false,
                    data: data
                }
            })
        }
        let clientDetails = {
            time: data?.client_call_time || data?.duration,
            callPrice: data?.client_call_price || getTotalCallPrice(data?.duration, data?.client_call_rate_currency, data?.client_call_rate),
            data: data
        }
        let remarks = data?.comment
        setCreditsDetailsClient(clientDetails)
        setCreditsDetails(arr)
        setRemarks(remarks)
    }, [data])

    const validateForm = () => {
        const errors = {};

        creditsDetails.forEach((detail, index) => {
            if (!detail.time) {
                errors[`time_${index}`] = 'Call duration is required';
            }
            if (["", undefined, null]?.includes(detail.callPrice) && !detail?.pro_bono) {
                errors[`callPrice_${index}`] = 'Call price is required';
            }
            if (["", undefined, null]?.includes(detail.payment_terms)) {
                errors[`payment_terms_${index}`] = 'Payment terms are required';
            }
        });

        if (!creditsDetailsClient.time) {
            errors.clientTime = 'Client call duration is required';
        }
        if (["", undefined, null]?.includes(creditsDetailsClient.callPrice)) {
            errors.clientCallPrice = 'Client call price is required';
        }

        if (!remarks) {
            errors.remarks = "remarks is required"
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const updateCallTime = async () => {
        if (!validateForm()) return;
        try {
            setLoading(true)
            let body = {
                id: data?._id,
                expert_credits: creditsDetails?.map((details, index) => {
                    return {
                        "expert_email": details?.data?.expert_details?.[index]?.expert_email,
                        "expert_name": details?.data?.expert_details?.[index]?.expert_name,
                        "expert_call_rate": Number(details?.data?.expert_details?.[index]?.expert_call_rate),
                        "expert_call_rate_currency": details?.data?.expert_details?.[index]?.expert_call_rate_currency,
                        "expert_call_price": Number(details.callPrice || 0),
                        "expert_company": details?.data?.expert_details?.[index]?.expert_company,
                        "expert_designation": details?.data?.expert_details?.[index]?.expert_designation,
                        "payment_terms": details?.payment_terms,
                        "call_time": Number(details.time),
                        "pre_call_email_sent": details?.data?.expert_details?.[index]?.pre_call_email_sent,
                        "optout_expert_for_pre_and_post_calls": details?.data?.expert_details?.[index]?.optout_expert_for_pre_and_post_calls,
                        //    NEW FIELDS
                        "advance_payment": details?.advance_payment,
                        "send_post_call_email_expert": details?.send_post_call_email_expert,
                        "advance_payment_done": details?.advance_payment_done,
                        "pro_bono": details?.pro_bono,
                    }
                }),
                remarks: remarks,
                client_call_price: creditsDetailsClient?.callPrice,
                client_call_time: creditsDetailsClient?.time

            }
            await axios.post(`${API_URL.UPDATE_ZOOM_CALL_TIME}`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            setOpen(false)
            getZoomList({ page: 1 })
            setLoading(false)
            notification.success({
                message: "Call Details Updated Successfully!",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
            });

        } catch (error) {
            setOpen(false)
            setLoading(false)
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
    }

    return (
        <div>
            <Modal
                centered
                title={<div>
                    Add Post Call Details
                    <Divider className="m-2" />
                </div>}
                open={open}
                destroyOnClose
                width={800}
                onOk={() => {
                    updateCallTime()
                }}
                onCancel={() => {
                    setOpen({
                        open: false,
                        data: {},
                        index: null
                    })
                    setValidationErrors({})
                }}
                okText={data?.expert_credits?.length > 0 ? <span>{loading && <Spin
                    size="small"
                    indicator={
                        <LoadingOutlined
                            style={{
                                color: "white",
                                paddingRight: 5,
                            }}
                        />
                    }
                />} Update</span> : <span>{loading && <Spin
                    size="small"
                    indicator={
                        <LoadingOutlined
                            style={{
                                color: "white",
                                paddingRight: 5,
                            }}
                        />
                    }
                />} {creditsDetails?.filter((dat) => !dat?.send_post_call_email_expert)?.length > 0 ? "Add to Call Records" : "Send Post Call Email"}</span>}
            >
                <label style={{
                    fontWeight: "500", fontSize: 17, textAlign: "left"
                }}>Expert</label>
                <div className='p-2 d-flex flex-column align-items-center'>

                    {creditsDetails?.map((dat, index) => (
                        <div className='mb-2'>
                            <label style={{
                                fontWeight: "500", fontSize: 15
                            }}>{dat?.data?.expert_details?.[index]?.expert_name}</label>
                            <div className='d-flex align-items-center gap-2 g-2 pb-2' key={index}>
                                <div>
                                    <span>Expert Call Duration <br /> (in mins)</span>
                                    <Input value={dat?.time} type='number' onChange={(e) => {
                                        let obj = {
                                            ...creditsDetails[index],
                                            callPrice: getTotalCallPrice(e.target.value, dat?.data?.expert_details?.[index]?.expert_call_rate_currency, dat?.data?.expert_details?.[index]?.expert_call_rate),
                                            time: e.target.value
                                        }
                                        let newArr = [...creditsDetails]
                                        newArr[index] = obj
                                        setCreditsDetails(newArr)

                                    }} placeholder='Add Meeting duration' />
                                    {validationErrors[`time_${index}`] && <span style={{ color: 'red' }}>{validationErrors[`time_${index}`]}</span>}

                                </div>
                                {dat?.advance_payment_done && <div>
                                    <span>Advance Payment Done <br /> (in {dat?.data?.expert_details?.[index]?.expert_call_rate_currency || dat?.data?.expert_credits?.[index]?.expert_call_rate_currency})</span>
                                    <Input value={dat?.advance_payment} placeholder='Advance Payment Done' onChange={(e) => {
                                        let obj = {
                                            ...creditsDetails[index],
                                            advance_payment: e.target.value,
                                        }
                                        let newRemarks = `Advance payment to expert - ${dat?.data?.expert_details?.[index]?.expert_call_rate_currency} ${e.target.value || 0}. Total amount to be paid  - ${dat?.data?.expert_details?.[index]?.expert_call_rate_currency} ${dat?.callPrice || 0}`
                                        setRemarks(newRemarks)
                                        let newArr = [...creditsDetails]
                                        newArr[index] = obj
                                        setCreditsDetails(newArr)
                                    }} />
                                    {/* {validationErrors[`callPrice_${index}`] && <span style={{ color: 'red' }}>{validationErrors[`callPrice_${index}`]}</span>} */}

                                </div>}
                                <div>
                                    <span>{dat?.advance_payment ? "Total Amount to be paid" : "Amount to be paid"} <br /> (in {dat?.data?.expert_details?.[index]?.expert_call_rate_currency || dat?.data?.expert_credits?.[index]?.expert_call_rate_currency})</span>
                                    <Input value={dat?.callPrice} placeholder='Price' disabled={dat?.pro_bono} onChange={(e) => {
                                        let obj = {
                                            ...creditsDetails[index],
                                            callPrice: e.target.value,
                                        }
                                        let newArr = [...creditsDetails]
                                        newArr[index] = obj
                                        setCreditsDetails(newArr)
                                    }} />
                                    {validationErrors[`callPrice_${index}`] && <span style={{ color: 'red' }}>{validationErrors[`callPrice_${index}`]}</span>}

                                </div>
                                <div>
                                    <span>Expert Payment Terms <br /> (in days)</span>
                                    <Input disabled={dat?.pro_bono} value={dat?.payment_terms} type='number' placeholder='Payment Terms (in days)' onChange={(e) => {
                                        let obj = {
                                            ...creditsDetails[index],
                                            payment_terms: e.target.value,
                                        }
                                        let newArr = [...creditsDetails]
                                        newArr[index] = obj
                                        setCreditsDetails(newArr)
                                    }} />
                                    {validationErrors[`payment_terms_${index}`] && <span style={{ color: 'red' }}>{validationErrors[`payment_terms_${index}`]}</span>}

                                </div>
                            </div>
                            <div>
                                <Checkbox checked={dat?.send_post_call_email_expert
                                } onChange={(e) => {
                                    let obj = {
                                        ...creditsDetails[index],
                                        send_post_call_email_expert: e.target.checked,
                                    }
                                    let newArr = [...creditsDetails]
                                    newArr[index] = obj
                                    setCreditsDetails(newArr)
                                }}>Send post call email to expert</Checkbox>
                                <Checkbox checked={dat?.advance_payment_done} disabled={dat?.pro_bono} onChange={(e) => {
                                    let obj = {
                                        ...creditsDetails[index],
                                        advance_payment_done: e.target.checked,
                                    }
                                    let newArr = [...creditsDetails]
                                    newArr[index] = obj
                                    setCreditsDetails(newArr)
                                }}>Advance payment done to expert</Checkbox>
                                <Checkbox checked={dat?.pro_bono} disabled={dat?.advance_payment_done} onChange={(e) => {
                                    let obj = {
                                        ...creditsDetails[index],
                                        pro_bono: e.target.checked,
                                        advance_payment: e.target.checked ? 0 : data?.expert_credits?.[index]?.advance_payment,
                                        callPrice: e.target.checked ? 0 : data?.expert_credits?.[index]?.expert_call_price || getTotalCallPrice(data?.duration, data?.expert_details?.[index]?.expert_call_rate_currency, data?.expert_details?.[index]?.expert_call_rate),
                                        payment_terms: 0,

                                    }
                                    let newArr = [...creditsDetails]
                                    newArr[index] = obj
                                    setCreditsDetails(newArr)
                                }}>Pro Bono</Checkbox>
                            </div>
                        </div>
                    ))}
                </div>

                <hr className='m-2 mb-3' />
                <label style={{
                    fontWeight: "500", fontSize: 17, textAlign: "left"
                }}>Clients</label>
                <div className='p-2 d-flex flex-column align-items-center'>
                    <div className='mb-2'>
                        <label style={{
                            fontWeight: "500", fontSize: 15
                        }}>{data?.clientname?.[0]?.companyName}</label>
                        <div className='d-flex align-items-center gap-2 g-2 pb-2' >
                            <div>
                                <span>Client Call Duration (in mins)</span>
                                <Input value={creditsDetailsClient?.time} type='number' onChange={(e) => {
                                    let obj = {
                                        ...creditsDetailsClient,
                                        callPrice: getTotalCallPrice(e.target.value, creditsDetailsClient?.data?.client_call_rate_currency, creditsDetailsClient?.data?.client_call_rate),
                                        time: e.target.value
                                    }
                                    setCreditsDetailsClient(obj)

                                }} placeholder='Add Meeting duration' />
                                {validationErrors.clientTime && <span style={{ color: 'red' }}>{validationErrors.clientTime}</span>}
                            </div>
                            <div>
                                <span>Amount to be paid (in {creditsDetailsClient?.data?.client_call_rate_currency})</span>
                                <Input value={creditsDetailsClient?.callPrice} placeholder='Price' onChange={(e) => {
                                    let obj = {
                                        ...creditsDetailsClient,
                                        callPrice: e.target.value,
                                    }
                                    setCreditsDetailsClient(obj)
                                }} />
                                {validationErrors.clientCallPrice && <span style={{ color: 'red' }}>{validationErrors.clientCallPrice}</span>}
                            </div>
                        </div>
                    </div>

                </div>
                <hr className='m-2 mb-3' />
                <div className='p-2 d-flex flex-column align-items-center'>
                    <Input.TextArea value={remarks} onChange={(e) => {
                        setRemarks(e.target.value)
                    }} placeholder='Add Remarks' />
                </div>
                {validationErrors?.remarks && <span style={{ color: 'red' }} className='ps-2'>{validationErrors?.remarks}</span>}

            </Modal>
        </div >
    )
}

export default CreditModal