import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    companyList: [],
    allClientList: [],
    addData: {
        project_title: "",
        project_start: null,
        project_deadline: null,
        client_name: "",
        company_id: "",
        client_domain: "",
        overview: "",
        status: "active",
        client_email: "",
        client_team_email: [],
        linkedin_account: "",
        platform: "",
        manager_id: [],
        no_of_calls_expected: "",
        no_of_profiles_sent: "",
        no_of_calls_completed: "",
        remarks: "",
        project_recieved_date: null,
        expert_status: ""
    },
    inputs: [],
    geographies: []

}

const addProjectSlice = createSlice({
    name: "addProjectSlice",
    initialState: initialState,
    reducers: {
        setCompanyList: (state, { payload }) => {
            state.companyList = payload
        },
        setAllClientList: (state, { payload }) => {
            state.allClientList = payload
        },
        setAddData: (state, { payload }) => {
            console.log('payload: ', payload);
            state.addData = payload
        },
        setInputs: (state, { payload }) => {
            state.inputs = payload
        },
        setGeographies: (state, { payload }) => {
            state.geographies = payload
        }
    }
})

export const { setCompanyList, setAllClientList, setAddData, setInputs, setGeographies } = addProjectSlice.actions
export const addProjectReducer = addProjectSlice.reducer