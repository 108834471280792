import React, { useState, useEffect } from "react";
import { CgTranscript } from "react-icons/cg";
import { IoCallOutline } from "react-icons/io5";
import { CiFileOn } from "react-icons/ci";
import { FaRegCircleCheck } from "react-icons/fa6";
import ProjectCallDetailsModal from "./ProjectCallDetailsModal";

function ScopeOverview({ datas }) {
  const [projectOverview, setProjectOverview] = useState({});
  const [open, setOpen] = useState(false);
  const [projectOverview1, setProjectOverview1] = useState({});
  useEffect(() => {
    setProjectOverview({ ...datas });
    setProjectOverview1({ ...datas });
  }, [datas]);

  // function CalculatePercentage(total, percentage) {
  //   return parseInt((percentage / total) * 100);
  // }
  function CalculatePercentage(total, percentage) {
    return parseInt((percentage / total) * 100);
  }

  const completedCalls = parseFloat(projectOverview1.completed_Calls);

  const percentage = CalculatePercentage(
    projectOverview.expected_calls,
    completedCalls
  );

  console.log(percentage, "percentage");
  console.log(projectOverview.expected_calls, "expected_calls");
  console.log(completedCalls, "completed_calls");




  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="progress col-10 mb-0">
          <div
            className="progress-bar"
            style={{ width: `${percentage}%` }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h5 className="my-0">{percentage + "%"}</h5>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <ul className="list-unstyled right-small-icons d-flex align-items-center justify-content-between w-100 mb-0 mt-3">
          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <CgTranscript style={{ fontSize: "18px" }} color="red" />
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview?.transcript_required
                  ? projectOverview?.transcript_required
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Transcript <br />
                Required
              </small>
            </div>
          </li>
          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <IoCallOutline style={{ fontSize: "18px" }} color="red" />
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview?.expected_calls
                  ? projectOverview?.expected_calls
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Expected <br />
                Calls
              </small>
            </div>
          </li>

          <li className="text-center" style={{ display: "unset" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <CiFileOn style={{ fontSize: "18px" }} color="red" />
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview1?.expected_Submitted
                  ? projectOverview1?.expected_Submitted
                  : 0}
              </span>
            </div>
            <div className="mt-2">
              <small className="d-block">
                Profiles
                <br />
                Submitted
              </small>
            </div>
          </li>

          <li className="text-center link-text" style={{ display: "unset", cursor: datas?.status === 3 ? "pointer" : "" }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="me-2">
                <FaRegCircleCheck style={{ fontSize: "18px" }} color="red" />
              </span>
              <span
                style={{ position: "relative", top: "1px", fontSize: "18px" }}
              >
                {projectOverview1?.completed_Calls
                  ? projectOverview1?.completed_Calls
                  : 0}
              </span>
            </div>
            <div className="mt-2" onClick={() => {
              if (datas?.status === 3) {
                setOpen(true)
              }
            }}>
              <small className="d-block">
                Completed
                <br />
                calls
              </small>
            </div>
          </li>
        </ul>
      </div>
      <ProjectCallDetailsModal setIsOpen={setOpen} isOpen={open} project_id={datas?._id} />
    </>
  );
}
export default ScopeOverview;
