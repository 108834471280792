import React from "react";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import ClockComponent from "./ClockComponent";
import { Spin } from "antd";
import ExpertDashboard from "./ExpertDashboard";
import ProjectStats from "./ProjectStats";
import CurrentProjects from "./CurrentProjects";
import EnquiriesTable from "./EnquiriesTable";
import CallRecords from "./CallRecords";

const Dashboard = () => {
  return (
    <>
      {/* <MobileMenu /> */}
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Nextyn | Home"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <div className="text-center loginScreenLinkedin">
          <Spin size="large" />
        </div>
        <main className="body-total content-wrapper float-start w-100 dashboardPage">
          <div>
            <div className="d-block d-lg-block">
              <div>
                <div className="dasb-div bk-div float-start w-100">
                  <ClockComponent />
                  {/* <div className="body-expart-div"> */}
                  <div>
                    <ProjectStats />
                  </div>
                  <div>
                    <ExpertDashboard />
                  </div>
                  <div>
                    <CallRecords />
                  </div>
                  <div>
                    <CurrentProjects />
                  </div>
                  <div>
                    <EnquiriesTable />
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
