import React, { useState, useRef } from 'react';
import { Select, Button, Divider, Space, Tooltip, Input, Spin } from 'antd';
import { FaCheck } from 'react-icons/fa';
import { PlusOutlined } from '@ant-design/icons';
import { convertToTitleCase } from '../../Utils/util';

const CustomSelect = ({
    debounceFetchClient,
    input,
    handleInputChange,
    index,
    dispatch,
    setStep1,
    step1,
    form,
    zoomAccountList,
    loadingClient,
    isLoadingClients,
    setEmail,
    email,
    onEmailChange,
    exist,
    addItem, fetchAllClientList, disabled = false, creatableSelect = true
}) => {
    const [open, setOpen] = useState(false);
    const clientSelectRef = useRef(null);
    const inputRef = useRef(null);
    const [resClient, setResClient] = useState(false);
    const handleButtonClick = () => {
        setOpen(false);
    };
    const handleDropdownVisibleChange = async (isOpen) => {
        setOpen(isOpen);
        setResClient(false)
        if (isOpen) {
            await fetchAllClientList("");
        }
    };
    return (
        <Select
            showSearch
            allowClear
            type="client"
            optionType="email"
            ref={clientSelectRef}
            filterOption={false}
            disabled={disabled}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            onSearch={debounceFetchClient}
            size="large"
            value={input?.client_email === "" ? undefined : input?.client_email}
            placeholder="Client Email"
            onChange={(newValue, option) => {
                handleInputChange(option, "client_email", index);
                dispatch(setStep1({
                    ...step1,
                    client: option?.company_id,
                }));
                form.setFieldValue([index, "client_name"], convertToTitleCase(option?.title));
                form.setFieldValue([index, "client_email"], convertToTitleCase(option?.value));


                // if (companySelected) {
                //     if (companySelected === option?.company_id) {
                //         dispatch(setStep1({
                //             ...step1,
                //             client: option?.company_id,
                //         }));
                //         handleInputChange(option, "client_email", index);
                //         form.setFieldValue([index, "client_name"], convertToTitleCase(option?.title));
                //         form.setFieldValue([index, "client_email"], convertToTitleCase(option?.value));
                //     } else {
                //         form.setFields([{
                //             name: [index, "client_email"],
                //             errors: ["Client of different companies not allowed."]
                //         }])
                //     }
                // }
            }}
            style={{ width: "100%" }}
            options={zoomAccountList?.map((item) => ({
                label: item?.email,
                value: item?.email,
                key: item?._id,
                title: item?.name,
                company_id: item?.company_id,
                in_list: true,
            }))}
            open={open}
            dropdownRender={
                creatableSelect ?
                    (menu) => (
                        <>
                            {loadingClient || isLoadingClients ? (
                                <span className="d-flex align-items-center justify-content-center" style={{ height: 40 }}>
                                    <Spin size="small" />{" "}
                                </span>
                            ) : (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Space.Compact
                                        block
                                        style={{ padding: "0 8px 4px", width: "100%" }}
                                        className="zum_meeting_space"
                                    >
                                        <Tooltip
                                            title={"For Unregistered Clients Only"}
                                            overlayStyle={{ zIndex: 2000 }}
                                        >
                                            <Input
                                                placeholder="Please enter client email"
                                                ref={inputRef}
                                                width={100}
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    if (e.target.value !== "") {
                                                        onEmailChange(e, index);
                                                    }
                                                }}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                        </Tooltip>
                                        {resClient ? (
                                            <Button
                                                type="text"
                                                disabled={!exist?.exist}
                                                style={{ color: "green" }}
                                                className="d-flex align-items-center"
                                            >
                                                <FaCheck />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="text"
                                                disabled={!exist?.exist}
                                                icon={<PlusOutlined />}
                                                className="d-flex align-items-center"
                                                onClick={(e) => {
                                                    setResClient(true)
                                                    addItem(e, index)
                                                    setTimeout(() => {
                                                        handleButtonClick()
                                                    }, 500);
                                                }}
                                            >
                                                Add
                                            </Button>
                                        )}
                                    </Space.Compact>

                                </>
                            )}
                        </>
                    ) : null}
        />
    );
};

export default CustomSelect;
