import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import EnquiryPageSection from "./EnquiryPageSection";
import { Spin } from "antd";

const EnquiryPage = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Nextyn | Enquiries"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <div className="bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope">
                  <h2>Enquiries</h2>
                  <div className="request-body-sec projects-details-page">
                    {/* {open ? ( */}
                    <EnquiryPageSection />
                    {/* ) : (
                      <>
                        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
                          <Spin size="large" />
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default EnquiryPage;
