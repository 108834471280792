import { Button, Divider, Form, Input, Modal, notification, Radio, Select } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { currencyList } from '../../Constants/Currency';
import API_URL from '../../Constants/api-path';
import { Country } from "country-state-city";
import "../../assests/css/custom.css"
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ACCOUNT_NAME_COUNTRIES, ACCOUNT_NUMBER_COUNTRIES, ACH_ROUTING_NUMBER_COUNTRIES, ADDRESS_COUNTRIES, BSB_CODE_COUNTRIES, IBAN_COUNTRIES, SORT_CODE_COUNTRIES, SWIFT_CODE_COUNTRIES, BANK_NAME_OR_CODE_COUNTRIES, ACCOUNT_TYPE_COUNTRIES, FINANCE_INSTITUTE_COUNTRIES, TRANSIT_NUMBER_COUNTRIES, INDIVIDUAL_TAX_ID_COUNTRIES, BANK_DETAILS_COUNTRIES, PAN_COUNTRIES, CLABE_COUNTRIES, BENEFICIARY_ACCOUNT_COUNTRY, IFSC_COUNTRIES, ACCOUNT_NAME_MANDATORY_COUNTRIES, ACCOUNT_NUMBER_MANDATORY_COUNTRIES, FINANCE_INSTITUTE_MANDATORY_COUNTRIES, TRANSIT_NUMBER_MANDATORY_COUNTRIES, INDIVIDUAL_TAX_ID_MANDATORY_COUNTRIES, ACCOUNT_TYPE_MANDATORY_COUNTRIES, BANK_NAME_OR_CODE_MANDATORY_COUNTRIES, IBAN_MANDATORY_COUNTRIES, IFSC_MANDATORY_COUNTRIES, SWIFT_CODE_MANDATORY_COUNTRIES, BSB_CODE_MANDATORY_COUNTRIES, ACH_ROUTING_NUMBER_MANDATORY_COUNTRIES, CLABE_MANDATORY_COUNTRIES, SORT_CODE_MANDATORY_COUNTRIES, ADDRESS_MANDATORY_COUNTRIES, PAN_MANDATORY_COUNTRIES, BENEFICIARY_ACCOUNT_MANDATORY_COUNTRY, BANK_BRANCH_NAME_MANDATORY_COUNTRIES, BANK_BRANCH_NAME_COUNTRIES } from '../../Utils/util';
const { Option } = Select;

const BankDetailsModal = ({ show, setShow, viewId, fetchData, allPaymentData, resetAll }) => {
    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem("expertData"));
    const [values, setValues] = useState({
        country: "United Kingdom",
        account_holder_name: "",
        account_number: "",
        finance_institute_number: "",
        transit_number: "",
        individual_tax_id: "",
        account_type: "",
        bank_name: "",
        bank_branch_address: "",
        iban_ifsc: "",
        clabe: "",
        bic_or_swift: "",
        branch_code: "",
        ach_routing_code: "",
        micr_code: "",
        currency: "GBP",
        expert_address: "",
        pan_number: "",
        pan_adhar_link_status: "",
        contact_person_name: "",
        contact_number: "",
        email: "",
        is_beneficiary_account: "",
        expert_id: userData?.data?._id,
    });
    const once = useRef(false)
    const handleReset = () => {
        once.current = false
        setValues({
            country: "United Kingdom",
            account_holder_name: "",
            account_number: "",
            finance_institute_number: "",
            transit_number: "",
            individual_tax_id: "",
            account_type: "",
            bank_name: "",
            bank_branch_address: "",
            iban_ifsc: "",
            clabe: "",
            bic_or_swift: "",
            branch_code: "",
            ach_routing_code: "",
            micr_code: "",
            currency: "GBP",
            expert_address: "",
            pan_number: "",
            pan_adhar_link_status: "",
            contact_person_name: "",
            contact_number: "",
            email: "",
            is_beneficiary_account: "",
            expert_id: userData?.data?._id,
        })
        form.resetFields()
    }
    useEffect(() => {
        if (show && !once.current) {
            once.current = true
            let item = allPaymentData?.filter((type) => type?.payment_method === "bank")?.[0]
            let obj = {
                ...values,
                country: item?.country || "United Kingdom",
                account_holder_name: item?.account_holder_name,
                account_number: item?.account_number,
                finance_institute_number: item?.finance_institute_number,
                transit_number: item?.transit_number,
                individual_tax_id: item?.individual_tax_id,
                account_type: item?.account_type,
                bank_name: item?.bank_name,
                bank_branch_address: item?.bank_branch_address,
                iban_ifsc: item?.iban_ifsc,
                clabe: item?.clabe,
                bic_or_swift: item?.bic_or_swift,
                branch_code: item?.branch_code,
                ach_routing_code: item?.ach_routing_code,
                micr_code: item?.micr_code,
                currency: item?.currency || "GBP",
                expert_address: item?.expert_address,
                pan_number: item?.pan_number,
                pan_adhar_link_status: item?.pan_adhar_link_status,
                contact_person_name: item?.contact_person_name,
                contact_number: item?.contact_number,
                is_beneficiary_account: item?.is_beneficiary_account || "",
                email: item?.email,
            }
            setValues(obj)
            form.setFieldsValue(obj)
        }
    }, [allPaymentData, form, show, values])

    const [loading, setLoading] = useState(false);
    const updatedCountries = useMemo(
        () =>
            BANK_DETAILS_COUNTRIES?.map((country, index) => {
                return (
                    <Option key={country} value={country}>
                        {country}
                    </Option>
                );
            }),
        []
    );

    const handleInputs = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [key]: value });
    };

    const validatePAN = (_, value) => {
        // if (!value) {
        //     return Promise.reject("Please enter PAN number");
        // }
        if (values.country === "Others") {
            return Promise.resolve();
        }

        const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (!panRegex.test(value)) {
            return Promise.reject("Invalid PAN number");
        }

        return Promise.resolve();
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (viewId) {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_UPDATE}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "bank",
                        payment_id: viewId,
                        ...values,
                    }),
                });

                const result = await res.json();
                if (result) {
                    once.current = false
                    resetAll()
                    fetchData();
                    if (result.status === 200 || result.status === true) {
                        notification.success({
                            message: "Payment details has been updated",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });
                        setShow(false);
                        handleReset()
                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            } else {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_ADD}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "bank",
                        ...values,
                    }),
                });

                const result = await res.json();
                console.log(result, "result");
                if (result) {
                    if (result.status === 200 || result.status === true) {
                        fetchData();
                        setShow(false);
                        handleReset()
                        notification.success({
                            message: "Payment method has been added successfully!",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });

                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    };
    return (
        <Modal
            title={<div>
                Bank Details
                <Divider className="m-2" />
            </div>}
            open={show}
            centered
            width={1000}
            onCancel={() => {
                setShow(false)
                handleReset()
            }}
            bodyStyle={{
                height: 500,
                overflowY: "scroll",
                overflowX: "hidden",
            }}
            className="modal_scroll removeScrollbar"
            footer={false}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                name='bank_details_form'
                initialValues={values}
                form={form}
                onFinish={() => {
                    handleSubmit()
                }}
            >
                <Row>
                    <Col>
                        <Form.Item
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: "Field is required",
                                },
                            ]}
                            label="Select Country"
                        >
                            <Select
                                name="country"
                                size="large"
                                allowClear
                                value={values.country}
                                placeholder="Select Country"
                                autoComplete="off"
                                showSearch
                                onChange={(value) => {
                                    let currencyValue = Country?.getAllCountries().filter((cont) => cont?.name === value)?.[0]?.currency
                                    let obj = {
                                        country: value,
                                        account_holder_name: "",
                                        account_number: "",
                                        finance_institute_number: "",
                                        transit_number: "",
                                        individual_tax_id: "",
                                        account_type: "",
                                        bank_name: "",
                                        bank_branch_address: "",
                                        iban_ifsc: "",
                                        clabe: "",
                                        bic_or_swift: "",
                                        branch_code: "",
                                        ach_routing_code: "",
                                        micr_code: "",
                                        currency: currencyValue || "",
                                        expert_address: "",
                                        pan_number: "",
                                        pan_adhar_link_status: "",
                                        contact_person_name: "",
                                        contact_number: "",
                                        email: "",
                                        is_beneficiary_account: "",
                                        expert_id: userData?.data?._id,
                                    }
                                    setValues(obj);
                                    form.setFieldsValue(obj)
                                }}
                            >
                                {updatedCountries}
                            </Select>
                        </Form.Item>
                    </Col>
                    {ACCOUNT_NAME_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="account_holder_name"
                            label="Account Holder Name"
                            rules={[
                                {
                                    required: ACCOUNT_NAME_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Account Holder Name is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="account_holder_name"
                                value={values?.account_holder_name}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {ACCOUNT_NUMBER_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="account_number"
                            label="Account Number"
                            rules={[
                                {
                                    required: ACCOUNT_NUMBER_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Account Number is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="316977XXXX"
                                name="account_number"
                                value={values?.account_number}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {FINANCE_INSTITUTE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="finance_institute_number"
                            label="Financial institution number (3 digit)"
                            rules={[
                                {
                                    required: FINANCE_INSTITUTE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: " Financial institution number is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="316977XXXX"
                                name="finance_institute_number"
                                value={values?.finance_institute_number}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}

                    {TRANSIT_NUMBER_COUNTRIES?.includes(values.country) &&
                        <Col xs={12} md={6}>
                            <Form.Item
                                name="transit_number"
                                label="Transit Number (5 digit)"
                                rules={[
                                    {
                                        required: TRANSIT_NUMBER_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                        message: " Transit number is required",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    autoComplete="nope"
                                    size="large"
                                    placeholder="316977XXXX"
                                    name="transit_number"
                                    value={values?.transit_number}
                                    onChange={handleInputs}
                                ></Input>
                            </Form.Item>
                        </Col>}
                    {INDIVIDUAL_TAX_ID_COUNTRIES?.includes(values.country) &&
                        <Col xs={12} md={6}>
                            <Form.Item
                                name="individual_tax_id"
                                label="Individual tax ID (CPF)"
                                rules={[
                                    {
                                        required: INDIVIDUAL_TAX_ID_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                        message: "Individual tax ID (CPF) is required",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    autoComplete="nope"
                                    size="large"
                                    placeholder="316977XXXX"
                                    name="individual_tax_id"
                                    value={values?.individual_tax_id}
                                    onChange={handleInputs}
                                ></Input>
                            </Form.Item>
                        </Col>}


                    {ACCOUNT_TYPE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="account_type"
                            label="Account Type"
                            rules={[
                                {
                                    required: ACCOUNT_TYPE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Account Type is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="account_type"
                                value={values?.account_type}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}

                    {BANK_NAME_OR_CODE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="bank_name"
                            label="Bank Name"
                            rules={[
                                {
                                    required: BANK_NAME_OR_CODE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Bank Name is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="bank_name"
                                value={values?.bank_name}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {BANK_BRANCH_NAME_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="bank_branch_address"
                            label="Bank Branch Address"
                            rules={[
                                {
                                    required: BANK_BRANCH_NAME_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Bank Branch Address is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="bank_branch_address"
                                value={values?.bank_branch_address}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {IBAN_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="iban_ifsc"
                            label={`IBAN ${values.country === "Others" ? "(Please enter if applicable)" : ""}`}
                            rules={[
                                {
                                    required: IBAN_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "IBAN is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="iban_ifsc"
                                value={values?.iban_ifsc}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {IFSC_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="iban_ifsc"
                            label="IFSC"
                            rules={[
                                {
                                    required: IFSC_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "IFSC is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="iban_ifsc"
                                value={values?.iban_ifsc}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {/* {RECIPIENT_NAME_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="recipient_name"
                            label="Recipient Name"
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="recipient_name"
                                value={values?.recipient_name}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>} */}

                    {SWIFT_CODE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="bic_or_swift"
                            label="BIC/SWIFT"
                            rules={[
                                {
                                    required: SWIFT_CODE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "BIC/SWIFT is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="bic_or_swift"
                                value={values?.bic_or_swift}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}


                    {BSB_CODE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="branch_code"
                            label="Branch Code/BSB Code"
                            rules={[
                                {
                                    required: BSB_CODE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Branch Code/BSB Code is required",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="branch_code"
                                value={values?.branch_code}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}

                    {ACH_ROUTING_NUMBER_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="ach_routing_code"
                            label="ACH Routing Code"
                            rules={[
                                {
                                    required: ACH_ROUTING_NUMBER_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "ACH Routing Code is required",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="ach_routing_code"
                                value={values?.ach_routing_code}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}
                    {CLABE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item
                            name="clabe"
                            label="CLABE (18 digit number starting with 3 digit bank code)"
                            rules={[
                                {
                                    required: CLABE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "CLABE Code is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="clabe"
                                value={values?.clabe}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}

                    {SORT_CODE_COUNTRIES?.includes(values.country) && <Col xs={12} md={6}>
                        <Form.Item name="micr_code" label="MICR/Sort Code" rules={[
                            {
                                required: SORT_CODE_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                message: "MICR/Sort Code is required",
                            },
                        ]}>
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="micr_code"
                                value={values?.micr_code}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>}

                    <Col xs={12} md={12}>
                        <Form.Item name="currency" label="Payment Currency (Please select the currency in which you would prefer to receive the consultation fee)"
                            rules={[
                                {
                                    required: true,
                                    message: "Payment Currency is required",
                                },
                            ]}>
                            <Select
                                size="large"
                                placeholder="Select Preferred Currency"
                                name="currency"
                                showSearch
                                filterOption={(input, option) => option?.value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0

                                }
                                onChange={(newValue, option) => {
                                    setValues({ ...values, currency: newValue });
                                    form.setFieldValue("currency", newValue);
                                }}
                                options={currencyList?.map((item) => ({
                                    label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>{item?.code}</span> <span>({item?.name})</span></div>,
                                    value: item?.code,
                                    key: item?.code,
                                }))}
                                virtual={false}
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                    {ADDRESS_COUNTRIES?.includes(values.country) && <Col xs={12} md={12}>
                        <Form.Item
                            name="expert_address"
                            label="Address (with postal code)"
                            rules={[
                                {
                                    required: ADDRESS_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                    message: "Address is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="expert_address"
                                value={values?.expert_address}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>

                    </Col>}

                    {PAN_COUNTRIES?.includes(values.country) && (
                        <>
                            <Col xs={12} md={6}>
                                <Form.Item
                                    name="pan_number"
                                    label="PAN (of the account holder)"
                                    rules={[{ required: PAN_MANDATORY_COUNTRIES?.includes(values.country) ? true : false, validator: validatePAN }]}
                                >
                                    <Input
                                        type="text"
                                        autoComplete="nope"
                                        className="text-uppercase"
                                        size="large"
                                        placeholder=""
                                        name="pan_number"
                                        value={values?.pan_number}
                                        onChange={handleInputs}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Item
                                    name="pan_adhar_link_status"
                                    label="PAN Link with Aadhar"
                                    rules={[
                                        {
                                            required: PAN_MANDATORY_COUNTRIES?.includes(values.country) ? true : false,
                                            message: "PAN Link with Aadhar is required",
                                        },
                                    ]}
                                >
                                    <Select
                                        size="large"
                                        placeholder="PAN Link with Aadhar"
                                        name="pan_adhar_link_status"
                                        onChange={(newValue, option) => {
                                            console.log('newValue: ', newValue);
                                            setValues({
                                                ...values,
                                                pan_adhar_link_status: newValue,
                                            });
                                        }}
                                        value={values?.pan_adhar_link_status}
                                        options={[{
                                            label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>Yes</span></div>,
                                            value: "yes",
                                            key: "yes",
                                        }, {
                                            label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>No</span></div>,
                                            value: "no",
                                            key: "no",
                                        }]}

                                        virtual={false}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                        </>
                    )}
                    {BENEFICIARY_ACCOUNT_COUNTRY?.includes(values.country) && <Row>
                        <Col>
                            <Form.Item
                                name={"is_beneficiary_account"}
                                label="Is the beneficiary account an NRI bank account?"
                                className="mb-0"
                                rules={[
                                    {
                                        required: BENEFICIARY_ACCOUNT_MANDATORY_COUNTRY?.includes(values.country) ? true : false,
                                        message: "Field is required",
                                    },
                                ]}
                            >
                                {/* <span className="font-weight-bold me-2">Is the beneficiary account an NRI bank account?</span> */}
                                <Radio.Group name='is_beneficiary_account' onChange={(e) => {
                                    setValues({
                                        ...values,
                                        is_beneficiary_account: e.target.value,
                                    });
                                }} value={values.is_beneficiary_account}>
                                    <Radio value={"yes"}>Yes</Radio>
                                    <Radio value={"no"}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Row>
                        <Col className='text-end d-flex align-items-center justify-content-end gap-2'>
                            <Form.Item>
                                <Button
                                    className="btn-responsive expertButtonNext"
                                    onClick={() => {
                                        setShow(false)
                                        handleReset()
                                    }}
                                    htmlType='button'
                                >
                                    Close
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    className="btn-responsive viewButton"
                                    htmlType='submit'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Row>
            </Form>
        </Modal>
    )
}

export default BankDetailsModal
