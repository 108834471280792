import { DatePicker, Divider, Form, Input, Modal } from 'antd'
import React, { useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { clearAll, setAddCreditModal, setFormValues } from '../Slice/creditManagement.slice';
import useCreditManagement from '../hooks/useCreditManagement';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import moment from 'moment';

dayjs.extend(customParseFormat);
function AddCreditsModal() {
    const [form] = Form.useForm();
    const { addCreditModal, formvalues, credit_value_in_dollor } = useSelector((state) => state.creditManagement)
    const onlyOnce = useRef(false)
    const { addCredits } = useCreditManagement()
    const dispatch = useDispatch()
    useEffect(() => {
        if (!onlyOnce.current) {
            onlyOnce.current = true
            form.setFieldsValue(formvalues)
        }
    }, [form, formvalues])
    return (
        <Modal
            title={<div className="modalHeader">Add Credits <Divider className='m-2'/></div>}
            open={addCreditModal}
            maskClosable={false}
            onOk={() => dispatch(setAddCreditModal(false))}
            onCancel={() => {
                dispatch(setAddCreditModal(false))
                form.resetFields()
            }}
            width={680}

            centered
            footer={false}
        >
            <Form form={form} layout="vertical" name="form_in_creditmodal"
                // initialValues={formvalues}
                onFinish={() => {
                    addCredits()
                    dispatch(setAddCreditModal(false));
                    form.resetFields()
                }}

            >
                <Row>
                    <Col>
                        <Form.Item label="Amount $" name="amount" rules={[{
                            required: true,
                            message: "Amount is Required!"
                        },
                            // {
                            //     validator(_, value) {
                            //         let credits = value / credit_value_in_dollor
                            //         if (!value || credits >= 100) {
                            //             return Promise.resolve();
                            //         }
                            //         return Promise.reject('Clients must purchase a minimum of 100 credits!');
                            //     },
                            // },
                        ]}>
                            <Input size="large" placeholder="10,000" type='number'
                                onChange={(e) => {
                                    let obj = {
                                        ...formvalues,
                                        amount: e.target.value,
                                        // credits: e.target.value / credit_value_in_dollor >= 100 ? e.target.value / credit_value_in_dollor : 0
                                        credits: e.target.value / credit_value_in_dollor
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("amount", e.target.value)
                                    form.setFieldValue("credits", e.target.value / credit_value_in_dollor)
                                    // form.setFieldValue("credits", e.target.value / credit_value_in_dollor >= 100 ? e.target.value / credit_value_in_dollor : 0)
                                }} />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item label="Credits" name="credits" rules={[{
                            required: true,
                            message: "Credits are Required!"
                        }]}>
                            <Input size="large" type='number' disabled placeholder="credits" onChange={(e) => {
                                let obj = {
                                    ...formvalues,
                                    credits: e.target.value
                                };
                                dispatch(setFormValues(obj));
                                form.setFieldValue("credits", e.target.value)
                            }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Item label="Payment Date" name="payment_date" >
                            <DatePicker
                                className="mb-2 w-100"
                                size="large"
                                format="DD-MM-YYYY"
                                picker="date"
                                allowClear={false}
                                name="date_from1"
                                // value={
                                //     formvalues?.payment_date
                                //         ? dayjs(formvalues?.payment_date, "DD-MM-YYYY")
                                //         : null
                                // }
                                onChange={(value) => {
                                    let obj = {
                                        ...formvalues,
                                        payment_date: dayjs(value).format(),
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("payment_date", dayjs(value, "DD-MM-YYYY"))
                                }
                                }

                            />
                            {/* <DatePicker
                                format="DD-MM-YYYY"
                                size={"large"}
                                name='new_payment_date'
                                style={{ width: "100%" }}
                                picker='date'
                                onChange={(date, dateString) => {
                                    let obj = {
                                        ...formvalues,
                                        payment_date: dateString,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("payment_date", dayjs(dateString, "DD-MM-YYYY"))
                                }}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Payment Reference Id" name="payment_refrence_id">
                            <Input size="large" placeholder="Refrence id" name="referenceId"
                                onChange={(e) => {
                                    let obj = {
                                        ...formvalues,
                                        payment_refrence_id: e.target.value,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("payment_refrence_id", e.target.value)
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item label="Credit Validity (Optional)" name="credit_validity">
                            <DatePicker
                                format="DD-MM-YYYY"
                                picker='date'
                                size={"large"}
                                style={{ width: "100%" }}
                                disabledDate={(current) => {
                                    return moment() >= current
                                }}
                                onChange={(date) => {
                                    let obj = {
                                        ...formvalues,
                                        credit_validity: dayjs(date).format(),
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("credit_validity", dayjs(date, "DD-MM-YYYY"))
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex justify-content-end align-items-center">
                        <Form.Item style={{ marginBottom: 0 }} onClick={() => {
                            form.resetFields()
                            dispatch(setAddCreditModal(false));
                            dispatch(clearAll());
                        }}>
                            <button class="secondaryBttn btn" type="button">Close</button>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <button className="viewButton btn ms-2" type='submit'>Add Credit</button>
                        </Form.Item>
                    </div>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddCreditsModal