import React, { useState, useRef, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { GrAttachment } from "react-icons/gr";
import AddExpertSvg from "../../assests/images/addExpert.svg";
import AvatarGroup from "react-avatar-group";
import { MdDelete, MdOutlineClose, MdOutlineStar } from "react-icons/md";
import CloseProject from "./CloseProject";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useLocation, useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { BsChevronLeft } from "../../Utils/Icons";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryOptions } from "../../Constants/CountryOptions";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import {
  formatNumber,
  convertDate,
  getFormattedDate,
  modifiedContent,
} from "../../Utils/util";
import { generateExpertProfile } from "../expert_profile/ExpertSubmittedProfile";
import { FiDownload } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import ProjectExpertListModal from "./Components/ProjectExpertListModal";
import ProjectCallDetailsModal from "../projects/ProjectCallDetailsModal";

function ProjectOverview() {
  const navigate = useNavigate();
  const [handleDeleteProject, setDeleteProject] = useState(false);
  const [handleDeleteExpert, setDeleteExpert] = useState(false);
  const [handleCloseProject, setCloseProject] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(2);
  const [show, setShow] = useState(false);
  const [teamProject, setTeamProject] = useState(false);
  const editorRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [loadingPreview, setloadingPreivew] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [, setLoading] = useState(false);
  const [liveProject, setliveProject] = useState([]);
  const [nextynTeam, setNextynTeam] = useState([]);
  const [phone, setPhone] = useState("");
  const [tempId, setTempId] = useState("");
  const [isOpenExpertListModal, setIsOpenExpertListModale] = useState("");
  const [open, setOpen] = useState({
    id: "",
    isOpen: false,
  });
  const [intData, setInvtData] = useState("");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");

  let status = query.get("status");
  const [form] = Form.useForm();
  const [emailList, setEmailList] = useState([]);
  console.log("emailList: ", emailList);
  const [messages] = useState("");
  const [, setSpinner] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setExprtList] = useState([]);
  const [expertIdEdit, setExpertIdEdit] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [addExpert, setAddExpert] = useState({
    expert_name: "",
    linkedin_url: "",
    contact_number: "",
    email: "",
    contact_code: "",
    rate: "",
    currency: "",
    added_by: "",
    project_id: "",
    remark: "",
    by_LinkedinAccount: "",
    country: "",
    data: {},
  });
  const GetLiveProjectList = React.useCallback(async () => {
    setLoading(true);
    form.resetFields();
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}/${project_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setliveProject(result?.data);
        setNextynTeam(result?.nextynTeam);
        // let emailListFromProject =
        //   result?.data?.invite_team_dashboard?.split(",");
        // let emailsFormated = emailListFromProject
        //   ?.filter((em) => !["", "", null, undefined]?.includes(em))
        //   ?.map((em) => {
        //     return `${em}@nextyn.com`;
        //   });
        let formated = result?.nextynTeam?.map((team) => {
          return {
            label: team?.name,
            value: team?.email,
            key: team?._id,
          };
        });
        setEmailList([...formated]);
        form.setFieldsValue({ nextyn_team: [...formated] });
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [form, project_id, userData?.token]);

  const handleSubmit = React.useCallback(async () => {
    setSpinner(true);

    let emailData = [];
    for (var Email in emailList) {
      if (emailList[Email]?.email !== "")
        emailData?.push(emailList[Email]?.email);
    }

    let payload = {
      project_id: project_id,
      invite_team_dashboard: emailList?.map(
        (email) => email?.value?.split("@")?.[0] || email?.split("@")?.[0]
      ),
    };
    if (payload.invite_team?.length === 0 || emailData?.length === 0) {
      notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    } else {
      try {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_INVITE}`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        const result = await res.json();
        if (result) {
          if (result?.status === 200 || result?.status === true) {
            setEmailList([]);
            form.resetFields(["nextyn_team"]);
            setTeamProject(false);
            useOnce.current = false;
            notification.success({
              message: "Team member has been successfully invited",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
            await GetLiveProjectList();
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    }
  }, [GetLiveProjectList, emailList, form, project_id, userData?.token]);

  const editName = React.useCallback((email) => {
    if (typeof email !== "string") {
      return "NoName";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName
      ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
      : "";
    return `${firstName} ${lastName}`.trim();
  }, []);

  const CloseTeamProject = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH?.TEAM_PROJECT_ADD}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          status: status,
        }),
      });

      const result = await res?.json();
      if (result) {
        if (result && (res.status === 200 || res.status === true)) {
          // setShow(false);
          // GetLiveProjectList();
          // GetCompleteProjectList();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          notification.success({
            message: "Project has been closed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  }, [project_id, status, userData?.token]);

  const useOnce = React.useRef(false);
  useEffect(() => {
    if (!useOnce.current) {
      useOnce.current = true;
      GetLiveProjectList();
    }
  }, [GetLiveProjectList]);

  const getTeamIdEdit = React.useCallback((_id) => {
    setExpertIdEdit(_id);
  }, []);

  const columns = [
    {
      title: "Expert Name",
      dataIndex: "expert_name",
      key: "expert_name",
    },
    {
      title: "Expert Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
  ];
  const dataSource = liveProject?.team_project_experts?.map((record, index) => {
    const truncatedTitle = record?.name
      ? record.name.split(" ").slice(0, 4).join(" ")
      : "";

    // Add three dots at the end if the title was truncated
    const displayTitle =
      truncatedTitle !== record?.name ? truncatedTitle + "..." : truncatedTitle;
    const avatarName = record?.expertAddedBy?.name || "-";
    return {
      key: index,
      expert_name: (
        <div
          className=""
          onClick={() => {
            toggleDrawer();
            getTeamIdEdit(record?._id);
          }}
        >
          <Tooltip color="#5746EB" title={record?.name} placement="topLeft">
            <h5 className="projectTitle">{displayTitle || record?.name}</h5>
          </Tooltip>
          {record?.submit === "Save as Draft" ? (
            <Tag
              style={{
                fontSize: 10,
              }}
              className="mt-1 text-center"
              size="small"
              color="red"
            >
              Draft
            </Tag>
          ) : (
            <small>{record?.email}</small>
          )}
        </div>
      ),
      email: (
        <>
          <p>{record?.email}</p>
        </>
      ),
      rate: (
        <div
          style={{
            fontWeight: "600",
          }}
        >
          {record?.expertCurrency || "USD"} {record?.consulting_rate || ""}
        </div>
      ),
      addedBy: (
        //  <AvatarGroup
        //   avatars={[avatarName]}
        //   uppercase={true}
        //   className="team-info"
        //   initialCharacters={2}
        //   fontColor="#f33636"
        //   max={2}
        //   // bold={true}
        //   size={40}
        //   fontSize=".38"
        //   backgroundColor="#EFEFEF"
        // />
        <div>
          <Tooltip color="#5746EB" title={avatarName} placement="topLeft">
            <h5 className="projectTitle">{avatarName}</h5>
          </Tooltip>
          <small>{convertDate(record?.created_at)}</small>
        </div>
      ),
      action: (
        <div className="d-flex flex-row gap-2 g-2 justify-content-center">
          <Tooltip placement="top" title={"Schedule a call"} color="#5746EB">
            <span>
              <button
                disabled={record?.submit === "Save as Draft"}
                onClick={() => {
                  navigate(
                    `/dashboard/draft-zoom-meetings/add-meeting?pid=${project_id}&pn=${liveProject?.project_title}&eid=${record?._id}`
                  );
                }}
                style={{ fontWeight: "600", color: "#5746EC" }}
                className="viewBttnExpert"
              >
                <SiGooglemeet />
              </button>
            </span>
          </Tooltip>
          <Tooltip placement="top" title={"Download PDF"} color="#5746EB">
            <span>
              <button
                onClick={async () => {
                  setTempId(record?._id);
                  setloading(true);
                  let res = await fetchData(record?._id);
                  if (res) {
                    handleDownloadPDF(res?.data);
                  }
                }}
                disabled={loading && tempId === record?._id}
                style={{
                  fontWeight: "600",
                  color: "#5746EC",
                  cursor: "pointer",
                }}
                className="viewBttnExpert"
              >
                {loading && tempId === record?._id ? (
                  <>
                    <Spin
                      size="small"
                      indicator={
                        <LoadingOutlined className="viewBttnExpert rounded-circle" />
                      }
                    />
                  </>
                ) : (
                  <>
                    <FiDownload />
                  </>
                )}
              </button>
            </span>
          </Tooltip>
        </div>
      ),
      data: record,
    };
  });
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  const getInvite = React.useCallback((inviteData) => {
    setInvtData(inviteData);
  }, []);

  const removeNextynFromEmail = React.useCallback((email) => {
    if (email && email.includes("@nextyn.com")) {
      return email.replace("@nextyn.com", "");
    }
    return email;
  }, []);

  const DeleteInvite = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_INVITE_REMOVE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          invite_team_dashboard: intData,
        }),
      });
      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setShow(false);
          setDeleteProject(false);
          setEmailList([]);
          form.resetFields(["nextyn_team"]);
          notification.success({
            message: "Team member has been removed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          // window.location.reload();
          await GetLiveProjectList();
        } else {
          setEmailList([]);
          form.resetFields(["nextyn_team"]);
          setShow(false);
          setDeleteProject(false);
          await GetLiveProjectList();
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      setDeleteProject(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [GetLiveProjectList, form, intData, project_id, userData?.token]);

  const handleInput = React.useCallback(
    (e) => {
      const key = e.target.name;
      const value = e.target.value;

      setAddExpert({ ...addExpert, [key]: value });

      if (key === "email") {
        setEmailError(null); // Reset the email error message
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setEmailError("Invalid email address");
        }
      }
    },
    [addExpert]
  );

  const handleSelectChangeCurr = React.useCallback(
    (value) => {
      const selectValue = value;
      setAddExpert({ ...addExpert, currency: selectValue });
    },
    [addExpert]
  );

  const GetExpertList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.TEAM_EXPERT_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setExprtList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const toggleDrawer = React.useCallback(() => {
    setIsDrawerVisible(!isDrawerVisible);
  }, [isDrawerVisible]);

  const AddTeamExpert = React.useCallback(async () => {
    setLoading(true);

    try {
      const requestData = {
        expert_name: addExpert?.expert_name,
        linkedin_url: addExpert?.linkedin_url,
        contact_number: addExpert?.contact_number,
        email: addExpert?.email,
        contact_code: addExpert?.contact_code,
        country: addExpert?.country,
        rate: addExpert?.rate,
        currency: addExpert?.currency,
        added_by: userData?.data?._id,
        project_id: project_id,
        remark: addExpert?.remark,
        by_LinkedinAccount: addExpert?.by_LinkedinAccount,
      };

      const requestDataEdit = {
        expert_name: addExpert?.expert_name,
        linkedin_url: addExpert?.linkedin_url,
        contact_number: addExpert?.contact_number,
        email: addExpert?.email,
        contact_code: addExpert?.contact_code,
        country: addExpert?.country,
        rate: addExpert?.rate,
        currency: addExpert?.currency,
        added_by: userData?.data?._id,
        project_id: project_id,
        remark: addExpert?.remark,
        by_LinkedinAccount: addExpert?.by_LinkedinAccount,
        team_project_experts_id: expertIdEdit,
      };

      if (expertIdEdit) {
        const res = await fetch(`${API_PATH.TEAM_EXPERT_EDIT}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestDataEdit),
        });

        const result = await res.json();
        if (result) {
          if (result && (res.status === 200 || res.status === true)) {
            setShow();
            toggleDrawer();
            GetExpertList();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: "Expert has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } else {
        const res = await fetch(`${API_PATH.TEAM_EXPERT_ADD}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestData),
        });

        const result = await res.json();
        if (result) {
          if (result?.status === 200 || result?.status === true) {
            setShow();
            GetExpertList();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: "Expert has been added",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  }, [
    GetExpertList,
    addExpert?.by_LinkedinAccount,
    addExpert?.contact_code,
    addExpert?.contact_number,
    addExpert?.country,
    addExpert?.currency,
    addExpert?.email,
    addExpert?.expert_name,
    addExpert?.linkedin_url,
    addExpert?.rate,
    addExpert?.remark,
    expertIdEdit,
    project_id,
    toggleDrawer,
    userData?.data?._id,
    userData?.token,
  ]);

  const fetchData = useCallback(async (expertIdEdit) => {
    try {
      const res = await axios?.get(
        // `${API_PATH?.TEAM_EXPERT_BY_ID}/${expertIdEdit}`
        `${API_PATH?.PROJECT_EXPERT_GET}/${expertIdEdit}`
      );

      const projectData = res?.data?.data?.[0];

      if (projectData) {
        let obj = {
          expert_name: projectData?.name,
          linkedin_url: projectData?.link,
          contact_number: projectData?.contact_number,
          email: projectData?.email,
          contact_code: projectData?.contact_code,
          rate: projectData?.consulting_rate || "",
          currency: projectData?.expertCurrency || "USD",
          remark: projectData?.overview,
          by_linkedinAccount: projectData?.by_linkedinAccount,
          data: projectData,
        };
        setAddExpert(obj);
        const phoneValue = `+${projectData?.contact_code}${projectData?.contact_number}`;

        setPhone(phoneValue);
        return obj;
      } else {
        setAddExpert({
          expert_name: "",
          linkedin_url: "",
          contact_number: "",
          email: "",
          contact_code: "",
          rate: "",
          currency: "",
          added_by: "",
          project_id: "",
          remark: "",
          by_LinkedinAccount: "",
        });
        setPhone("");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      return false;
    }
  }, []);

  const DeleteExpert = React.useCallback(async () => {
    setLoading(true);

    try {
      const res = await fetch(
        `${API_PATH.TEAM_EXPERT_DELETE}/${expertIdEdit}`,
        // `${API_PATH.PROJECT_EXPERT_DELETE}/${expertIdEdit}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setShow(false);
          notification.success({
            message: "Expert has been removed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          await GetLiveProjectList();
          setDeleteExpert(false);
          toggleDrawer();
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetLiveProjectList, expertIdEdit, toggleDrawer, userData?.token]);
  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetExpertList();
    }
  }, [GetExpertList]);

  useEffect(() => {
    if (expertIdEdit && expertIdEdit?.length > 0) {
      fetchData(expertIdEdit);
      setLoading(false);
    } else {
      setAddExpert({
        expert_name: "",
        linkedin_url: "",
        contact_number: "",
        email: "",
        contact_code: "",
        rate: "",
        currency: "",
        added_by: "",
        project_id: "",
        remark: "",
        by_LinkedinAccount: "",
      });
    }
  }, [expertIdEdit, fetchData]);

  const handlePhoneChange = React.useCallback((value, country) => {
    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    if (selectedCountry) {
      setAddExpert((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));
    }
  }, []);

  const handleIconClick = () => {
    navigate(-1);
  };

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
  ];

  const itemsActive = [
    {
      key: "2",
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true);
              setUpdatedStatus(2);
            }}
            className=""
          >
            Close Project
          </span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true);
              setUpdatedStatus(3);
            }}
            className=" "
          >
            Hold Project
          </span>
        </div>
      ),
    },
  ];

  const itemsHold = [
    {
      key: "2",
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true);
              setUpdatedStatus(2);
            }}
            className=""
          >
            Close Project
          </span>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true);
              setUpdatedStatus(1);
            }}
            className=" "
          >
            Active Project
          </span>
        </div>
      ),
    },
  ];
  const items =
    liveProject?.status === 1
      ? itemsActive
      : liveProject?.status === 3
        ? itemsHold
        : [];

  const handleDownloadPDF = React.useCallback(async (data) => {
    setloading(true);
    await generateExpertProfile(data);
    setloading(false);
    setTempId("");
  }, []);
  return (
    <>
      <Helmet>
        <title>{"Project Overview | Nextyn Platform"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="bk-div float-start w-100">
              <div className="body-expart-div projectOverview">
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                  <h2
                    className="d-flex align-items-center"
                    onClick={handleIconClick}
                    style={{ cursor: "pointer" }}
                  >
                    <BsChevronLeft className="me-2 search-btn-back" />
                    Project Overview
                  </h2>
                </div>
                <Row className="g-3">
                  <Col lg={8}>
                    <Card
                      className="projectOverView ant-card gap-2"
                      title={
                        <>
                          <h4
                            className="mt-0 pt-0 mb-0"
                            style={{ color: "#5746EB" }}
                          >
                            <Tooltip title="Company" color="#5746EB">
                              <strong className="fw-bold">
                                {liveProject?.client_name}
                              </strong>{" "}
                              -{" "}
                            </Tooltip>
                            {liveProject?.project_title}
                          </h4>
                          <p className="mt-2">
                            {liveProject?.country
                              ?.split(",")
                              ?.map((country, index) => (
                                <Tag
                                  className="cust_tag"
                                  color="purple"
                                  key={index}
                                >
                                  {" "}
                                  {country}
                                </Tag>
                              ))}
                          </p>
                        </>
                      }
                    >
                      <p

                        dangerouslySetInnerHTML={{
                          __html: modifiedContent(liveProject?.question),
                        }}
                      ></p>

                      {liveProject?.attachments?.length > 0 && (
                        <div
                          style={{
                            marginTop: "20px",
                            paddingLeft: "10px",
                          }}
                        >
                          {liveProject?.attachments?.map((attach) => {
                            return (
                              <div className="attachment_overview">
                                <a
                                  href={attach?.s3url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="d-flex align-items-center gap-1"
                                >
                                  <GrAttachment />
                                  {attach?.file_original_name}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Card>

                    {/* when expert not available */}
                    {liveProject?.team_project_experts?.length === 0 ? (
                      <>
                        <Card className="text-center addExpertCard">
                          <img src={AddExpertSvg} alt="add_exp_img" />
                          <p className="mb-3">
                            Click on the button below to add an expert profile
                            in the project{" "}
                          </p>
                          <button
                            onClick={() => {
                              //  setShow(true)
                              navigate(
                                `/dashboard/addExpert?project_id=${project_id}&redirect=team`
                              );
                            }}
                            className="btn add-pls"
                          >
                            Add Experts
                          </button>
                        </Card>
                      </>
                    ) : null}

                    {liveProject?.team_project_experts?.length > 0 ? (
                      <>
                        <Card
                          title={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Expert Profiles</span>
                              <div className="">
                                <div className="d-flex flex-row gap-2 g-2 justify-content-center">
                                  {selectedRowKeys?.length > 0 && (
                                    <Tooltip
                                      placement="top"
                                      title={"Preview"}
                                      color="#5746EB"
                                    >
                                      <span>
                                        <button
                                          size="large"
                                          className="btn add-pls fs-5 "
                                          disabled={loadingPreview}
                                          onClick={async () => {
                                            setloadingPreivew(true);
                                            const promises = selectedRows?.map(
                                              async (row) => {
                                                let res = await fetchData(
                                                  row?.data?._id
                                                );
                                                if (res) {
                                                  return {
                                                    ...row,
                                                    newData: res?.data,
                                                  };
                                                } else {
                                                  return { ...row };
                                                }
                                              }
                                            );
                                            const modifiedContent =
                                              await Promise.all(promises);
                                            let data = modifiedContent?.map(
                                              (row) => {
                                                if (row?.newData) {
                                                  return row?.newData;
                                                }
                                              }
                                            );
                                            let url =
                                              await generateExpertProfile(
                                                data,
                                                true
                                              );
                                            setloadingPreivew(false);
                                            window.open(url, "_blank");
                                          }}
                                        >
                                          {loadingPreview ? (
                                            <>
                                              <Spin
                                                size="small"
                                                indicator={
                                                  // <LoadingOutlined color="#5746EB" style={{ color: "#5746EB" }} />
                                                  <LoadingOutlined className="viewBttnExpert rounded-circle" />
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <i
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                                style={{
                                                  fontSize: "12px",
                                                  cursor: "pointer",
                                                }}
                                              ></i>
                                            </>
                                          )}
                                        </button>
                                      </span>
                                    </Tooltip>
                                  )}
                                  {selectedRowKeys?.length > 0 && (
                                    <>
                                      <span className="ms-2">
                                        <button
                                          className="btn add-pls fs-5"
                                          disabled={loading}
                                          onClick={async () => {
                                            setloading(true);
                                            const promises = selectedRows?.map(
                                              async (row) => {
                                                let res = await fetchData(
                                                  row?.data?._id
                                                );
                                                if (res) {
                                                  return {
                                                    ...row,
                                                    newData: res?.data,
                                                  };
                                                } else {
                                                  return { ...row };
                                                }
                                              }
                                            );
                                            const modifiedContent =
                                              await Promise.all(promises);
                                            let data = modifiedContent?.map(
                                              (row) => {
                                                if (row?.newData) {
                                                  return row?.newData;
                                                }
                                              }
                                            );
                                            await generateExpertProfile(data);
                                            setloading(false);
                                          }}
                                        >
                                          {loading ? (
                                            <>
                                              <Spin
                                                size="small"
                                                indicator={
                                                  <LoadingOutlined className="viewBttnExpert rounded-circle" />
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <FiDownload className="fs-5" /> (
                                              {selectedRowKeys?.length})
                                            </>
                                          )}
                                        </button>
                                      </span>
                                    </>
                                  )}
                                  <button
                                    className="btn add-pls ms-2"
                                    onClick={() => {
                                      // setShow(true);
                                      setExpertIdEdit("");
                                      navigate(
                                        `/dashboard/addExpert?project_id=${project_id}&redirect=team`
                                      );
                                    }}
                                  >
                                    Add Experts
                                  </button>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <Table
                            dataSource={dataSource}
                            columns={columns}
                            rowSelection={rowSelection}
                            pagination={false}
                            style={{
                              overflowX: "auto",
                            }}
                          />
                        </Card>
                      </>
                    ) : null}
                  </Col>

                  <Col xs={12} lg={4}>
                    <Row>
                      <Col>
                        <Card title="Performance Statistics" bordered={false}>
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Expected Calls</span>
                              <span
                                className="col-indigo"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                              >
                                {formatNumber(
                                  liveProject?.no_of_calls_expected || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Profiles Sent</span>
                              <span
                                className="col-indigo"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                              >
                                {formatNumber(
                                  liveProject?.no_of_profiles_sent || 0
                                )}{" "}
                                (
                                {formatNumber(
                                  (liveProject?.no_of_profiles_sent /
                                    (2 * liveProject?.no_of_calls_expected)) *
                                  100 || 0
                                )}
                                %)
                              </span>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-between "
                              onClick={() => {
                                if (liveProject?.no_of_calls_completed > 0) {
                                  setOpen({
                                    id: liveProject?._id,
                                    isOpen: true,
                                  });
                                }
                              }}
                            >
                              <span>Completed Calls</span>
                              <span
                                className="col-indigo link-text"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                              >
                                {formatNumber(
                                  liveProject?.no_of_calls_completed || 0
                                )}{" "}
                                (
                                {formatNumber(
                                  (liveProject?.no_of_calls_completed /
                                    liveProject?.no_of_calls_expected) *
                                  100 || 0
                                )}
                                %)
                              </span>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-between">
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                Fill Rate
                              </span>
                              <span
                                className="col-indigo"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                              >
                                {formatNumber(
                                  (liveProject?.no_of_profiles_sent /
                                    liveProject?.no_of_calls_expected) *
                                  100 || 0
                                )}
                                %
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                Call Rate
                              </span>
                              <span
                                className="col-indigo"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                              >
                                {formatNumber(
                                  (liveProject?.no_of_calls_completed /
                                    liveProject?.no_of_calls_expected) *
                                  100 || 0
                                )}
                                %
                              </span>
                            </div> */}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card title="Project Details" bordered={false}>
                          {liveProject?.project_recieved_date && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Project Recieved Date:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  {convertDate(
                                    liveProject?.project_recieved_date
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                          {liveProject?.created_at && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Created At:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  {convertDate(liveProject?.created_at)}
                                </span>
                              </div>
                            </>
                          )}

                          {liveProject?.project_deadline && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Deadline:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  {convertDate(liveProject?.project_deadline)}
                                </span>
                              </div>
                            </>
                          )}

                          {liveProject?.addedBy?.name && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Added By:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  {liveProject?.addedBy?.name}
                                </span>
                              </div>
                            </>
                          )}
                          {liveProject?.linkedin_account && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Linkedin Account:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  {liveProject?.linkedin_account}
                                </span>
                              </div>
                            </>
                          )}

                          {liveProject?.remarks && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Additional Notes:
                                </span>
                                <span
                                  className="col-indigo"
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  <Tooltip title={liveProject?.remarks}>
                                    {_.truncate(liveProject?.remarks || "", {
                                      length: 20,
                                      omission: "...",
                                    })}
                                  </Tooltip>
                                </span>
                              </div>
                            </>
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          title={`${liveProject?.client_name || "Client"} Team`}
                          bordered={false}
                        >
                          <div className="client-teams-ul-li">
                            <ul className="list-unstyled">
                              {liveProject?.clientteamemail?.map(
                                (item, index) => (
                                  <>
                                    {item && (
                                      <li key={index}>
                                        <div className="teams-li d-flex align-items-center">
                                          <figure className="mb-0">
                                            <div className="avatarImg d-flex">
                                              <img
                                                width="40px"
                                                src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                                                  item?.name || "NoName"
                                                )}`}
                                                alt={item?.name || "NoName"}
                                              />
                                            </div>
                                          </figure>
                                          <h5
                                            className="mb-0 ms-2 mt-0"
                                            key={index}
                                          >
                                            {item ? item?.name || "-" : null}
                                            {item && (
                                              <>
                                                <span className="d-block">
                                                  {
                                                    <Tooltip
                                                      title={
                                                        liveProject
                                                          ?.clientemail?.[0]
                                                          ?.email
                                                      }
                                                    >
                                                      {_.truncate(
                                                        item?.email || "",
                                                        {
                                                          length: 25,
                                                          omission: "...",
                                                        }
                                                      )}
                                                    </Tooltip>
                                                  }
                                                </span>
                                              </>
                                            )}
                                            <span
                                              className="d-block"
                                              style={{ color: "#c40a36" }}
                                            >
                                              {item?.Invite === "Invite pending"
                                                ? `Invite pending (sent ${convertDate(
                                                  item?.created_at
                                                )})`
                                                : null}
                                            </span>
                                          </h5>
                                        </div>
                                        {/* <div className="dropdown">
                                          <div>
                                            <a className="ant-dropdown-trigger">
                                              <span
                                                role="img"
                                                aria-label="ellipsis"
                                                tabindex="-1"
                                                className="anticon anticon-ellipsis"
                                                style={{ fontSize: "20px" }}
                                              >
                                                <Tooltip
                                                  color="#5746EB"
                                                  title="Delete"
                                                  placement="top"
                                                >
                                                  <MdDelete
                                                    style={{
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setDeleteProject(true);
                                                      getInvite(
                                                        removeNextynFromEmail(
                                                          item
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </span>
                                            </a>
                                          </div>
                                        </div> */}
                                      </li>
                                    )}
                                  </>
                                )
                              )}
                              {liveProject?.clientemail && (
                                <li>
                                  <div className="teams-li d-flex align-items-center">
                                    <figure className="mb-0">
                                      <div
                                        key={"343534"}
                                        className="avatarImg d-flex"
                                      >
                                        <img
                                          width="40px"
                                          src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                                            liveProject?.clientemail?.[0]
                                              ?.name || "NoName"
                                          )}`}
                                          alt={
                                            liveProject?.clientemail?.[0]
                                              ?.name || "NoName"
                                          }
                                        />
                                      </div>
                                    </figure>
                                    <h5 className="mb-0 ms-2 mt-0" key={343534}>
                                      {liveProject?.clientemail?.[0]?.name
                                        ? liveProject?.clientemail?.[0]?.name
                                        : null}{" "}
                                      {liveProject?.clientemail && (
                                        <>
                                          <span className="d-block">
                                            {
                                              <Tooltip
                                                title={
                                                  liveProject?.clientemail?.[0]
                                                    ?.email
                                                }
                                              >
                                                {_.truncate(
                                                  liveProject?.clientemail?.[0]
                                                    ?.email || "",
                                                  {
                                                    length: 20,
                                                    omission: "...",
                                                  }
                                                )}
                                              </Tooltip>
                                            }
                                          </span>
                                        </>
                                      )}
                                      <span
                                        className="d-block"
                                        style={{ color: "#c40a36" }}
                                      >
                                        {liveProject?.client_email?.Invite ===
                                          "Invite pending"
                                          ? `Invite pending (sent ${convertDate(
                                            liveProject?.client_email
                                              ?.created_at
                                          )})`
                                          : null}
                                      </span>
                                    </h5>
                                  </div>
                                  <Tag
                                    size="small"
                                    color="#5746ec"
                                    style={{
                                      background: "transparent",
                                      color: "#5746ec",
                                      border: "1px solid #5746ec",
                                      fontSize: "10px",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    POC
                                  </Tag>
                                  {/* <div className="dropdown">
                                    <div>
                                      <a className="ant-dropdown-trigger">
                                        <span
                                          role="img"
                                          aria-label="ellipsis"
                                          tabindex="-1"
                                          className="anticon anticon-ellipsis"
                                          style={{ fontSize: "20px" }}
                                        >
                                          <Tooltip
                                            color="#5746EB"
                                            title="Delete"
                                            placement="top"
                                          >
                                            <MdDelete
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setDeleteProject(true);
                                                getInvite(
                                                  removeNextynFromEmail(
                                                    liveProject?.client_email
                                                  )
                                                );
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      </a>
                                    </div>
                                  </div> */}
                                </li>
                              )}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card
                          bordered={false}
                          title={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Nextyn Team</span>
                              <button
                                onClick={() => {
                                  useOnce.current = false;
                                  GetLiveProjectList();
                                  setTeamProject(true);
                                }}
                                className="btn add-pls"
                              >
                                Add Team
                              </button>
                            </div>
                          }
                        >
                          <div className="client-teams-ul-li">
                            <ul className="list-unstyled">
                              {nextynTeam?.map((item, index) => (
                                <>
                                  {item && (
                                    <li key={index}>
                                      <div className="teams-li d-flex align-items-center">
                                        <figure className="mb-0">
                                          <div className="avatarImg d-flex">
                                            <img
                                              width="40px"
                                              src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${item?.name || "NoName"
                                                }`}
                                              alt={item?.name || "NoName"}
                                            />
                                          </div>
                                        </figure>
                                        <h5
                                          className="mb-0 ms-2 mt-0"
                                          key={index}
                                        >
                                          {item ? item?.name : null}

                                          {
                                            <>
                                              <span className="d-block">
                                                {
                                                  <Tooltip title={item?.email}>
                                                    {_.truncate(
                                                      item?.email || "",
                                                      {
                                                        length: 20,
                                                        omission: "...",
                                                      }
                                                    )}
                                                  </Tooltip>
                                                }
                                              </span>
                                            </>
                                          }
                                        </h5>
                                      </div>
                                      {item?.ownerType !== "cs_owner" &&
                                        item?.ownerType !== "bd_owner" && (
                                          <div className="dropdown">
                                            <div>
                                              <a className="ant-dropdown-trigger">
                                                <span
                                                  role="img"
                                                  aria-label="ellipsis"
                                                  tabindex="-1"
                                                  className="anticon anticon-ellipsis"
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  <Tooltip
                                                    color="#5746EB"
                                                    title="Delete"
                                                    placement="top"
                                                  >
                                                    <MdDelete
                                                      style={{
                                                        fontSize: "20px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setDeleteProject(true);
                                                        getInvite(
                                                          removeNextynFromEmail(
                                                            item?.email
                                                          )
                                                        );
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      {(item?.ownerType === "cs_owner" ||
                                        item?.ownerType === "bd_owner") && (
                                          <Tag
                                            size="small"
                                            color="#5746ec"
                                            style={{
                                              background: "transparent",
                                              color: "#5746ec",
                                              border: "1px solid #5746ec",
                                              fontSize: "10px",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {item?.ownerType === "cs_owner"
                                              ? "CS OWNER"
                                              : item?.ownerType === "bd_owner"
                                                ? "BD OWNER"
                                                : ""}
                                          </Tag>
                                        )}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        title={
          <div>
            Remove invitee from project.
            <Divider className="m-2" />
          </div>
        }
        open={handleDeleteProject}
        onCancel={() => setDeleteProject(false)}
        centered
        size={"lg"}
        footer={false}
      >
        <div>Are you sure you want to remove?</div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="secondaryBttn btn"
            onClick={() => setDeleteProject(false)}
          >
            No
          </button>
          <button
            className="viewButton btn ms-2"
            onClick={() => DeleteInvite()}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Modal
        title="Delete Expert Profile"
        open={handleDeleteExpert}
        onCancel={() => setDeleteExpert(false)}
        centered
        size={"lg"}
        footer={false}
      >
        <div>Are you sure you want to delete this expert from the project?</div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="secondaryBttn btn"
            onClick={() => setDeleteExpert(false)}
          >
            No
          </button>
          <button
            className="viewButton btn ms-2"
            onClick={() => DeleteExpert()}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Modal
        show={show}
        onCancel={() => setShow(false)}
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
      >
        <MdOutlineClose
          className="position-absolute"
          style={{
            right: "15px",
            top: "15px",
            fontSize: "25px",
            cursor: "pointer",
          }}
          onClick={() => setShow(false)}
        />
        <div className="text-left">
          <h4 className="modalHeader">Close Project</h4>
        </div>
        <div>Are you sure you want to close this project ?</div>
        <div className="d-flex justify-content-end mt-3">
          <button className="secondaryBttn btn" onClick={() => setShow(false)}>
            No
          </button>
          <button
            className="viewButton btn ms-2"
            onClick={() => CloseTeamProject()}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Modal
        title={
          <div>
            Add Teams <Divider className="m-2" />
          </div>
        }
        open={teamProject}
        onCancel={() => {
          setEmailList([]);
          setTeamProject(false);
        }}
        centered
        size={"md"}
        footer={false}
      >
        <div>
          <Form
            layout={"vertical"}
            form={form}
            name="invite_nextyn_team_modal"
            onFinish={async () => {
              await handleSubmit();
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Form.Item
                className="mb-1"
                name="nextyn_team"
                label="Add Team"
                rules={[
                  {
                    required: true,
                    message: "Please Select the team member",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <DebounceSelect
                  type="manager"
                  size="large"
                  optionType="email"
                  name={`nextyn_team`}
                  mode="multiple"
                  value={emailList}
                  placeholder="Select Team Member"
                  style={{ flex: 1, marginRight: "8px" }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    console.log("selectedValue: ", selectedValue);
                    setEmailList(selectedValue);
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
            {messages === "" ? (
              <></>
            ) : (
              <p className="text-danger" style={{ marginLeft: "3%" }}>
                {messages}
              </p>
            )}

            {/* <Form.Item
                className="mb-1"
                name="team"
                label="Add Team"
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input size="large" addonAfter="@nextyn.com" />
              </Form.Item>

              <AiOutlinePlusCircle
                className="ms-2"
                style={{ fontSize: "22px", color: "#5746EB" }}
              /> */}
            {/* <div className="d-flex align-items-center">
                <Form.Item
                  className="mb-1"
                  name="team"
                  label=""
                  style={{ width: "100%" }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" addonAfter="@nextyn.com" />
                </Form.Item>

                <AiOutlineMinusCircle
                  className="ms-2"
                  style={{ fontSize: "22px", color: "red" }}
                />
              </div> */}
            <div className="d-flex justify-content-end mt-3">
              <Form.Item>
                <Button
                  className="secondaryBttn "
                  onClick={() => {
                    setTeamProject(false);
                    setEmailList([]);
                    useOnce.current = false;
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button className="viewButton ms-2" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        show={show}
        cancel={() => {
          setShow(false);
          setAddExpert({});
        }}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
      >
        <MdOutlineClose
          className="position-absolute"
          style={{ right: "15px", top: "15px", fontSize: "25px" }}
          onClick={() => setShow(false)}
        />
        <div className="text-center">
          <h4 className="modalHeader">Add Expert</h4>
        </div>
        <Form layout="vertical">
          <Row>
            <Col>
              <Form.Item label="Expert Name" rules={[{ required: true }]}>
                <Input
                  type="text"
                  name="expert_name"
                  size="large"
                  value={addExpert?.expert_name}
                  onChange={handleInput}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Expert Email"
                // name={"email"}
                rules={emailRules}
                validateStatus={emailError ? "error" : ""}
                help={emailError}
              >
                <Input
                  type="email"
                  name="email"
                  size="large"
                  value={addExpert?.email || ""}
                  onChange={handleInput}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item label="Linkedin Url">
                <Input
                  type="text"
                  name="linkedin_url"
                  size="large"
                  value={addExpert?.linkedin_url}
                  onChange={handleInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* <Col md={2}>
                <Form.Item label="Contact Code">
                  <Select
                    size="large"
                    placeholder="Select Country Code"
                    name="contact_code"
                    showSearch
                    value={addExpert?.contact_code}
                    onChange={handleSelectChange}
                    virtual={false}
                    allowClear
                  >
                    {countries?.map((country, key) => {
                      return (
                        <>
                          <Select.Option value={country?.phonecode}>
                            {country?.nicename}
                          </Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item label="Contact Number">
                  <Input
                    name="contact_number"
                    size="large"
                    type="number"
                    value={addExpert?.contact_number}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col> */}
            <Col md={6}>
              <Form.Item label="Contact Number">
                <PhoneInput
                  className="react-tel-input-2"
                  country={"us"}
                  enableSearch={true}
                  value={phone}
                  onChange={(value, country) => {
                    handlePhoneChange(value, country);
                  }}
                  inputStyle={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item label="Currency">
                <Select
                  name="currency"
                  size="large"
                  placeholder="Select Currency"
                  value={addExpert?.currency}
                  onChange={handleSelectChangeCurr}
                >
                  <Select.Option value="">Select Currency</Select.Option>
                  <Select.Option value="USD">USD</Select.Option>
                  <Select.Option value="EUR">EUR</Select.Option>
                  <Select.Option value="JPY">JPY</Select.Option>
                  <Select.Option value="GBP">GBP</Select.Option>
                  <Select.Option value="AUD">AUD</Select.Option>
                  <Select.Option value="CAD">CAD</Select.Option>
                  <Select.Option value="NZD">NZD</Select.Option>
                  <Select.Option value="SGD">SGD</Select.Option>
                  <Select.Option value="HKD">HKD</Select.Option>
                  <Select.Option value="INR">INR</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label="Expert Rate">
                <Input
                  name="rate"
                  size="large"
                  type="number"
                  value={addExpert?.rate}
                  onChange={handleInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="projectEditor">
              <CKEditor
                editor={Editor}
                data={addExpert?.remark} // Prefill CKEditor with fetched overview data
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                  ],
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  setAddExpert((prevData) => ({
                    ...prevData,
                    remark: newData,
                  }));
                }}
                onBlur={(editor) => {
                  console.info("Blur.", editor);
                }}
                onFocus={(editor) => {
                  console.info("Focus.", editor);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Item label="Linkedin Account" rules={[{ required: true }]}>
                <Input
                  name="by_LinkedinAccount"
                  type="text"
                  placeholder="First name & last name with space"
                  size="large"
                  value={addExpert?.by_linkedinAccount}
                  onChange={handleInput}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end mt-3">
          <button className="secondaryBttn btn" onClick={() => setShow(false)}>
            Close
          </button>
          <button
            className="viewButton btn ms-2"
            onClick={() => AddTeamExpert()}
          >
            Submit
          </button>
        </div>
      </Modal>

      <CloseProject
        show={handleCloseProject}
        setShow={setCloseProject}
        status={status}
        updatedStatus={updatedStatus}
        teamIdClosed={project_id}
        GetLiveProjectList={GetLiveProjectList}
      />
      {/* <ProjectExpertListModal
        isOpenExpertListModal={isOpenExpertListModal}
        setIsOpenExpertListModale={setIsOpenExpertListModale}
      /> */}
      <Drawer
        title={
          <>
            <div style={{ float: "left" }}>
              Expert Profile {addExpert?.data?.profile_number ? "#" : ""}
              {addExpert?.data?.profile_number}
            </div>
          </>
        }
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        width={"50%"}
      >
        {/* Offcanvas content goes here */}
        {/* Replace the content below with your actual content */}
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="nameAndCompany_M"
              style={{
                fontWeight: "400",
                fontSize: "15px",
                color: "#333",
                marginTop: "-8px",
              }}
            >
              <strong
                id="nameAndCompany_M"
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#333",
                }}
              >
                {addExpert?.expert_name}
              </strong>
            </div>
            <h2
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#333",
                marginBottom: "10px",
                marginTop: 0,
              }}
            >
              {addExpert?.email}
            </h2>
          </div>
          <div className="d-flex mt-1">
            <span
              onClick={() => {
                // setShow(true)
                navigate(
                  `/dashboard/addExpert?project_id=${project_id}&expertID=${expertIdEdit}&redirect=team`
                );
              }}
              style={{ color: "#5746ec", cursor: "pointer" }}
              className="me-3"
            >
              Edit
            </span>
            <span
              onClick={() => setDeleteExpert(true)}
              style={{ color: "red", cursor: "pointer" }}
              className="me-3"
            >
              Delete
            </span>
            <span
              onClick={() => handleDownloadPDF(addExpert?.data)}
              style={{ color: "red", cursor: "pointer" }}
            >
              {loading ? (
                <>
                  <Spin size="small" /> PDF...{" "}
                </>
              ) : (
                <>
                  <FiDownload /> PDF
                </>
              )}
            </span>
          </div>
          <div className="mt-2">
            <div className="expertPanel_3Zo3_">
              <div className="section_3ByhA">
                {addExpert?.linkedin_url !== null &&
                  addExpert?.linkedin_url !== "" &&
                  addExpert?.linkedin_url ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">Linkedin</span>
                      <span className="detailsRowRight_3h_zH">
                        <a
                          href={addExpert?.linkedin_url}
                          target="_blank"
                          style={{ color: "#5746EC" }}
                          rel="noreferrer"
                        >
                          View Profile
                        </a>
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.rate !== null &&
                  addExpert?.rate !== "" &&
                  addExpert?.rate ? (
                  <>
                    <div className="detailsRows_2038l">
                      {/* if any field is coming null do not show that part */}
                      <span className="detailsRowLeft_3h_zH">Expert Rate </span>
                      <span className="detailsRowRight_3h_zH">
                        {addExpert?.currency} {addExpert?.rate}/hr
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.by_LinkedinAccount !== null &&
                  addExpert?.by_LinkedinAccount !== "" &&
                  addExpert?.by_LinkedinAccount ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">
                        By Linkedin Account
                      </span>
                      <span className="detailsRowRight_3h_zH">
                        {addExpert?.by_LinkedinAccount}
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.contact_number !== null &&
                  addExpert?.contact_number !== "" &&
                  addExpert?.contact_number ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">
                        Contact Number
                      </span>
                      <span className="detailsRowRight_3h_zH">
                        +{addExpert?.contact_code} {addExpert?.contact_number}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {addExpert?.remark !== null &&
            addExpert?.remark !== "" &&
            addExpert?.remark ? (
            <>
              <div className="overview mt-2">
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Overview
                </h2>
                <p
                  style={{
                    overflowX: 'hidden',
                    whiteSpace: 'normal', // Ensures text wraps within the container
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: modifiedContent(addExpert?.remark),
                  }}
                ></p>
              </div>
            </>
          ) : null}
        </div>
        {addExpert?.data?.expert_experience?.length > 0 && (
          <div className="experien-list mt-4">
            <h6
              style={{
                fontWeight: "500",
                fontSize: "15px",
              }}
            >
              Experience
            </h6>
            <div>
              <ul className="nextyn_list_items">
                {addExpert?.data?.expert_experience?.map((curElem, i) =>
                  curElem?.current_compny || curElem.previous_compny ? (
                    <li
                      className="nextyn_list_item"
                      key={i}
                      style={{ position: "relative" }}
                    >
                      {curElem?.show_check === "yes" && (
                        <MdOutlineStar
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: 0,
                            color: "#5746ec",
                          }}
                        />
                      )}

                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">
                          {curElem?.previous_designation
                            ? curElem?.previous_designation
                            : curElem?.current_designation}
                        </span>
                        <div className="companyName_1dSwA">
                          {curElem?.previous_compny
                            ? curElem?.previous_compny
                            : curElem?.current_compny}
                        </div>
                      </div>
                      <span className="careerDates_3sJ2b">
                        {curElem?.previous_designation_date
                          ? getFormattedDate(curElem?.previous_designation_date)
                          : getFormattedDate(
                            curElem?.current_designation_date
                          ) +
                          `${curElem?.till_present === "yes" ? " Present" : ""
                          }`}
                      </span>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        )}
      </Drawer>
      <ProjectCallDetailsModal
        isOpen={open?.isOpen}
        project_id={open?.id}
        setIsOpen={(val) => {
          setOpen({
            id: "",
            isOpen: val,
          });
        }}
      />
    </>
  );
}

export default ProjectOverview;
