import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { Spin } from "antd";
import AddMeetingForm from "./AddMeetingForm";

const AddMeeting = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 100);
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Nextyn | Add Zoom Meeting"}</title>
            </Helmet>
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <Sidebar />
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div>
                        <div className="d-block d-lg-block">
                            {open ? (
                                <AddMeetingForm />
                            ) : (
                                <>
                                    <div
                                        className="d-flex justify-content-center align-items-center text-center"
                                        style={{ minHeight: "100vh" }}
                                    >
                                        <Spin size="large" />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default AddMeeting;
