import { Button, Divider, Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import API_URL from '../../Constants/api-path';
import "../../assests/css/custom.css"
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";


const AmazonGiftModal = ({ show, setShow, viewId, fetchData, allPaymentData, resetAll }) => {
    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem("expertData"));
    const [values, setValues] = useState({
        amazn_email_id: "",
        contact_person_name: "",
        contact_number: "",
        email: "",
        expert_id: userData?.data?._id,
    });
    const once = useRef(false)
    const handleReset = () => {
        once.current = false
        setValues({
            amazn_email_id: "",
            contact_person_name: "",
            contact_number: "",
            email: "",
            expert_id: userData?.data?._id,
        })
        form.resetFields()
    }

    useEffect(() => {
        if (show && !once.current) {
            once.current = true
            let item = allPaymentData?.filter((type) => type?.payment_method === "amazon")?.[0]
            console.log('item: ', item);
            let obj = {
                ...values,
                amazn_email_id: item?.amazn_email_id,
                contact_person_name: item?.contact_person_name,
                contact_number: item?.contact_number,
                email: item?.email,
            }
            setValues(obj)
            form.setFieldsValue(obj)
        }
    }, [allPaymentData, form, show, values])

    const [loading, setLoading] = useState(false);

    const handleInputs = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [key]: value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (viewId) {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_UPDATE}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "amazon",
                        payment_id: viewId,
                        ...values,
                    }),
                });

                const result = await res.json();
                if (result) {
                    resetAll()
                    fetchData();
                    if (result.status === 200 || result.status === true) {
                        notification.success({
                            message: "Payment details has been updated",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });
                        handleReset()
                        setShow(false);
                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            } else {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_ADD}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "amazon",
                        ...values,
                    }),
                });

                const result = await res.json();
                if (result) {
                    fetchData();
                    if (result.status === 200 || result.status === true) {
                        setShow(false);
                        handleReset()
                        notification.success({
                            message: "Payment method has been updated",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });

                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    };

    const emailRules = [
        {
            type: "email",
            message: "Please enter a valid email address!",
        },
        {
            required: true,
            message: "Email is required!",
        },
    ];
    return (
        <Modal
            title={<div>
                Amazon Gift Card
                <Divider className="m-2" />
            </div>}
            open={show}
            centered
            width={1000}
            onCancel={() => {
                setShow(false)
                handleReset()
            }}
            className="modal_scroll removeScrollbar"
            footer={false}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                name='amazon_gift_card_form'
                initialValues={values}
                form={form}
                onFinish={() => {
                    handleSubmit()
                }}
            >
                <Row>
                    <Col xs={12}>
                        <Form.Item
                            name="amazn_email_id"
                            label="Please enter your registered Email ID"
                            rules={emailRules}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="xyz@example.com"
                                name="amazn_email_id"
                                value={values?.amazn_email_id}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col className='d-flex align-items-center justify-content-end gap-2'>
                        <Form.Item className='mb-0'>
                            <Button
                                className="expertButtonNext"
                                onClick={() => {
                                    setShow(false)
                                    handleReset()
                                }}
                                htmlType='button'
                            >
                                Close
                            </Button>
                        </Form.Item>
                        <Form.Item className='mb-0'>
                            <Button
                                className="viewButton"
                                htmlType='submit'
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default AmazonGiftModal
