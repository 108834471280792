import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API_PATH from "../Constants/api-path";
import { history } from './history';
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import mydecodedTokenFunction from './DecodedToken';
import { notification } from 'antd';
import API_URL from '../Constants/api-path';
import ENV from '../Constants/env';
import { toast } from 'react-toastify';
import crypto from 'crypto-js'

const name = 'auth';
const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    error: null,
    loading: false,
    success: false,
    utm: null,
};

export const email_login = createAsyncThunk(
    `${name}/email_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_PATH.ADMINLOGIN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                // password: password
                password: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY)?.toString()
            }),
        });
        let login_response = await response.json();
        if (login_response?.status) {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    },
);

export const otp_login = createAsyncThunk(
    `auth/otp_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_URL.OTPLOGIN}/${email}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                otp: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY).toString(),
            })
        });
        let login_response = await response.json()
        if (login_response?.status === true || login_response?.status === 200 || login_response?.status === "200") {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    }
)

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            localStorage.removeItem('userData');
            localStorage.removeItem("token")
            localStorage.removeItem("auth")
            localStorage.removeItem("lastActiveTime")
            localStorage.removeItem("changePassword")
            localStorage.removeItem("lastLoginTime")
            return history.navigate('/admin-login');
        },
        resetState: (state) => {
            state.user = null;
            state.error = null;
            state.loading = false;
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(email_login.fulfilled, (state, action) => {
            const user = action.payload;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', user?.token);
            localStorage.setItem('userData', JSON.stringify(user));
            // localStorage.setItem("ChangePassword", JSON.stringify(user?.data?.password));
            localStorage.setItem('lastActiveTime', new Date().toString()); // Set the last active time when the user logs in
            localStorage.setItem('lastLoginTime', new Date().getTime().toString()); // Set the last login time
            const userData = mydecodedTokenFunction(user?.token);
            // console.log('userData: ', userData);
            state.user = userData;
            state.loading = false;
            state.success = true;
            // get return url from location state or default to home page
            if (!user) {
                return history.navigate("/admin-login");
            } else {
                notification.success({
                    message: "Login Successful",
                    icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                        backgroundColor: "#2ecc71",
                        color: "#fff !important",
                        border: "1px solid #52c41a",
                    },
                    duration: 5,
                    placement: "topRight",
                });
                return history.navigate("/dashboard");
            }
        })
        builder.addCase(email_login.pending, (state,) => {
            state.error = null;
            state.loading = true;
        })
        builder.addCase(email_login.rejected, (state, action) => {
            state.error = action?.payload?.message;
            state.loading = false;
            notification.error({
                message: action?.payload?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })

        builder.addCase(otp_login?.fulfilled, (state, action) => {
            let result = action.payload
            // result.data = JSON.parse(atob(result.data));
            // result.token = atob(result.token);
            localStorage.setItem('token', result?.token);
            localStorage.setItem('userData', JSON.stringify(result));
            const userDecodedData = mydecodedTokenFunction(result?.token);
            state.user = userDecodedData
            state.loading = false
            state.success = true

            if (result?.user?.passString) {
                history.navigate("/reset-password");
            } else {
                localStorage.setItem("isAuth", true);
                setTimeout(() => {
                    return toast.success("Login Successful!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }, 100);
                history.navigate("/dashboard");
            }
        })
        builder.addCase(otp_login?.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(otp_login?.rejected, (state, action) => {
            state.error = action?.payload?.message
            state.loading = false
            notification.error({
                message: action?.payload?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })
    }
});

// exports

export const authActions = { ...slice.actions };
export const authReducer = slice.reducer;

