import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../../Constants/api-path";
import Sidebar from "../../components/Sidebar";
import ComonProjectTable from "./ComonProjectTable";
import { DatePicker, Segmented, Select } from "antd";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
const { RangePicker } = DatePicker
function Projects() {
  let queryParams = queryString.parse(window.location.search);
  const [projectListObj, setProjectListObj] = useState([]);
  const [holdprojectListObj, setHoldProjectListObj] = useState([]);
  const [completeprojectListObj, setCompleteProjectListObj] = useState([]);
  const [managerFilterList, setManagerFilterList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [projectFilterList, setProjectFilterList] = useState([]);
  const [clientFilterList, setClientFilterList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    queryParams?.page !== undefined ? parseInt(queryParams.page) : 1
  );
  const [selectedOption, setSelectedOption] = useState("Live Projects");
  const [selectedOptions, setSelectedOptions] = useState({
    project_name: !["", undefined, "undefined"]?.includes(queryParams?.project_name) ? queryParams?.project_name : [],
    client_name: !["", undefined, "undefined"]?.includes(queryParams?.client_name) ? queryParams?.client_name : [],
    added_by: !["", undefined, "undefined"]?.includes(queryParams?.added_by) ? queryParams?.added_by : [],
    manager_id: !["", undefined, "undefined"]?.includes(queryParams?.manager_id) ? queryParams?.manager_id : [],
    date: !["", undefined, "undefined"]?.includes(queryParams?.sdate) && !["", undefined, "undefined"]?.includes(queryParams?.edate) ? [queryParams?.sdate, queryParams?.edate] : [],
  });
  // const [selectedOptions, setSelectedOptions] = useState({
  //   key: queryParams?.key !== undefined ? queryParams.key : "",
  // });
  const userData = JSON.parse(localStorage.getItem("userData"));

  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );

  let history = useNavigate();
  document.title = "Nextyn | Client Projects";
  const constructUrl = React.useCallback(
    (selectedOptions = null, pageNumber = null) => {
      // let projectParam;
      // let page_no;
      // if (options === null) {
      //   const { key } = selectedOptions;
      //   projectParam = Array.isArray(key) ? key.join(", ") : key || "";
      // } else {
      //   const { key } = options;
      //   projectParam = Array.isArray(key) ? key.join(", ") : key || "";
      // }
      // if (pageNumber === null) {
      //   page_no = currentPage;
      // } else {
      //   page_no = pageNumber;
      // }
      // const clientPage = Array.isArray(page_no)
      //   ? page_no.join(", ")
      //   : page_no || 1;

      // const urlParams = [
      //   `key=${encodeURIComponent(projectParam)}`,
      //   `page=${encodeURIComponent(clientPage)}`,
      // ];

      // return history({
      //   pathname: `/dashboard/projects`,
      //   search: `?${urlParams.join("&")}`,
      // });

      // NEW
      const { project_name, client_name, added_by, date, manager_id } =
        selectedOptions || {};
      const projectParam = Array.isArray(project_name)
        ? project_name.join(", ")
        : project_name;
      const companyParam = Array.isArray(client_name)
        ? client_name.join(", ")
        : client_name;
      const teamMemberParams = Array.isArray(added_by)
        ? added_by.join(", ")
        : added_by;
      const managerParams = Array.isArray(manager_id)
        ? manager_id.join(", ")
        : manager_id;
      const sdate =
        Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[0])
          ? JSON.stringify(date[0])
          : "";
      const edate =
        Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[1])
          ? JSON.stringify(date[1])
          : "";
      const clientPage = Array.isArray(pageNumber) ? date.join(", ") : pageNumber;

      const urlParams = [
        `project_name=${encodeURIComponent(projectParam)}`,
        `client_name=${encodeURIComponent(companyParam)}`,
        `added_by=${encodeURIComponent(teamMemberParams)}`,
        `manager_id=${encodeURIComponent(managerParams)}`,
        `sdate=${encodeURIComponent(sdate)}`,
        `edate=${encodeURIComponent(edate)}`,
        `page=${encodeURIComponent(clientPage)}`,
      ];

      return history({
        pathname: `/dashboard/projects`,
        search: `?${urlParams.join("&")}`,
      });
    },
    [history]
  );
  const getliveProjectList = useCallback(
    async (page = currentPage,) => {
      setLoading(true);
      setCurrentPage(page)
      try {
        let object = {};
        if (selectedOptions?.project_name) {
          object.project_name = selectedOptions?.project_name;
        }

        if (selectedOptions?.client_name) {
          object.client_name = selectedOptions?.client_name;
        }

        if (selectedOptions?.added_by) {
          object.added_by = selectedOptions?.added_by;
        }
        if (selectedOptions?.manager_id) {
          object.manager_id = selectedOptions?.manager_id;
        }
        if (
          selectedOptions?.date?.length > 0 &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[0] &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[1]
        ) {
          object.sdate = selectedOptions?.date?.[0];
          object.edate = selectedOptions?.date?.[1];
        }

        constructUrl(object, page);
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_LIVE}/${userData?.data?.email}?page=${page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setProjectListObj(result);
          setManagerFilterList(result?.allManagers);
          setProjectFilterList(result?.projectsName);
          setClientFilterList(result?.clientName);
          setTeamMemberList(result?.allTeams);
          // setCallPage(result?.countData);
          setLoading(false);
          //setAlldatas(result.count);
          console.log("result", result);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [constructUrl, currentPage, selectedOptions?.added_by, selectedOptions?.client_name, selectedOptions?.date, selectedOptions?.manager_id, selectedOptions?.project_name, userData?.data?.email, userData?.token]
  );

  const getarchievedProjectList = useCallback(
    async (page = currentPage) => {
      setLoading(true);
      setCurrentPage(page)
      try {
        let object = {};
        if (selectedOptions?.project_name) {
          object.project_name = selectedOptions?.project_name;
        }

        if (selectedOptions?.client_name) {
          object.client_name = selectedOptions?.client_name;
        }

        if (selectedOptions?.added_by) {
          object.added_by = selectedOptions?.added_by;
        }
        if (selectedOptions?.manager_id) {
          object.manager_id = selectedOptions?.manager_id;
        }
        if (
          selectedOptions?.date?.length > 0 &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[0] &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[1]
        ) {
          object.sdate = selectedOptions?.date?.[0];
          object.edate = selectedOptions?.date?.[1];
        }

        constructUrl(object, page);
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_ON_HOLD}/${userData?.data?.email}?page=${page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setHoldProjectListObj(result);
          setManagerFilterList(result?.allManagers);
          setProjectFilterList(result?.projectsName);
          setClientFilterList(result?.clientName);
          setTeamMemberList(result?.allTeams);
          setLoading(false);
          console.log(result);
          //setAlldatas(result.count);
          // console.log(projectListObj);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [constructUrl, currentPage, selectedOptions?.added_by, selectedOptions?.client_name, selectedOptions?.date, selectedOptions?.manager_id, selectedOptions?.project_name, userData?.data?.email, userData?.token]
  );

  const getcompleteProjectList = useCallback(
    async (page = currentPage) => {
      setLoading(true);
      setCurrentPage(page)
      try {
        let object = {};
        if (selectedOptions?.project_name) {
          object.project_name = selectedOptions?.project_name;
        }

        if (selectedOptions?.client_name) {
          object.client_name = selectedOptions?.client_name;
        }

        if (selectedOptions?.added_by) {
          object.added_by = selectedOptions?.added_by;
        }
        if (selectedOptions?.manager_id) {
          object.manager_id = selectedOptions?.manager_id;
        }
        if (
          selectedOptions?.date?.length > 0 &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[0] &&
          !["", undefined, "undefined"]?.selectedOptions?.date?.[1]
        ) {
          object.sdate = selectedOptions?.date?.[0];
          object.edate = selectedOptions?.date?.[1];
        }

        constructUrl(object, page);
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_COMPLETE}/${userData?.data?.email}?page=${page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setCompleteProjectListObj(result);
          setManagerFilterList(result?.allManagers);
          setProjectFilterList(result?.projectsName);
          setClientFilterList(result?.clientName);
          setTeamMemberList(result?.allTeams);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [constructUrl, currentPage, selectedOptions?.added_by, selectedOptions?.client_name, selectedOptions?.date, selectedOptions?.manager_id, selectedOptions?.project_name, userData?.data?.email, userData?.token]
  );



  const handleOptionChange = useCallback(
    (option) => {
      setSelectedOption(option);
      setSelectedOptions({
        project_name: [],
        client_name: [],
        added_by: [],
        manager_id: [],
        date: [],
      })
      callSearch.current = false
      setCurrentPage(1);
      constructUrl(null, 1);
      if (option === "Completed Projects") {
        getcompleteProjectList(1, "");
      } else if (option === "Live Projects") {
        getliveProjectList(1, "");
      } else {
        getarchievedProjectList(1);
      }
    },
    [
      constructUrl,
      getarchievedProjectList,
      getcompleteProjectList,
      getliveProjectList,
    ]
  );

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      // constructUrl(selectedOptions, pageNumber);
      callSearch.current = false
      if (selectedOption === "Completed Projects") {
        getcompleteProjectList(pageNumber);
      } else if (selectedOption === "Live Projects") {
        getliveProjectList(pageNumber);
      } else {
        getarchievedProjectList(pageNumber);
      }
    },
    [getarchievedProjectList, getcompleteProjectList, getliveProjectList, selectedOption]
  );

  useEffect(() => {
    let queryParams = queryString.parse(window.location.search);
    if (queryParams.page && !isNaN(parseInt(queryParams.page))) {
      setCurrentPage(parseInt(queryParams.page));
    } else {
      setCurrentPage(1);
    }
  }, []);

  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      getliveProjectList(currentPage, selectedOptions);
      // if (selectedOptions) {
      //   constructUrl(selectedOptions, currentPage);
      // }
    }
  }, [currentPage, getliveProjectList, selectedOptions]);

  const LiveProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={projectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={projectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    currentPage,
    handlePageChange,
    loading,
    projectListObj?.countData,
    projectListObj?.data,
    selectedOptions,
  ]);

  const CompletedProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={completeprojectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={completeprojectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    completeprojectListObj?.countData,
    completeprojectListObj?.data,
    currentPage,
    handlePageChange,
    loading,
    selectedOptions,
  ]);

  const ArchivedProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={holdprojectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={holdprojectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    currentPage,
    handlePageChange,
    holdprojectListObj?.countData,
    holdprojectListObj?.data,
    loading,
    selectedOptions,
  ]);
  const callonce = useRef(false)
  const callSearch = useRef(false)
  useEffect(() => {
    if (callSearch.current) {
      callSearch.current = false
      let handler = setTimeout(async () => {
        setCurrentPage(1)
        if (selectedOption === "Completed Projects") {
          getcompleteProjectList(1, selectedOptions.key);
        } else if (selectedOption === "Live Projects") {
          getliveProjectList(1, selectedOptions.key);
        } else {
          getarchievedProjectList(1);
        }
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [getarchievedProjectList, getcompleteProjectList, getliveProjectList, selectedOption, selectedOptions.key]);


  const getArrayWithPermission = useMemo(() => {
    let segmentArray = []
    if (permission?.[3]?.live_projects?.view) {
      segmentArray = [...segmentArray, "Live Projects"]
    }
    if (permission?.[8]?.archived_projects?.view) {
      segmentArray = [...segmentArray, "On-Hold Projects"]
    }
    if (permission?.[7]?.completed_projects?.view) {
      segmentArray = [...segmentArray, "Completed Projects"]
    }

    return segmentArray
  }, [permission])

  const onChange = useCallback((value, key) => {
    const updatedValue = value === undefined ? '' : value;
    let obj = { ...selectedOptions, [key]: updatedValue }
    setSelectedOptions(obj);
    constructUrl(obj, 1)
    // const queryParams = queryString.parse(window.location.search);
    // queryParams[key] = JSON.stringify(updatedValue);

    // history({
    //   pathname: "/dashboard/team/completed-projects",
    //   search: `?${queryString.stringify(queryParams)}`,
    // });
  }, [constructUrl, selectedOptions]);

  return (
    <>
      {/* <MobileMenu /> */}
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Projects | Nextyn Platform"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>

            <div className="bk-div float-start w-100">
              <div className="body-expart-div projects-pages">
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                  <h2> My Projects </h2>

                  <Segmented
                    size="large"
                    options={getArrayWithPermission}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  />
                  <div>
                    <span className="d-none">something</span>
                  </div>
                </div>

                {/* <div className="d-md-flex project-sec-2 top-ps-seection justify-content-between align-items-center m-queries d-md-none">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2> My Projects </h2>
                  </div>

                  <Segmented
                    size="large"
                    options={[
                      "Live Projects",
                      "On-Hold Projects",
                      "Completed Projects",
                    ]}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  />


                </div> */}
                <div className="mb-4">
                  {/* <Input
                    placeholder="Search"
                    value={selectedOptions?.key}
                    onChange={(e) => {
                      callSearch.current = true
                      setSelectedOptions({
                        key: e.target.value,
                      });
                      // handleInputChange(e)
                    }
                    }

                    prefix={<SearchOutlined />}
                    style={{ marginBottom: 16 }}
                    size="large"
                  /> */}
                  <Row className="mt-3  g-2 flex align-items-center">
                    <Col md={11}>
                      <Row md={12}>
                        <Col >
                          {/* <Input
                            size="large"
                            placeholder="Project Name"
                            className="input-size"
                            value={selectedOptions?.project_name}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                callonce.current = false
                              } onChange(e.target.value, "project_name")
                            }}
                            style={{ height: "40px" }}
                            allowClear
                          /> */}
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Project Name"
                            className="input-size"
                            size="large"
                            mode="multiple"
                            showSearch
                            value={selectedOptions?.project_name || null}
                            onChange={(value) => {
                              onChange(value, "project_name")
                            }
                            }
                            onClear={() => {
                              callonce.current = false;
                            }}
                            filterOption={(input, option) =>
                              option?.props?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) !== -1
                            }
                            allowClear
                          >
                            {projectFilterList &&
                              projectFilterList?.map((option, index) => (
                                <Select.Option value={option?._id} key={index}>
                                  {option?.project_title}
                                </Select.Option>
                              ))}
                          </Select>
                        </Col>
                        <Col >
                          {/* <Input
                            size="large"
                            placeholder="Client Name"
                            className="input-size"
                            value={selectedOptions?.client_name}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                callonce.current = false
                              } onChange(e.target.value, "client_name")
                            }}
                            style={{ height: "40px" }}
                            allowClear
                          /> */}
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Client Name"
                            className="input-size"
                            size="large"
                            mode="multiple"
                            showSearch
                            value={selectedOptions?.client_name || null}
                            onChange={(value) => {
                              onChange(value, "client_name")
                            }
                            }
                            onClear={() => {
                              callonce.current = false;
                            }}
                            filterOption={(input, option) =>
                              option?.props?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) !== -1
                            }
                            allowClear
                          >
                            {clientFilterList &&
                              clientFilterList?.map((option, index) => (
                                <Select.Option value={option?._id} key={index}>
                                  {option?.companyName}
                                </Select.Option>
                              ))}
                          </Select>
                        </Col>
                        {userData?.data?.roleData?.[0]?.name === "Super Admin" &&
                          <Col >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Manager"
                              mode="multiple"
                              className="input-size"
                              size="large"
                              showSearch
                              value={selectedOptions?.manager_id || null}
                              onClear={() => {
                                callonce.current = false
                              }}
                              onChange={(value) => onChange(value, "manager_id")}
                              filterOption={(input, option) =>
                                option?.props?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) !== -1
                              }
                              allowClear
                            >
                              {managerFilterList &&
                                managerFilterList?.map((option, index) => (
                                  <Select.Option value={option?._id} key={index}>
                                    {option?.manager}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                          </Col>}
                        {(userData?.data?.roleData?.[0]?.name === "Super Admin" || userData?.data?.roleData?.[0]?.name === "Manager") &&
                          <Col >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Team Member"
                              mode="multiple"
                              className="input-size"
                              size="large"
                              showSearch
                              onClear={() => {
                                callonce.current = false
                              }}
                              value={selectedOptions?.added_by || null}
                              onChange={(value) => onChange(value, "added_by")}
                              filterOption={(input, option) =>
                                option?.props?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) !== -1
                              }
                              allowClear
                            >
                              {teamMemberList &&
                                teamMemberList?.map((option, index) => (
                                  <Select.Option value={option?._id} key={index}>
                                    {option?.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Col>}
                        <Col md={4}>
                          <RangePicker
                            size="large"
                            style={{ width: "100%", height: "40px" }}
                            format="YYYY-MM-DD"
                            className="input-size"
                            value={selectedOptions?.date?.[0] && selectedOptions?.date?.[1] ? [dayjs(selectedOptions?.date?.[0], "YYYY-MM-DD"), dayjs(selectedOptions?.date?.[1], "YYYY-MM-DD")] : []}
                            onChange={(date, dateString) => {
                              if (date === null) {
                                callonce.current = false
                              }
                              onChange(dateString, "date")
                            }
                            } allowClear
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}>
                      <button
                        className="viewButton btn"
                        style={{
                          float: "right"
                        }}
                        onClick={() => {
                          // GetCompleteProjectList(currentPage, selectedOptions);
                          if (selectedOption === "Completed Projects") {
                            getcompleteProjectList(1);
                          } else if (selectedOption === "Live Projects") {
                            getliveProjectList(1);
                          } else {
                            getarchievedProjectList(1);
                          }
                        }}
                      >
                        Search
                      </button>
                    </Col>
                  </Row>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  {selectedOption === "Live Projects" && <LiveProjectsPage />}
                  {selectedOption === "On-Hold Projects" && (
                    <ArchivedProjectsPage />
                  )}
                  {selectedOption === "Completed Projects" && (
                    <CompletedProjectsPage />
                  )}

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Projects;
