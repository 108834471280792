import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  notification,
} from "antd";
import React, { useRef, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { countryOptions } from "../../Constants/CountryOptions";
import { CloseCircleOutlined, LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import API_PATH from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Country } from "country-state-city";
import useZeroBounce from "../../Utils/useZeroBounce";
import axios from "axios";
import API_URL from "../../Constants/api-path";
function UpdateUserModal({
  selectedUser,
  userModal,
  setUserModal,
  GetClientList,
  viewClientModal,
}) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    companyName: selectedUser?.company,
    contact_number_full: "",
    isValidEmail: ""
  });
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  // const { validateEmailWithZeroBounce } = useZeroBounce()
  const [loadValid, setLoadValid] = useState(false);

  const validateEmailDomain = async (value) => {
    // Check if value is a valid email address
    let isEmailValidated = value.match(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
    // let isEmailValidated = value.match(
    //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // );
    if (isEmailValidated) {
      setLoadValid(true)
      // const response = await validateEmailWithZeroBounce(value);
      // if (response?.status === "valid" || response?.status === "unknown") {
      setAddUser({
        ...addUser,
        isValidEmail: true
      })
      try {
        // Make an API call to check if the email is valid
        let res = await axios.get(
          API_URL?.VALIDATE_CLIENT_EMAIL + `?email=${value}&company_id=${selectedUser?.company_id}`
        );
        if (res?.data?.data?.length === 0) {
          setLoadValid(false)
          return false
        } else {
          setLoadValid(false)
          form.setFields([
            {
              name: "email",
              errors: ["This domain is already in use."],
            },
          ]);
          return true
          // return Promise.reject(new Error("This domain is already in use."));
        }
      } catch (error) {
        setLoadValid(false)
        form.setFields([
          {
            name: "email",
            errors: ["This domain is already in use."],
          },
        ]);
        return true
        // return Promise.reject(new Error("This domain is already in use."));
      }
      // } else if (response?.error) {
      //   setLoadValid(false)
      //   notification.error({
      //     message: "Account ran out of credits please contact to administrator",
      //     icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
      //     style: {
      //       backgroundColor: "#e74c3c",
      //       color: "#fff",
      //       border: "1px solid #c0392b",
      //     },
      //     duration: 5,
      //     placement: "topRight",
      //   });
      //   form.setFields([
      //     {
      //       name: "email",
      //       errors: ["Account ran out of credits."],
      //     },
      //   ]);
      //   return true
      //   // return Promise.reject(new Error("Account ran out of credits."));

      // } else {
      //   setLoadValid(false)
      //   form.setFields([
      //     {
      //       name: "email",
      //       errors: ["Please enter a valid email address."],
      //     },
      //   ]);
      //   setAddUser({
      //     ...addUser,
      //     isValidEmail: false
      //   })
      //   return true
      //   // return Promise.reject(new Error("Please enter a valid email address."));
      // }
    } else {
      form.setFields([
        {
          name: "email",
          errors: ["Please enter a valid email id"],
        },
      ]);
      return true
    }

  };
  const callOnce = useRef(false);
  const countries = Country?.getAllCountries();

  const updatedCountries = React.useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Select.Option key={country.isoCode} value={country.name}>
            {country.name}
          </Select.Option>
        );
      }),
    [countries]
  );
  useEffect(() => {
    if (!callOnce.current && userModal && selectedUser) {
      callOnce.current = true;
      form.setFieldsValue({
        ...addUser,
        companyName: selectedUser?.company,
        firstNmae: selectedUser?.name,
        email: selectedUser?.email,
        contact_code: selectedUser?.contact_code,
        contact_number: selectedUser?.contact_number,
        country: selectedUser?.country,
        contact_number_full: `+${selectedUser?.contact_code}${selectedUser?.contact_number}`,
      });
      setAddUser({
        ...addUser,
        companyName: selectedUser?.company,
        firstNmae: selectedUser?.name,
        email: selectedUser?.email,
        contact_code: selectedUser?.contact_code,
        contact_number: selectedUser?.contact_number,
        country: selectedUser?.country,
        contact_number_full: `+${selectedUser?.contact_code}${selectedUser?.contact_number}`,
      });
    }
  }, [
    addUser,
    form,
    selectedUser,
    selectedUser?.company,
    selectedUser?.contact_code,
    selectedUser?.contact_number,
    selectedUser?.country,
    selectedUser?.email,
    selectedUser?.name,
    userModal,
  ]);

  const UpdateClientData = async () => {
    setLoading(true);

    // let fullEmail = `${addUser?.email?.toLowerCase()?.split("@")?.[0]}@${selectedUser?.companyDomain
    //   }`;
    let fullEmail = addUser?.email?.toLowerCase();

    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regEx.test(fullEmail)) {
      return setMessage("Email is invalid");
    } else {
      setMessage("");
    }

    const clienData = {
      name: addUser?.firstNmae,
      email: fullEmail,
      companyName: addUser?.companyName,
      company_id: selectedUser?.company_id,
      contact_number: addUser?.contact_number,
      country: addUser?.country,
      contact_code: addUser?.contact_code,
      registrationType: selectedUser?.registrationType,
    };
    try {
      const res = await fetch(
        `${API_PATH.UPDATE_CLIENT}/${selectedUser?._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(clienData),
        }
      );

      const result = await res.json();
      if (result && (result.status === 200 || result.status === true)) {
        viewClientModal();
        GetClientList();
        notification.success({
          message: "Client Updated Successfully!",
          icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#2ecc71",
            color: "#fff",
            border: "1px solid #52c41a",
          },
          duration: 5,
          placement: "topRight",
        });
        setUserModal(false);
        callOnce.current = false;
        setAddUser({
          firstNmae: "",
          email: "",
          contact_code: "",
          contact_number: "",
          country: "",
          companyName: selectedUser?.company,
          contact_number_full: "", isValidEmail: ""

        });
        form.resetFields();
      } else if (result && result.message === "Email is already exists.") {
        notification.error({
          message: "Email id already exists.",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Error when creating client", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      open={userModal}
      title={
        <div>
          Update User
          <Divider className="m-2" />
        </div>
      }
      onCancel={() => {
        callOnce.current = false;
        form.resetFields();
        setUserModal(false);
        setAddUser({
          firstNmae: "",
          email: "",
          contact_code: "",
          contact_number: "",
          country: "",
          companyName: selectedUser?.company,
          contact_number_full: "", isValidEmail: ""

        });
      }}
      keyboard={false}
      centered
      size={"lg"}
      width={800}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={async () => {
          let isErrorExist = false
          let isErrored = await validateEmailDomain(addUser.email);
          if (isErrored) {
            isErrorExist = true;
          }
          if (!isErrorExist) {
            await UpdateClientData();
          }

        }}
      >
        <div>
          <div className="projects-modal-divs UserModal">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <Form.Item
                    label="Name"
                    name={"firstNmae"}
                    rules={[{ required: true, message: "name is required!" }]}
                  >
                    <Input
                      type="text"
                      name="firstNmae"
                      maxLength={40}
                      size="large"
                      width={"95%"}
                      placeholder="Name"
                      // value={addUser.firstNmae}
                      // onChange={handleInputs}
                      onChange={(e) => {
                        let obj = {
                          ...addUser,
                          firstNmae: e.target.value,
                        };
                        setAddUser(obj);
                        form.setFieldValue("firstNmae", e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group addRightInput">
                  <Form.Item
                    label="Email"
                    name="email"
                    maxLength={50}
                    rules={[
                      {
                        required: true,
                        message: "email is required!",
                      },
                      // {
                      //   validator: validateEmailDomain, // Custom validator function
                      // },
                    ]}
                  >
                    <Input
                      // type="email"
                      name="email"
                      size="large"
                      maxLength={50}
                      width={"95%"}
                      // value={addUser.email}
                      placeholder="Enter email address"
                      // onChange={handleInputs}
                      // addonAfter={"@" + selectedUser?.companyDomain}
                      suffix={loadValid ? <LoadingOutlined /> : addUser?.email !== "" && addUser?.isValidEmail === false ? <CloseCircleOutlined /> : addUser?.email !== "" && addUser?.isValidEmail ? <CheckCircleOutlined style={{ color: "#52c41a" }} /> : <MailOutlined />}
                      onChange={(e) => {
                        let obj = {
                          ...addUser,
                          email: e.target.value,
                        };
                        setAddUser(obj);
                      }}
                    />
                  </Form.Item>
                </div>
                {message === "" ? (
                  <></>
                ) : (
                  <p className="text-danger">{message}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <Form.Item
                    label="Company Name"
                    name={"companyName"}
                    rules={[
                      {
                        required: true,
                        message: "Company name is required!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="companyName"
                      maxLength={40}
                      size="large"
                      width={"95%"}
                      value={addUser.companyName}
                      disabled
                      placeholder="Company Name"
                      // value={addUser.firstNmae}
                      // onChange={handleInputs}
                      onChange={(e) => {
                        let obj = {
                          ...addUser,
                          companyName: e.target.value,
                        };
                        setAddUser(obj);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <Form.Item
                    label="Contact Number"
                    name="contact_number_full"
                    rules={[
                      {
                        required: true,
                        message: "contact number is required!",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"us"}
                      name="contact_number_full"
                      enableSearch={true}
                      // value={phone}
                      onChange={(value, country) => {
                        setPhone(value);
                        const selectedCountry = countryOptions?.find(
                          (option) => option?.code === `+${country?.dialCode}`
                        );
                        if (selectedCountry) {
                          setAddUser((prevStep3) => ({
                            ...prevStep3,
                            contact_code: country?.dialCode,
                            contact_number: value?.replace(
                              country?.dialCode,
                              ""
                            ),
                            contact_number_full: value,
                          }));
                        }
                      }}
                      inputStyle={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Location"
                  name={"country"}
                  rules={[
                    {
                      required: true,
                      message: "Location is required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}

                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    showSearch
                    value={addUser?.country}
                    onChange={(value) => {
                      let obj = {
                        ...addUser,
                        country: value,
                      };
                      setAddUser(obj);
                    }}
                  >
                    {updatedCountries}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              key="back"
              htmlType="button"
              className="secondaryBttn"
              onClick={() => {
                callOnce.current = false;
                form.resetFields();
                setUserModal(false);
                setAddUser({
                  firstNmae: "",
                  email: "",
                  contact_code: "",
                  contact_number: "",
                  country: "",
                  companyName: selectedUser?.company,
                  contact_number_full: "", isValidEmail: ""

                });
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              //   style={{
              //     backgroundColor: "rgb(76, 187, 23)",
              //     borderColor: "rgb(76, 187, 23)",
              //     color: "#fff",
              //   }}
              className=" ms-2"
            >
              Submit{" "}
              {loading && (
                <Spin
                  style={{ color: "#fff", marginLeft: "5px" }}
                  size="small"
                  indicator={<LoadingOutlined />}
                />
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

export default UpdateUserModal;
