import { useCallback, useMemo } from 'react'
import API_URL from '../../../Constants/api-path';
import { useDispatch } from 'react-redux';
import { setAllClientList, setCompanyList } from '../features/addProject.slice';
import useSWR from 'swr';
import axios from 'axios';
import { useSelector } from 'react-redux';
function useAddProject() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const dispatch = useDispatch()
    const getRoleList = useCallback(async () => {
        try {
            const res = await axios.get(
                `${API_URL.CLIENT_LIST}?page=1&limit=10`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );
            return res?.data
        } catch (error) {
            console.log("notification", error);
        }
    }, [userData?.token]);
    const { addData } = useSelector((state) => state.addProjectReducer)

    const getAllClientLlist = useCallback(async () => {
        try {
            const res = await axios.get(
                `${API_URL.ALL_CLIENTS_LIST}?page=1&limit=10&domainSearch=${addData?.client_domain}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );
            return res.data
        } catch (error) {
            return error
        }
    }, [addData?.client_domain, userData?.token]);

    const { isLoading: isLoadingCompanyList } = useSWR("get_all_company_list", () => getRoleList(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            if (result) {
                console.log('result: ', result);
                dispatch(setCompanyList(result?.data));
            }

        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const { isLoading: isLoadingClients } = useSWR(["get_all_client_list_data", addData?.client_domain], () => addData?.client_domain ? getAllClientLlist() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            console.log('result: 2', result);
            dispatch(setAllClientList(result?.data));
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });
    const fetchAllClientList = useCallback(
        async (key) => {
            try {
                const res = await axios.get(
                    `${API_URL.ALL_CLIENTS_LIST}?page=1&limit=10&key=${key}&domainSearch=${addData?.client_domain}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );
                dispatch(setAllClientList(res?.data?.data));
            } catch (error) {
                console.error('error: ', error);
                // Handle error
            }
        },
        [addData?.client_domain, dispatch, userData?.token]
    );

    const fetchCompanyList = useCallback(async (key = "") => {
        let object = { key: key, page: 1, limit: 10 };
        const queryParams = new URLSearchParams(object);
        try {
            const res = await axios.get(
                `${API_URL.CLIENT_LIST}?${queryParams.toString()}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );
            dispatch(setCompanyList(res?.data?.data));
        } catch (error) {
            return error
        }
    }, [dispatch, userData?.token]);

    const values = useMemo(() => ({
        isLoadingCompanyList,
        isLoadingClients,
        fetchAllClientList,
        fetchCompanyList
    }), [fetchAllClientList, fetchCompanyList, isLoadingClients, isLoadingCompanyList])
    return values
}

export default useAddProject
