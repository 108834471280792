import React, { useState } from 'react';
import { Select, Button, Divider, Space, Tooltip, Input, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FaCheck } from 'react-icons/fa';

const CustomSelectExpert = ({
    showSearch = true,
    labelInValue = true,
    allowClear = true,
    filterOption = false,
    fetchExperts,
    debounceFetcher,
    optionsDatasEmail = [],
    optionsDatas = [],
    optionType,
    fetching,
    creatableSelect = false,
    inputRef,
    email,
    onEmailChange,
    addItem,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const [resExpert, setResExpert] = useState(false);

    const handleDropdownVisibleChange = (isOpen) => {
        setOpen(isOpen);
        setResExpert(false)
        if (isOpen) {
            fetchExperts({ is_filtering: false, value: "" });
        }
    };

    return (
        <Select
            showSearch={showSearch}
            labelInValue={labelInValue}
            allowClear
            filterOption={filterOption}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            onSearch={debounceFetcher}
            options={optionType === "email" ? optionsDatasEmail : optionsDatas}
            open={open}
            size="large"
            dropdownRender={
                creatableSelect
                    ? (menu) => (
                        <>
                            {fetching ? (
                                <span
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ height: 40 }}
                                >
                                    <Spin size="small" />
                                </span>
                            ) : (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Space.Compact
                                        block
                                        style={{ padding: "0 8px 4px", width: "100%" }}
                                        className="zum_meeting_space"
                                    >
                                        <Tooltip
                                            title={"For Unregistered Experts Only"}
                                            overlayStyle={{ zIndex: 2000 }}
                                        >
                                            <Input
                                                placeholder="Please enter expert email"
                                                ref={inputRef}
                                                width={100}
                                                value={email}
                                                onChange={onEmailChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                        </Tooltip>
                                        {resExpert ? (
                                            <Button
                                                type="text"
                                                style={{ color: "green" }}
                                                className="d-flex align-items-center"
                                            >
                                                <FaCheck />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="text"
                                                icon={<PlusOutlined />}
                                                onClick={(e) => {
                                                    setResExpert(true)
                                                    addItem(e)
                                                    setTimeout(() => {
                                                        setOpen(false)
                                                    }, 500);

                                                }}
                                            >
                                                Add
                                            </Button>

                                        )}
                                    </Space.Compact>
                                </>
                            )}
                        </>
                    )
                    : null
            }
            {...props}
        />
    );
};

export default CustomSelectExpert;
