import { Modal, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import API_URL from '../../Constants/api-path';
import moment from 'moment';
import AvatarGroup from 'react-avatar-group';
import _ from "lodash";

const ProjectCallDetailsModal = ({
    setIsOpen,
    isOpen,
    project_id = "",
    type = "client"
}) => {

    const [callList, setCallList] = useState([])

    const getTotalCallPrice = (callRate, duration) => {
        let ratePerMinute = Number(callRate) / 60
        let actualPriceOfCall = ratePerMinute * Number(duration)
        return actualPriceOfCall?.toFixed(2)
    }
    const data = callList?.map((exp) => ({
        expert_name: <Tooltip title={exp?.call_data?.map((exp) => exp?.expert_name)?.join(", ")}>{_.truncate(exp?.call_data?.map((exp) => exp?.expert_name)?.join(", ") || "", { length: 20, omission: "..." })}</Tooltip>,
        //  <AvatarGroup
        //     avatars={exp?.call_data?.map((exp) => exp?.expert_name)}
        //     uppercase={true}
        //     initialCharacters={2}
        //     fontColor="#f33636"
        //     max={2}
        //     size={30}
        //     fontSize=".38"
        //     displayAllOnHover={true}
        //     backgroundColor="#EFEFEF"
        // />,
        client_name: exp?.call_data?.[0]?.client_company,
        date: moment(exp?.call_data?.[0]?.date)?.format("DD-MM-YYYY"),
        team_member: exp?.call_data?.[0]?.draft_zoom_meetings?.[0]?.team_member_name?.join(", "),
        call_duration: exp?.call_data?.[0]?.call_duration + " Min",
        // client_amount: `${exp?.call_data?.[0]?.client_currency} ${getTotalCallPrice(exp?.call_data?.[0]?.client_rate, exp?.call_data?.[0]?.call_duration)}`,
        client_amount: `${exp?.call_data?.[0]?.client_currency} ${exp?.call_data?.[0]?.client_rate}`,
        expert_amount: exp?.call_data?.map((ex) => `${ex?.expert_currency} ${ex?.expert_rate}`)?.join(", "),
        // expert_amount: exp?.call_data?.map((ex) => `${ex?.expert_currency} ${getTotalCallPrice(ex?.expert_rate, exp?.call_data?.[0]?.call_duration)}`)?.join(", "),
        key: exp?.call_data?.[0]?._id
    }))

    const columns = [

        {
            title: "Experts",
            dataIndex: "expert_name",
            key: "name",

        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Team Member",
            dataIndex: "team_member",
            key: "team_member",
        },
        {
            title: "Call Duration",
            dataIndex: "call_duration",
            key: "call_duration",
        },
        {
            title: "Client Amount",
            dataIndex: "client_amount",
            key: "client_amount",
        },

        {
            title: "Expert Amount",
            dataIndex: "expert_amount",
            key: "expert_amount",
        },
    ];
    const columnsExpert = [
        {
            title: "Client",
            dataIndex: "client_name",
            key: "client_name",

        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Team Member",
            dataIndex: "team_member",
            key: "team_member",
        },
        {
            title: "Call Duration",
            dataIndex: "call_duration",
            key: "call_duration",
        },
        {
            title: "Client Amount",
            dataIndex: "client_amount",
            key: "client_amount",
        },
        {
            title: "Expert Amount",
            dataIndex: "expert_amount",
            key: "expert_amount",
        },
    ];
    const getCompletedCalls = React.useCallback(async () => {
        try {
            const res = await axios.get(`${API_URL.GET_COMPLETED_CALL_DETAILS}/${project_id}`);
            return res
        } catch (error) {
            console.error('error: ', error);
        }
    }, [project_id])

    const { isLoading } = useSWR(["completed_calls_details", project_id], () => project_id ? getCompletedCalls() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res?.data !== null) {
                setCallList(res?.data?.data)
            }
        }
    })
    return (
        <Modal
            open={isOpen}
            footer={false}
            onCancel={() => setIsOpen(false)}
            title="Call Records"
            width={1000}
        >
            <div>

                <Table
                    dataSource={data}
                    columns={type === "client" ? columns : columnsExpert}
                    pagination={false}
                    loading={isLoading}
                    size='middle'
                />
            </div>
        </Modal>
    )
}

export default ProjectCallDetailsModal