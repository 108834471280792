import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
// import MobileMenu from "../MobileMenu";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { BsChevronLeft } from "../../Utils/Icons";
import Sidebar from "../../components/Sidebar";
import CompleteTable from "./CompleteTable";
import DeclineTable from "./DeclineTable";
import RequestTable from "./RequestTable";
import ScheduledTable from "./ScheduledTable";
import SubmitTable from "./SubmitTable";
import DraftTable from "./DraftTable";
import { createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import API_URL from "../../Constants/api-path";

function ProjectsDetailsExpert() {


  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const userData = JSON.parse(localStorage.getItem("userData"));

  let project_id = query.get("project_id");
  let active_tab = query.get("tab");
  const headers = React.useMemo(() => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  }), [userData?.token]);
  const [expertList, setExpertList] = useState([])
  const [loading, setLoading] = useState(false);

  const getExperts = React.useCallback(() => {
    setLoading(true);
    axios
      .get(`${API_URL.Client_Project_Expert_List}/${project_id}`, { headers })
      .then((res) => {
        setExpertList(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, project_id]);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    getExperts()
  }, [getExperts])
  const navigate = useNavigate()

  return (
    <>
      {/* <MobileMenu /> */}
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="bk-div float-start w-100">
              <div className="body-expart-div request-page projects-scope">
                <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                  <h4 className="d-flex align-items-center">
                    {/* <NavLink
                      to={{
                        pathname: "/dashboard/project_details",
                        search: `?${createSearchParams({ project_id })}`,
                      }}
                      style={{ textAlign: "left" }}
                    > */}
                    <span
                      onClick={() => {
                        navigate(-1)
                      }}
                      style={{ textAlign: "left", cursor: "pointer" }}
                    >
                      <BsChevronLeft className="me-3 search-btn-back" />
                    </span>
                    Experts
                  </h4>
                  <div className="d-flex justify-content-center align-items-center">
                    <ul
                      className="nav nav-pills mb-3 mt-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/project_details",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/chats",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Messages
                        </NavLink>
                      </li>

                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/expert_list",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Experts
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <button
                    className="btn add-ps-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#carteProjectModal"
                    style={{ visibility: "hidden" }}
                  >
                    {" "}
                    <BsPlusLg /> Create Project{" "}
                  </button>
                </div>

                <span className="sp-hr w-100" />

                <div className="request-body-sec projects-details-page details-message-part">
                  <div className="expert-tables">
                    <ul
                      className="nav nav-pills mb-3 mx-auto"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === null || active_tab === "" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab7"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="true"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=`)
                          }}
                        >
                          Draft
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === "submitted" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab2"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="false"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=submitted`)
                          }}
                        >
                          Submitted
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === "requested" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab3"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=requested`)
                          }}
                        >
                          Requested
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === "scheduled" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab4"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=scheduled`)
                          }}
                        >
                          Scheduled
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === "completed" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab5"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=completed`)
                          }}
                        >
                          Completed
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${active_tab === "declined" ? "active" : ""}`}
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab6"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                          onClick={() => {
                            navigate(`/dashboard/expert_list?project_id=${project_id}&tab=declined`)
                          }}
                        >
                          Declined
                        </button>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end align-items-center mt-4">
                      <NavLink
                        to={{
                          pathname: "/dashboard/addExpert",
                          search: `?${createSearchParams({ project_id })}`,
                        }}
                      ><Button
                        size="large"
                        icon={<PlusCircleOutlined />}
                        className="d-flex align-items-center viewButton"
                      >

                          Add New Expert
                        </Button>
                      </NavLink>

                    </div>
                    <div className="tab-content mt-3" id="pills-tabContent">
                      <div
                        className={`tab-pane fade ${active_tab === null || active_tab === "" ? "show active" : ""}`}
                        // className="tab-pane fade show active"
                        id="expert-tab7"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <DraftTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${active_tab === "submitted" ? "show active" : ""}`}
                        id="expert-tab2"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <SubmitTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${active_tab === "requested" ? "show active" : ""}`}
                        id="expert-tab3"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <RequestTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${active_tab === "scheduled" ? "show active" : ""}`}
                        id="expert-tab4"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <ScheduledTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${active_tab === "completed" ? "show active" : ""}`}
                        id="expert-tab5"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <CompleteTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${active_tab === "declined" ? "show active" : ""}`}
                        id="expert-tab6"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <DeclineTable project_id={project_id} expertList={expertList} setExpertList={setExpertList} getExperts={getExperts} loading={loading} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
export default ProjectsDetailsExpert;
