import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    openAddMeetingModal: false,
    experts: [],
    zoomExperts: [],
    clients: [],
    managers: [],
    companyList: [],
    meetingList: { data: [], count: 0 },
    meeting_page: 1,
    meetingInfo: false,
    formvalues: {
        "zoomEmail": [],
        "meetingId": undefined,
        "call_taken_by": [],
        "expert_id": [],
        "project_title": "",
        "cloud_zoom_meetings_id": "",
        "company_select": [],
        "country": [],
        "industry": []
    },
    selectedMeeting: {},
    selectedMeetingDetails: {},
    type: "create",
    deleteModal: false,
    isOpen: false,
    fetchingExperts: false,
    fetchingClients: false,
    fetchingManagers: false,
    fetchingMeetings: false,
    transcriptText: "",
    sortType: "",
    sortBy: "",
    search: "",
    fetching: false
}

const internalmeetingSlice = createSlice({
    name: "internalMeetings",
    initialState: initialState,
    reducers: {
        setOpenAddMeethingModel: (state, { payload }) => {
            state.openAddMeetingModal = payload
        },
        setExperts: (state, { payload }) => {
            state.experts = payload
        },
        setZoomExperts: (state, { payload }) => {
            state.zoomExperts = payload
        },
        setClients: (state, { payload }) => {
            state.clients = payload
        },
        setSearch: (state, { payload }) => {
            state.search = payload
        },
        setSortBy: (state, { payload }) => {
            state.sortBy = payload
        },
        setSortType: (state, { payload }) => {
            state.sortType = payload
        },
        setManagers: (state, { payload }) => {
            state.managers = payload
        },
        setFormValues: (state, { payload }) => {
            state.formvalues = payload
        },
        setFetchingManagers: (state, { payload }) => {
            state.fetchingManagers = payload
        },
        setFetchingExperts: (state, { payload }) => {
            state.fetchingExperts = payload
        },
        setFetchingClients: (state, { payload }) => {
            state.fetchingClients = payload
        },
        setFetchingMeetings: (state, { payload }) => {
            state.fetchingMeetings = payload
        },
        setMeetingList: (state, { payload }) => {
            state.meetingList = payload
        },
        setSelectedMeeting: (state, { payload }) => {
            state.selectedMeeting = payload
        },
        setSelectedMeetingDetails: (state, { payload }) => {
            state.selectedMeetingDetails = payload
        },
        setTranscriptText: (state, { payload }) => {
            state.transcriptText = payload
        },
        setMeetingPage: (state, { payload }) => {
            state.meeting_page = payload
        },
        setIsFetching: (state, { payload }) => {
            state.fetching = payload
        },
        setType: (state, { payload }) => {
            state.type = payload
        },
        setMeetingInfo: (state, { payload }) => {
            state.meetingInfo = payload
        },
        setDeleteModal: (state, { payload }) => {
            state.deleteModal = payload
        },
        setIsOpen: (state, { payload }) => {
            state.isOpen = payload
        },
        setAllCompanyList: (state, { payload }) => {
            state.companyList = payload
        },
        clearAll: (state) => {
            state.formvalues = {
                "zoomEmail": [],
                "meetingId": undefined,
                "call_taken_by": [],
                "expert_id": [],
                "project_title": "",
                "cloud_zoom_meetings_id": "",
                "company_select": [],
                "country": []
            }
        }
    }

})
export const internalMeetingReducer = internalmeetingSlice.reducer
export const { setOpenAddMeethingModel, setExperts, setManagers, setFormValues, setFetchingManagers, setFetchingExperts, clearAll, setMeetingList, setFetchingMeetings, setSelectedMeeting, setMeetingPage, setType, setMeetingInfo, setSelectedMeetingDetails, setDeleteModal, setIsOpen, setAllCompanyList, setTranscriptText, setSearch, setFetchingClients,
    setSortBy,
    setSortType, setClients, setIsFetching, setZoomExperts } = internalmeetingSlice.actions

