import {Navigate} from "react-router-dom";
import useDecodedTokenExpert from "../../Utils/useDecodedTokenExpert";

const ExpertPrivateRouteTwo = ({children}) => {
  const decodedTokenExpert = useDecodedTokenExpert();
  let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));

  // if (decodedTokenExpert && isExpertAuth) {
  if (decodedTokenExpert) {
    return <Navigate to="/expert/dashboard" replace />;
  } else {
    return children;
  }
  // let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));
  // if (isExpertAuth) {
  //   return <Navigate to="/expert/dashboard" replace />;
  // } else
  //   return children;
};

export default ExpertPrivateRouteTwo;
