import { Tooltip, Modal, notification, Tag, Divider, Popover } from "antd";
import React, { memo, useCallback, useMemo, useState } from "react";
import { FaEye, FaLinkedinIn } from "react-icons/fa6";
import { MdContentCopy, MdDelete, MdOutlineAssignment, MdWorkOutline } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import _ from "lodash";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";

import "../../assests/css/custom.css";
import user_icon from "../../assests/images/user.png";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  ProjectOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { IoMdGlobe } from "react-icons/io";
import { BsBuildings } from "react-icons/bs";
import CopyToClipboard from "react-copy-to-clipboard";
import { ProjectListModal } from "../projects/ProjectListModal";
import axios from "axios";
import { LiaIndustrySolid } from "react-icons/lia";
import moment from "moment";
function TopIndustry({ experts, getExperts, callWhenComeToPage = false, currentPage = 1 }) {
  const [modal2Open, setModal2Open] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [copiedId, setCopiedId] = useState("");
  const [loading, setLoading] = useState("");
  const [expertProjectList, setExpertProjectList] = useState([]);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const addDefaultSrc = (ev) => {
    ev.target.src = user_icon;
  };

  const getMangerId = (_id) => {
    setManagerId(_id);
  };

  const DeleteManager = async () => {
    try {
      const res = await fetch(`${API_PATH.EXPERT_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          expert_id: [managerId],
        }),
      });

      const result = await res.json();
      if (result) {
        getExperts();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Expert has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#52c41a",
              color: "#fff",
              height: "40%",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const footer = (
    <div>
      <button
        key="cancel"
        className="secondaryBttn btn"
        onClick={() => setModal2Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          DeleteManager();
          setModal2Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );
  const headers = React.useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );
  const getExpertProfileDetails = useCallback((expert_id) => {
    if (expert_id) {
      setLoading(true)
      axios
        .get(`${API_PATH.EXPERT_PROFILE}/${expert_id}`, {
          headers,
        })
        .then((res) => {
          setExpertProjectList(res.data.allProjects);
          setIsOpenProject(true)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err);
        });
    }

  }, [headers]);
  const navigate = useNavigate()
  if (experts?.length === 0) {
    return (
      <>
        <div className="text-center noResultFound">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-6">
                <svg
                  className="SVGInline-svg StyledManWithIceCream--1fwimz7-svg dRWpfu-svg"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 179 250"
                  style={{ height: "400px", marginTop: "50px" }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M148.017 226.599c0 .267-.012.532-.035.794l12.858-1.387.273-.003c.737-.013 1.774-.032.317.601-1.27.552-6.004 1.67-9.594 2.518-1.958.462-3.576.844-4.105 1.01 1.489.359 7.101.845 12.527 1.314 5.967.517 11.707 1.013 11.487 1.3-.184.241-3.263.097-7.029-.078-5.381-.251-12.165-.567-13.899.078-1.706.635 3.43 2.687 7.759 4.415 3.155 1.261 5.882 2.35 5.214 2.593-1.179.43-11.424-3.108-16.628-4.905-1.783-.616-2.974-1.027-3.007-1.005-.144.096.891 1.41 1.989 2.805h.001c1.304 1.657 2.698 3.428 2.313 3.404-.252-.016-1.971-1.515-3.714-3.037-1.774-1.548-3.574-3.119-3.882-3.172a58.093 58.093 0 0 1-2.429-.493c-.267-.058-1.868.721-3.416 1.475-1.448.705-2.849 1.388-3.071 1.342-.487-.101.907-1.333 1.99-2.29.706-.624 1.281-1.132 1.114-1.132l-.107.001c-2.12 0-9.231.001-10.653-1.118-.93-.732.567-1.075 2.448-1.505 2.195-.503 4.913-1.125 4.913-2.624 0-4.784 4.487-8.667 9.211-8.667 4.723 0 7.155 2.982 7.155 7.766zm17.953 2.59c-.683-.007-3.645-.583-1.97-.889 1.675-.306 5.508-.306 6.098.292.59.597-3.444.605-4.128.597zm9.878 5.102c-.495.187.953.687 1.636.716.683.029 1.668-.278.684-.642-.984-.363-1.825-.261-2.32-.074zm-7.616 4.744c-.431-.092-4.311-1.505-3.195-1.597 1.116-.092 3.539.385 3.799 1.045.261.66-.174.644-.604.552z"
                    fill="#FF6DA2"
                  ></path>
                  <path
                    d="M106.952 62.373c2.428-.895-12.124-19.7-12.92-20.15-.795-.452-8.543 8.516-8.278 9.536.265 1.02 18.77 11.51 21.198 10.614z"
                    fill="#E0CF77"
                  ></path>
                  <path
                    d="M93.962 42.196c.573.332-.074 3.5-2.27 5.858-2.196 2.357-5.524 4.622-6.307 3.36-.784-1.261.377-2.686 2.573-5.043 2.196-2.357 5.43-4.508 6.004-4.175z"
                    fill="#C59F65"
                  ></path>
                  <path
                    d="M15.703 240.685c6.61 0 12.264-.465 9.519-3.205-2.542-2.539-19.334-8.147-22.122-4.066-1.54 2.254-3.159 6.76.45 6.976 3.61.217 11.055.295 12.153.295zM94.52 222.9c-3.087-.332-9.28 1.602-11.99 2.611 0 0-7.586.477-8.235 3.99-.496 2.685-.152 7.459 3.236 6.198 3.388-1.261 10.276-4.104 11.233-4.642 7.27-4.094 9.614-7.742 5.755-8.157z"
                    fill="#1A2460"
                  ></path>
                  <path
                    d="M65.97 122.111c-7.762 3.103-26.039 1.293-33.477 0 0 6.787-1.756 9.189-5.636 9.189-7.115 27.145-22.395 90.669-23.947 102.302 1.294 2.586 5.433 3.103 7.761 0 2.329-3.102 24.9-46.535 35.895-67.864 7.438 22.945 24.819 65.475 28.7 64.7 3.88-.776 6.647-3.01 7.294-3.979L65.97 122.111z"
                    fill="#7482DE"
                  ></path>
                  <path
                    d="M77.55 73.541c-4.657.776-7.114-7.433-7.761-11.634l-6.791 1.94v9.694c1.617 6.14 6.791 17.45 14.552 13.573 6.344-3.17 17.847-13.669 22.252-27.1-4.31-3.98-2.653-5.306-3.979-8.621-.995-1.327-2.159 5.05-2.321 5.968-1.229 6.962-11.295 15.404-15.952 16.18zm22.712-22.454c-.663 0-.995-.663-.663-1.99 1.326-.331 3.647 2.653 3.979 3.98.331 1.325.864 2.294.201 2.626-.317.158-.814-.411-.995-.995-.197-.637-.081-1.3-.533-1.3-.331 0-1.124-.689-1.124-1.352 0-.663-.202-.97-.865-.97zm-46.845-27.66c-.876 1.768-5.174 6.95-6.998 8.424l-.663 2.627c6.202 3.683 18.167 10.166 16.416 6.63-1.75-3.536-1.459-6.63-1.094-7.735 2.554-2.21 7.661-6.851 7.661-7.735 0-.885 1.46-1.842 2.189-2.21-.876-1.768.73-7.367 1.094-8.84l-10.944-6.63-7.661 6.63c.365 2.21.875 7.072 0 8.84z"
                    fill="#DD6652"
                  ></path>
                  <path
                    d="M57.794 19.317c-.875 1.768-3.948 4.777-5.407 5.882 0-1.104 1.03-4.775-1.16-8.091l-.001-.003c-2.189-3.315-4.375-6.628-1.093-7.732 3.284-1.105 1.095-7.735 3.284-6.63 2.188 1.105 3.283-3.316 6.566-1.106 3.284 2.21 6.567-3.314 7.661-1.104 1.095 2.21 2.19 4.42 4.378 4.42 2.189 0 4.378 4.42 3.283 5.524-.875.884 1.095 3.315-1.094 4.42-2.189 1.106-4.914 1.018-6.572-.64-1.658-1.657-3.865.247-5.637.64-1.663.37-1.51-2.508-2.984.687-.652 1.412-.13 1.523-1.224 3.733z"
                    fill="#1A2460"
                  ></path>
                  <path
                    d="M57.795 29.264c-1.094-3.315 3.284-9.945 4.378-11.05 1.095-1.105 2.554-2.579 2.189-1.105-.365 1.473-1.313 3.757-2.189 5.525-1.094 2.21 0 3.315 1.095 4.42.875.884 4.013-.369 5.472-1.105-.365 1.841-1.314 5.967-2.19 7.735-1.094 2.21-3.282 3.315-6.566 2.21-3.283-1.105-1.094-3.315-2.189-6.63z"
                    fill="#1A2460"
                  ></path>
                  <path
                    d="M57.465 14.395c.36-1.989 2.879-2.79 3.034 0-.443 1.138-1.294 2.924-1.441 3.433-.184.638-.176.74-.704.74-.469 0-.768-.867-.663-1.327.216-.943-.412-1.814-.226-2.846z"
                    fill="#DD6652"
                  ></path>
                  <path
                    d="M64.03 65.881c1.552 13.185 1.94 42.981 1.94 56.231-12.417 4.653-31.044 1.292-38.805-.97 4.527-17.755 13.776-53.275 14.552-53.322-3.88.324-12.612-.194-16.492-4.847 1.293-8.08 7.373-25.595 21.343-31.024.97 2.909 6.567 8.834 15.104 8.834 5.433 4.653 7.209 15.403 9.15 21.22-.971.97-3.688 3.103-6.792 3.878z"
                    fill="#FDC8DA"
                  ></path>
                  <path
                    d="M28.134 133.745c-1.553-4.653-1.617-47.181-1.94-68.833 2.586 1.615 9.119 5.041 14.551 5.817-3.557 15.188-9.035 48.724-5.93 57.255 3.104 8.532-3.448 7.054-6.681 5.761z"
                    fill="#DD6652"
                  ></path>
                  <path
                    d="M41.717 67.82c-8.537 0-14.552-3.231-16.492-4.847v4.847c2.587 1.94 9.313 5.623 15.522 4.848l.97-4.848zm29.103-5.817c-3.105 3.103-5.821 3.878-6.791 3.878l-.97-3.878c1.617-.323 5.238-1.357 6.79-2.908l.97 2.908z"
                    fill="#EBA3BF"
                  ></path>
                  <path
                    d="M3.28 142.491c4.76-2.418 57.613-4.307 59.561 0 1.949 4.307 3.647 39.117 0 41.842-3.646 2.726-57.613 4.307-59.561 0-1.948-4.307-4.76-39.424 0-41.842z"
                    fill="#333"
                  ></path>
                  <path
                    d="M28.048 131.377c-4.576-.897-8.32 6.281-6.24 9.87h4.16c-.693-2.243-1.248-6.28 2.08-6.28 4.16 0 7.904 0 8.736 1.345.923 1.494.555 4.038.832 4.935h4.16c-.416-13.01-9.152-8.973-13.728-9.87z"
                    fill="#333"
                  ></path>
                  <path
                    d="M37.137 132.957c0 2.015-2.375 3.648-5.305 3.648-2.93 0-5.305-1.633-5.305-3.648 0-2.014 1.05-6.962 3.98-6.962 2.929 0 6.63 4.948 6.63 6.962z"
                    fill="#DD6652"
                  ></path>
                </svg>
              </div>
              <div className="col-md-6">
                <h3
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "300",
                    lineHeight: "26px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Oops,</span> there are no
                  results for your search query.
                </h3>
                <h3
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "300",
                    lineHeight: "26px",
                  }}
                >
                  Launch a custom search request for experts{" "}
                  {/* <span style={{ fontWeight: "500" }}>
                      <CreateProjectModal2 />
                    </span> */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {experts?.map((hit, index) => {
        console.log('hit: ', hit);
        return (
          <>
            <div
              className="comon-divs-re d-xl-flex align-items-center justify-content-between findExperts expertBox pt-4 pb-4"
              key={index}
            >
              <NavLink
                className="col-lg-9 position-relative"
                onClick={() => {
                  callWhenComeToPage.current = false
                  let filter = JSON.parse(localStorage.getItem("filterStates"));
                  if (filter?.name && filter?.value) {
                    localStorage.setItem(
                      "filterStates",
                      JSON.stringify({
                        ...filter,
                        path: "/expert-profile",
                      })
                    );
                  }
                }}
                to={permission?.[2]?.expert?.view === true ? `/expert-profile?expertId=${hit._id}` : ""}
                // to={() => goToDetailsPage(hit.expert_id)}
                style={{ cursor: "pointer" }}
              >
                {/* <div className={`cust-tag-expert ${hit?.by_linkedinAccount === "Self" ? "cust_green" : "cust_blue"}`}>
                  {hit?.by_linkedinAccount === "Self" ? "Self Registered" : "Source"}
                </div> */}
                <Row className="g-0">
                  <Col md={1} className="position-relative mt-2">
                    {hit?.image !== null &&
                      hit?.image !== undefined &&
                      hit?.v !== "" ? (
                      <img
                        loading="lazy"
                        className=""
                        style={{
                          width: "75px",
                          borderRadius: "50%",
                          maxHeight: "75px",
                        }}
                        src={hit?.image}
                        onError={addDefaultSrc}
                        alt={hit?.image}
                      />
                    ) : (
                      <img
                        loading="lazy"
                        className=""
                        style={{
                          width: "75px",
                          borderRadius: "50%",
                          maxHeight: "75px",
                        }}
                        src={user_icon}
                        alt={hit?.image}
                      />
                    )}
                    <Tooltip
                      placement="top"
                      color="#5746EB"
                      title={
                        hit?.by_linkedinAccount === "Self"
                          ? "Self Registered"
                          : "Added by " + hit?.by_linkedinAccount
                      }
                    >
                      <div
                        style={{ top: "52px", right: "4px" }}
                        className={hit?.by_linkedinAccount + " expertTypes"}
                      ></div>
                    </Tooltip>
                  </Col>
                  <Col md={11} >
                    <div className="px-sm-3">
                      <Row md={12}>
                        <Col md={7}>
                          <h4
                            className="find_companyName mt-0 "
                            style={{ marginBottom: "5px" }}
                          >
                            {![null, undefined, ""]?.includes(hit?.name) ? hit?.name : hit?.first_name + " " + hit?.last_name}
                          </h4>
                          <div>
                            <div className="text-muted pe-4 mb-2 d-flex align-items-center g-3 gap-2">
                              {![null, undefined, ""]?.includes(hit?.country) ? <span>
                                <Tooltip title="Country" color="#5746ec"> <IoMdGlobe style={{ color: "#5746ec" }} /></Tooltip>
                                <span
                                  className="card-text"
                                  style={{ lineHeight: "13px" }}
                                >
                                  <small
                                    className="text-muted ms-1"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {hit?.country}
                                  </small>
                                </span>
                              </span> : null}
                              {![null, undefined, ""]?.includes(hit?.current_designation) ?
                                <span>
                                  <Tooltip title="Current Designation" color="#5746ec">
                                    <MdWorkOutline style={{ color: "#5746ec" }} />
                                  </Tooltip>
                                  <span
                                    className="card-text"
                                  >
                                    <small
                                      className="text-muted ms-1"
                                      style={{ fontSize: "13px" }}
                                    >
                                      <Tooltip title={hit?.current_designation} color="#5746ec">
                                        {_?.truncate(hit?.current_designation, { length: 30, omission: "..." })}
                                      </Tooltip>
                                    </small>
                                  </span>
                                </span> : hit?.current_designation}
                            </div>
                            <div className="d-flex align-items-center">
                              {![null, undefined, ""]?.includes(hit?.current_company) ?
                                <div className="text-muted pe-2 mb-2 d-flex align-items-center g-2 gap-1">
                                  <Tooltip title="Current Company" color="#5746ec"> <BsBuildings style={{ color: "#5746ec" }} /></Tooltip>
                                  <span
                                    className="card-text"
                                    style={{ lineHeight: "13px" }}
                                  >
                                    <Tooltip title={hit?.current_company} color="#5746ec">
                                      <Tag
                                        color="green"
                                      >
                                        {_?.truncate(hit?.current_company, { length: 30, omission: "..." })}
                                      </Tag>
                                    </Tooltip>
                                  </span>
                                </div> : null}
                              {![null, undefined, ""]?.includes(hit?.previous_company) ?
                                <div className="text-muted pe-2 mb-2 d-flex align-items-center g-2 gap-1">
                                  <Tooltip title="Previous Company" color="#5746ec"> <HiOutlineBuildingOffice2 style={{ color: "#5746ec" }} /></Tooltip>
                                  <span
                                    className="card-text"
                                    style={{ lineHeight: "13px" }}
                                  >

                                    <Tooltip title={hit?.previous_company} color="#5746ec">
                                      <Tag
                                        color="yellow"
                                      >
                                        {_?.truncate(hit?.previous_company, { length: 30, omission: "..." })}
                                      </Tag>
                                    </Tooltip>
                                  </span>
                                </div> : null}
                            </div>
                            {![undefined, null, ""]?.includes(hit?.industry) ? <div className="text-muted pe-4 mb-2 d-flex align-items-center g-2 gap-1">
                              <Tooltip title="Industries" color="#5746ec"><LiaIndustrySolid style={{ color: "#5746ec" }} /></Tooltip>
                              <span
                                className="card-text d-flex align-items-center"
                                style={{ lineHeight: "13px" }}
                              >
                                {Array.isArray(hit?.industry) ?
                                  <>
                                    {
                                      hit?.industry?.length > 0 && hit?.industry?.slice(0, 2)?.map((ind) =>
                                        <Tag
                                          className=""
                                          color="blue"
                                        >
                                          {ind}
                                        </Tag>)
                                    }
                                    {hit?.industry?.length > 2 &&
                                      <Popover placement="right" autoAdjustOverflow overlayInnerStyle={{ width: 400 }} content={hit?.industry?.map((ind) =>
                                        <Tag
                                          className=""
                                          color="blue"
                                        >
                                          +{ind}
                                        </Tag>)}>
                                        <Tag
                                          className=""
                                          color="blue"
                                        >+{hit?.industry?.length - 2}</Tag>
                                      </Popover>
                                    }
                                  </>

                                  : hit?.industry?.split(",")?.length > 0 ?
                                    <>{hit?.industry?.split(",")?.slice(0, 2)?.map((ind) => <Tag
                                      className=""
                                      color="blue"
                                    >
                                      {ind}
                                    </Tag>)}
                                      {hit?.industry?.split(",")?.length > 2 &&
                                        <Popover placement="right" overlayInnerStyle={{ maxWidth: 400, overflow: "auto" }} content={hit?.industry?.split(",")?.slice(2)?.map((ind) =>
                                          <Tag
                                            className=""
                                            color="blue"
                                          >
                                            {ind}
                                          </Tag>)}>
                                          <Tag
                                            className=""
                                            color="blue"
                                          >+{hit?.industry?.split(",")?.length - 2}</Tag>
                                        </Popover>}
                                    </>

                                    : null}

                              </span>
                            </div> : null}

                          </div>
                        </Col>
                        <Col md={5}>
                          <p>
                            {![null, undefined, ""]?.includes(hit?.email) ?
                              <small style={{ fontWeight: 300 }} onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                              }}>
                                <Tooltip title={hit?.email} color="#5746ec">{_?.truncate(hit?.email, { length: 20, omission: "..." })}</Tooltip>
                                <CopyToClipboard
                                  text={hit?.email}
                                  onCopy={() => {
                                    setCopiedId(hit?.email);
                                  }}

                                >
                                  <Tooltip
                                    title={copiedId === hit?.email ? "Copied!" : "Copy ID"} color="#5746ec"
                                  >
                                    <MdContentCopy
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                      }}
                                      className="ms-1"
                                      style={{ cursor: "pointer", color: "#5746ec" }}
                                    />
                                  </Tooltip>
                                </CopyToClipboard>
                              </small>
                              : null}
                          </p>
                          {hit?.call_history?.length > 0 && <><p>
                            <small style={{ fontWeight: 300 }}><strong>No. Of Calls Done: </strong>{hit?.call_history?.length} calls</small>
                          </p>
                            <p>
                              <small style={{ fontWeight: 300 }}><strong>Average Rate: </strong>{Number(hit?.last_5_call_avg)} {hit?.hourly_currency}</small>
                            </p>
                            <p>
                              <small style={{ fontWeight: 300 }}>
                                <strong>Last Call: </strong>{moment(hit?.last_call?.[0]?.created_at)?.format("ll")}
                                {hit?.last_call?.[0]?.lead_by && " - " + (hit?.last_call?.[0]?.lead_by)}
                              </small>
                            </p></>}


                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </NavLink>
              <div className="fixedCol-2 text-center">
                <h5 className="mt-0" style={{ fontWeight: 600 }}>
                  {hit?.hourly_currency ? hit?.hourly_currency : "USD"}{" "}
                  {hit?.hourly_rate_call_cunsltn && hit?.hourly_rate_call_cunsltn > 0
                    ? hit?.hourly_rate_call_cunsltn
                    : "250"}
                  /<small className="text-muted fw-normal">{hit
                    ?.charge_duration || "hour"}</small>
                </h5>
                <div className="d-flex gap-1 justify-content-center align-items-center">
                  <Tooltip
                    placement="top"
                    title={"View Profile"}
                    color="#5746EB"
                  >
                    <Link to={`/expert-profile?expertId=${hit._id}`}>
                      <span>
                        <button disabled={!permission?.[2]?.expert?.view} size="large" className="viewBttnExpert">
                          <FaEye />
                        </button>
                      </span>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={"View Linkedin Profile"}
                    color="#5746EB"
                  >
                    <a href={hit?.linkedin_url} target="_blank" rel="noreferrer">
                      <button size="large" className="viewBttnExpert">
                        <FaLinkedinIn />
                      </button>
                    </a>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={!permission?.[2]?.expert?.delete ? "Permission Denied" : "Delete Expert"}
                    color="#5746EB"
                  >
                    <span>
                      <button
                        size="large"
                        disabled={!permission?.[2]?.expert?.delete}
                        className="viewBttnExpert"
                        onClick={() => {
                          getMangerId(hit?._id);
                          setModal2Open(true);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top"
                    title={"Assign to project"}
                    color="#5746EB">
                    <span>
                      <button
                        size="large"
                        className="viewBttnExpert"
                        onClick={() => {
                          navigate(`/dashboard/expert_search?page=${currentPage}&limiit=10&expertId=${hit?._id}`)
                          getExpertProfileDetails(hit?._id)

                        }}
                      >
                        {loading ? <LoadingOutlined /> : <MdOutlineAssignment />}
                      </button>
                    </span>
                  </Tooltip>
                </div>
                {hit?.expertpaymentdetails?.length > 0 && (
                  <div className="text-muted d-flex align-items-center justify-content-center mt-3 mb-1">
                    <Tag
                      className="me-0 mb-1"
                      style={{
                        color: "#5746ec",
                        border: "1px solid #5746ec"
                      }}
                      color="white"
                      size="large"
                      bordered={true}
                    >
                      Payment Details Added
                    </Tag>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <img
                    src="/images/expert-icon1.svg"
                    alt="sn"
                    width="17px"
                  />
                  <span
                    className="ps-2 card-text"
                    style={{ lineHeight: "13px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ fontSize: "13px" }}
                    >
                      {(hit?.createdAt || hit?.created_at) && convertDate(
                        hit?.createdAt
                          ? hit?.createdAt
                          : hit?.created_at
                      )}
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      })}

      <Modal
        title={<div className="">Delete this Expert Account <Divider className="m-2" /></div>}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={footer}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            This action cannot be undone. If you delete this expert account then
            their account will be deactivated and the expert will not be able to
            log in again with their credentials. Are you sure you want to delete
            this expert account?
          </p>{" "}
        </div>
      </Modal>
      <ProjectListModal
        isOpenProject={isOpenProject}
        setIsOpenProject={setIsOpenProject}
        expertProjectList={expertProjectList}
        getExpertProfileDetails={getExperts}
      />
    </>
  );
}

export default memo(TopIndustry);
